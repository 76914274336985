import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

function Header({ addItems, close }) {
  const classes = useStyles();

  return (
    <AppBar
      style={{ boxShadow: "none", marginBottom: 8, position: "sticky" }}
      className={classes.appBar}
    >
      <Toolbar>
        <Icon onClick={close} style={{ color: "white", cursor: "pointer" }}>
          close
        </Icon>

        <Typography variant="h6" className={classes.title}>
          Añadir Productos
        </Typography>

        <Button autoFocus color="inherit" onClick={addItems}>
          Añadir
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
