import React, { useState, useEffect } from "react";
import styled from "styled-components";

import LinearProgress from "@material-ui/core/LinearProgress";

import { get_abonos } from "../../http/sales";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Pagination from "../../components/Table/Pagination";
import Button from "../../components/Buttons/Button";

import { Container } from "../../assets/utils/styles/global";

import AddAbono from "./add-abono";

var moment = require("moment");

function Abono({ isLoading, saleId, updateHistory, ...props }) {
  const [abonos, setAbonos] = useState({ data: [] });
  const [isAddAbonoOpen, setIsAddAbonoOpen] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function fetchAbonos() {
      const response = await get_abonos({ page, data: { sale_id: saleId } });
      setAbonos(response.data);
    }

    fetchAbonos();
  }, [saleId, page]);

  async function refresh() {
    const response = await get_abonos({ page: 1, data: { sale_id: saleId } });
    setAbonos(response.data);
  }

  return (
    <>
      {isAddAbonoOpen && (
        <AddAbono
          saleId={saleId}
          onClose={() => setIsAddAbonoOpen(false)}
          onSuccess={(total) => {
            setIsAddAbonoOpen(false);
            refresh();
            updateHistory(total);
          }}
        />
      )}

      <div {...props}>
        <Container style={{ marginTop: 20, padding: 0 }}>
          <Header>
            <Title>Abonos</Title>

            <Button
              width="200px"
              text="Agregar Abono"
              action={() => setIsAddAbonoOpen(true)}
              color="primary"
            />
          </Header>

          {isLoading ? (
            <LinearProgress value="100" mode="determinate" />
          ) : (
            <Divider />
          )}

          <TableContainer style={{ marginTop: 20 }}>
            <Table>
              <tbody>
                <Tr>
                  <Th>ID</Th>
                  <Th>Usuario</Th>
                  <Th>Monto</Th>
                  <Th>Fecha</Th>
                </Tr>

                {abonos.data.map((item) => {
                  return (
                    <Tr key={item.id}>
                      <Td>{item.id}</Td>
                      <Td>{item.username}</Td>
                      <Td>{item.amount}</Td>
                      <Td>{moment(item.date).format("DD/MM/YYYY HH:mm:ss")}</Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>

          <Pagination
            next={() => setPage(abonos.current_page + 1)}
            previous={() => setPage(abonos.current_page - 1)}
            currentPage={abonos.current_page}
            last_page={abonos.last_page}
            pageOne={() => setPage(1)}
          />
        </Container>
      </div>
    </>
  );
}

export default Abono;

const Divider = styled.div`
  height: 4px;
  background-color: #afdaa1;
`;

const Title = styled.div`
  color: #545358;
  font-size: 20px;
  font-weight: 600;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;
