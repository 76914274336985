import React, { useMemo } from "react";
import styled from "styled-components";
import { sum } from "lodash";

import { Label } from "../Text";

var numeral = require("numeral");

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  background-color: #f8f8f8;
  padding: 12px;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function Total({ data, abonos }) {
  const difference = useMemo(() => {
    return Number(data.total) - sum(abonos.map((item) => Number(item.amount)));
  }, [data, abonos]);

  return (
    <Container>
      <TextContainer>
        <Label size="16px" weight={600} style={{ marginRight: 14 }}>
          TOTAL
        </Label>

        <Label size="16x">
          {numeral(data.total).format("0,0.00")} {data.coin}
        </Label>
      </TextContainer>

      {abonos && abonos.length > 0 && (
        <TextContainer style={{ marginLeft: 20 }}>
          <Label size="16px" weight={600} style={{ marginRight: 14 }}>
            TOTAL RESTANTE
          </Label>

          <Label size="16px">{numeral(difference).format("0,0.00")} RD</Label>
        </TextContainer>
      )}
    </Container>
  );
}

export default Total;
