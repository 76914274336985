import React, { useContext } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, withRouter } from "react-router-dom";

import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";

import { TopBarLogo } from "./Image";
import { Label } from "./Text";

import theme from "../theme";

import ContextMenu from "../lib/ContextMenu";
import check_permission from "../lib/check_permission";

import LOGO from "../assets/images/logoDairies.jpeg";

const Container = styled.div`
  padding: 15px;
  background-color: ${theme.colors.PRIMARY};
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.4);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
    align-items: center;
    display: flex;
  }
`;

const LogoContainer = styled.div`
  margin-top: 3px;
`;

function TopBar({ history }) {
  const { setIsOpenMenu } = useContext(ContextMenu);
  const canSee = check_permission("PE010", "canSee");

  return (
    <Router>
      <Container>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon
            onClick={() => setIsOpenMenu(true)}
            style={{ color: "white", marginRight: 16 }}
          >
            menu
          </Icon>

          <LogoContainer>
            <TopBarLogo src={LOGO} />
          </LogoContainer>

          <Label style={{ marginLeft: 10 }} weight="bold" color="white">
            DAIRIESFOODS
          </Label>
        </div>

        <div style={{ marginRight: 32 }}>
          <Fab
            size="small"
            onClick={() => canSee && history.push("../history")}
            color="primary"
            style={{ backgroundColor: "white", marginRight: 32 }}
          >
            <Tooltip title={canSee ? "Historial" : "No tiene permisos"}>
              <Icon color="primary">timeline</Icon>
            </Tooltip>
          </Fab>
        </div>
      </Container>
    </Router>
  );
}

export default withRouter(TopBar);
