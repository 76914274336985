/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { find } from "lodash";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";

import { Field } from "../../../Input";
import { Tr, Table, Td, Th, TableContainer } from "../../../Table";
import Alert from "./Alert";
import Header from "./Header";

import Filters from "./Filters";

import { list } from "../../../../http/products";

var numeral = require("numeral");

const Divider = styled.div`
  height: 4px;
  background-color: #f8f8f8;
  margin: 4px -30px 4 -30px;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddProducts({ open, close, ...props }) {
  const [array, setArray] = useState([]);
  const [filters, setFilters] = useState([]);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const newArr = [];
      const { data } = await list();

      data.map((data) => {
        data.quantity = "";
        data.checked = false;
        data.product_id = data.id;
        data.included = false;
        data.fixed_quantity = 0;
        data.custom_price = data.sale_price;
        return newArr.push(data);
      });

      setArray(newArr);
      setFilters(newArr);
    }

    fetchData();
  }, []);

  useEffect(() => {
    function fetchData() {
      array.map((item, index) => {
        props.products.map((data) => {
          if (item.id === data.product_id) {
            array[index] = {
              ...data,
              quantity_in_stock: item.quantity_in_stock,
              checked: true,
              code: item.code,
              included: true,
              fixed_quantity: Number(data.quantity),
            };
          }
        });
      });

      setArray(array);
      setFilters(array);
    }

    if (props.edit || props.conduce) fetchData();
  }, [props.edit, props.conduce, props.products, array]);

  function check(code) {
    const products = [...filters];
    const object = find(products, { code });
    const sum = object.quantity - object.fixed_quantity;

    if (!object.included) {
      if (object.quantity_in_stock < Number(object.quantity)) {
        if (Boolean(object.bill_no_product && object.quantity_in_stock === 0)) {
          insertProducts(code);
        } else {
          setAlert(true);
        }
      } else insertProducts(code);
    } else {
      if (!object.checked) {
        if (sum > object.quantity_in_stock) {
          if (
            Boolean(object.bill_no_product && object.quantity_in_stock === 0)
          ) {
            insertProducts(code);
          } else {
            setAlert(true);
          }
        } else {
          insertProducts(code);
        }
      } else insertProducts(code);
    }
  }

  function insertProducts(code) {
    const newFilters = [...filters];
    const currentProduct = find(newFilters, { code });
    const checked = Boolean(!currentProduct.checked);
    currentProduct.checked = checked;

    setFilters(newFilters);
  }

  function changeSelected(code, e) {
    const products = [...filters];
    const currentProduct = find(products, { code });
    currentProduct.quantity = e.target.value;

    setFilters(products);
  }

  function changePrice(code, e) {
    const productsFilter = [...filters];
    const currentProductFilter = find(productsFilter, { code });
    currentProductFilter.custom_price = e.target.value;

    setFilters(productsFilter);
  }

  function addItems() {
    var result = array.filter((obj) => obj.checked === true);
    props.setProducts(result);
    setFilters(array);
  }

  function handleClose() {
    setFilters(array);
    close();
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Header close={handleClose} addItems={() => addItems()} />
      <Filters filters={array} setFilters={(items) => setFilters(items)} />

      <Divider />

      <TableContainer style={{ marginTop: 4 }}>
        <Table>
          <tbody>
            <Tr>
              <Th>Código</Th>
              <Th>Nombre</Th>
              <Th>Existencia</Th>
              <Th>Costo de venta</Th>
              <Th>Costo Deseado</Th>
              <Th>Facturar sin existencia</Th>
              <Th>Cantidad a vender</Th>
              <Th>Añadir</Th>
            </Tr>

            {filters.map((data) => (
              <Tr key={data.code}>
                <Td>{data.code}</Td>
                <Td>{data.name}</Td>
                <Td>{numeral(data.quantity_in_stock).format()}</Td>
                <Td>{numeral(data.sale_price).format("0.00")}</Td>
                <Td>
                  <Field
                    onChange={(event) => changePrice(data.code, event)}
                    label="Precio"
                    margin="dense"
                    variant="outlined"
                    style={{ width: 120 }}
                    disabled={data.checked}
                    value={data.custom_price}
                  />
                </Td>
                <Td>{data.bill_no_product === 1 ? "Sí" : "No"}</Td>
                <Td>
                  <Field
                    onChange={(event) => changeSelected(data.code, event)}
                    label="Cantidad"
                    margin="dense"
                    variant="outlined"
                    style={{ width: 120 }}
                    disabled={data.checked}
                    value={data.quantity}
                  />
                </Td>
                <Td>
                  <Checkbox
                    checked={data.checked}
                    onChange={() =>
                      props.credit_note
                        ? insertProducts(data.code)
                        : check(data.code)
                    }
                    value="primary"
                    color="primary"
                  />
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>

      <Alert open={alert} setAlert={setAlert} />
    </Dialog>
  );
}

export default AddProducts;
