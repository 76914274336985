import http from "./http";

export function permissions_by_user(id) {
  return http.get("roles/get_permissions_by_user", { params: { id: id } });
}

export function get_permissions_by_user_to_edit(id) {
  return http.get("roles/get_permissions_by_user_to_edit", {
    params: { id: id },
  });
}

export function edit(data) {
  return http.put("roles/edit", data);
}
