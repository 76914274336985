import React, { useState } from "react";
import styled from "styled-components";

import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";

import { Label } from "../Text";

import theme from "../../theme";

const AvatarContainer = styled.div`
  margin: 20px 14px;
  display: flex;
  align-items: center;
`;

function AvatarE({ logOut, name = "" }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <AvatarContainer>
        <Avatar style={{ backgroundColor: `${theme.colors.PRIMARY}` }}>
          {name[0]}
        </Avatar>

        <div
          onClick={handleClick}
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <Label weight={600} color="white" style={{ marginLeft: 10 }}>
            {name}
          </Label>

          <Icon style={{ marginLeft: 5 }} color="primary">
            expand_more
          </Icon>
        </div>
      </AvatarContainer>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => logOut()}>
          <Icon color="primary">exit_to_app</Icon>
          <Label style={{ marginLeft: 5 }}>Salir</Label>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default AvatarE;
