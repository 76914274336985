import React from "react";
import styled from "styled-components";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";

import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const Container = styled(Button)`
  width: ${(props) => props.width || "100%"};
  border: 0;
  color: white;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
`;

function ButtonFunc({
  action,
  text,
  disabled,
  width,
  loading,
  color,
  side,
  icon,
  top = 0,
  ...props
}) {
  const classes = useStyles();

  return (
    <Container
      variant="contained"
      className={classes.button}
      style={{
        boxShadow: "none",
        color: "white",
        margin: 0,
        marginTop: top,
        height: 40,
      }}
      disabled={disabled}
      onClick={() => action()}
      width={width}
      color={color}
      startIcon={side === "left" && <Icon>{icon}</Icon>}
      endIcon={side === "right" && <Icon>{icon}</Icon>}
      {...props}
    >
      {loading ? <Spinner color="white" /> : text}
    </Container>
  );
}

export default ButtonFunc;
