/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Radio from "../../RadioButton";
import { Label } from "../../Text";
import { SimpleAlert } from "../../Alert";

import NCF from "../../Selects/NCF";

import { getFirstTaxReceive } from "../../../http/sales";

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

function Voucher({ edit, setVoucher, values, ...props }) {
  const [isLoadingNumber, setIsLoadingNumber] = useState(false);

  useEffect(() => {
    async function fetch() {
      setIsLoadingNumber(true);

      try {
        const { data } = await getFirstTaxReceive(values.voucher_type_id);

        if (data.success) {
          props.setVoucherId(data.data.id);
          props.setVoucherNumber(data.data.numeration);
        } else {
          SimpleAlert("info", data.data);
          props.setVoucherNumber("");
          setVoucher({ value: null });
        }
      } catch (error) {}

      setIsLoadingNumber(false);
    }

    values.voucher_type_id !== null && !edit && fetch();
  }, [values.voucher_type_id, edit]);

  return (
    <div style={{ marginTop: 16 }}>
      <RadioContainer>
        <Label weight={500}>¿Incluye comprobante fiscal?</Label>

        <Radio
          action={() => props.setRadio(true)}
          selected={values.has_voucher}
          label="Si"
          margin="0 0 0 3px"
          disabled={edit}
        />

        <Radio
          action={() => {
            props.setRadio(false);
            setVoucher({ value: null });
            props.setVoucherNumber("");
            props.setVoucherId(null);
          }}
          selected={!values.has_voucher}
          label="No"
          margin="0 5px 0 0"
          disabled={edit}
        />
      </RadioContainer>

      <NCF
        isDisabled={!values.has_voucher || edit}
        value={values.voucher_type_id}
        onChange={setVoucher}
        style={{ marginBottom: 4, marginTop: 16 }}
      />

      <Label weight={500} style={{ marginTop: 16 }}>
        Numero de comprobante:{" "}
        {isLoadingNumber
          ? "Cargando..."
          : values.voucher_type_id !== null
          ? `${values.voucher_number}`
          : ""}
      </Label>
    </div>
  );
}

export default Voucher;
