import React from "react";

import { TextArea } from "../../Input";

function AdicionalNote({ onChange, value, ...props }) {
  return (
    <div style={{ marginTop: 16 }}>
      <TextArea
        disabled={props.isDisabled}
        onChange={onChange}
        value={value}
        rows="4"
      />
    </div>
  );
}

export default AdicionalNote;
