import http from "./http";

export function getSaleByDateRange(from, to) {
  return http.get(`sales/SalesGeneralReport?from=${from}&to=${to}`);
}

export function productsReports(from, to) {
  return http.get(`sales/productReports?from=${from}&to=${to}`);
}

export function reports_client(data) {
  return http.post("sales/reports_client", data);
}

export function reports_client_tax(data) {
  return http.post("sales/reports_client_tax", data);
}

export function export_excel(data) {
  return http.post("sales/reports_client_excel", data);
}

export function export_excel_tax(data) {
  return http.post("sales/reports_client_excel_tax", data);
}

export function export_pdf(data) {
  return http.post("sales/reports_client_pdf", data);
}

export function export_pdf_tax(data) {
  return http.post("sales/reports_client_tax_pdf", data);
}
