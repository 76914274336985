/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withTheme } from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Tooltip, Icon } from "@material-ui/core";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import { Label } from "../../components/Text";
import ResetPassword from "../../components/Users/ResetPassword";
import AddUser from "../../components/Users/AddUser";
import Divider from "../../components/Divider";
import Header from "../../components/Table/Header";
import NotPermissions from "../../components/NotPermissions";

import { Container } from "../../assets/utils/styles/global";

import check_permission from "../../lib/check_permission";

import { list } from "../../http/users";

import { updateUserList } from "../../actions/user-actions";

const mapStateToProps = (state) => {
  return {
    users: state.users,
  };
};

const mapActionsToProps = {
  updateUsersList: updateUserList,
};

function UsersGrid({ updateUsersList, users, history }) {
  const initValues = { username: "", email: "", password: "" };

  const [resetModal, setResetModal] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [user, setUser] = useState({ name: "", id: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [userData, setUserData] = useState(initValues);

  const usersState = users.user || [];

  useEffect(() => {
    usersFetch();
  }, []);

  async function usersFetch() {
    setIsLoading(true);

    try {
      const { data } = await list();
      updateUsersList(data);
    } catch {}

    setIsLoading(false);
  }

  function resetPasswordFunc(data) {
    setUser({ name: data.username, id: data.id });
    setResetModal(true);
  }

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Usuarios del sistema
      </Label>

      <Container style={{ padding: "12px 0 0 0" }}>
        <Header
          canAdd={!check_permission("PE009", "canAdd")}
          button_text="Añadir Usuario"
          add={() => setAddUser(true)}
        />

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        {check_permission("PE009", "canSee") ? (
          <TableContainer>
            <Table>
              <tbody>
                <Tr>
                  <Th>ID</Th>
                  <Th>Usuario</Th>
                  <Th>Correo</Th>
                  <Th>Acción</Th>
                </Tr>

                {usersState.map((data) => (
                  <Tr key={data.id}>
                    <Td>{data.id}</Td>
                    <Td>{data.username}</Td>
                    <Td>{data.email}</Td>
                    {check_permission("PE009", "canEdit") ? (
                      <Td style={{ cursor: "pointer" }}>
                        <Tooltip placement="right" title="Reiniciar contraseña">
                          <Icon
                            color="primary"
                            onClick={() => resetPasswordFunc(data)}
                          >
                            vpn_key
                          </Icon>
                        </Tooltip>

                        <Tooltip placement="right" title="Editar">
                          <Icon
                            style={{ marginLeft: 8 }}
                            color="primary"
                            onClick={() => {
                              setEdit(true);
                              setAddUser(true);
                              setUserData(data);
                            }}
                          >
                            edit
                          </Icon>
                        </Tooltip>

                        <Tooltip placement="right" title="Permisos">
                          <Icon
                            style={{ marginLeft: 8 }}
                            color="primary"
                            onClick={() =>
                              history.push({
                                pathname: "permissions",
                                state: { id: data.id },
                              })
                            }
                          >
                            security
                          </Icon>
                        </Tooltip>
                      </Td>
                    ) : (
                      <div />
                    )}
                  </Tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        ) : (
          <NotPermissions isLoading={isLoading} />
        )}
      </Container>

      {resetModal && (
        <ResetPassword
          user={user}
          open={resetModal}
          close={() => setResetModal(false)}
        />
      )}

      {addUser && (
        <AddUser
          isEdit={edit}
          values={userData}
          close={() => {
            setUserData(initValues);
            setEdit(false);
            setAddUser(false);
          }}
          closeAndReload={(data) => {
            setUserData(initValues);
            setEdit(false);
            setAddUser(false);
            updateUsersList(data);
            usersFetch();
          }}
        />
      )}
    </Fragment>
  );
}

export default withTheme(
  withRouter(connect(mapStateToProps, mapActionsToProps)(UsersGrid))
);
