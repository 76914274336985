import React, { useState, useEffect, Fragment } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";

import check_permission from "../../lib/check_permission";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Tooltip, Icon } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Pagination from "../../components/Table/Pagination";
import { Label } from "../../components/Text";
import Divider from "../../components/Divider";
import NotPermissions from "../../components/NotPermissions";

import { Container } from "../../assets/utils/styles/global";

import Header from "../../parts/orders/header";
import Filters from "../../parts/orders/filters";
import Status from "../../parts/orders/status";

import { grid } from "../../http/orders";

const initFilters = {
  date: null,
};

function OrdersGrid({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [orders, setOrders] = useState({ data: [] });
  const [filters, setFilters] = useState(initFilters);

  useEffect(() => {
    async function fetchOrders() {
      setIsLoading(true);

      try {
        const { data } = await grid(1);
        setOrders(data);
      } catch (error) {
        return error;
      } finally {
        setIsLoading(false);
      }
    }

    fetchOrders();
  }, []);

  async function paginate(page, items) {
    setIsLoading(true);
    setFilters(items);

    const { data } = await grid(page, items);
    setOrders(data);

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Ordenes
      </Label>

      <Container style={{ padding: "12px 0 0 0" }}>
        <Header
          handleResults={(e) => paginate(1, { name: e.target.value })}
          openFilter={() => setOpen(!isOpen)}
          isOpen={isOpen}
          handleAdd={() =>
            history.push({ pathname: "add", state: { edit: false } })
          }
        />

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <Collapse in={isOpen}>
          <Filters
            handleSearch={(items) => paginate(1, items)}
            filters={filters}
            initFilters={initFilters}
          />
          <Divider />
        </Collapse>

        {check_permission("PE012", "canSee") ? (
          <>
            {orders.data.map((data) => {
              return (
                <Container style={{ padding: "12px 0 0 0", marginTop: "16px" }}>
                  <TableContainer>
                    <Table>
                      <tbody>
                        <Tr>
                          <Th>Cliente</Th>
                          <Th>Estado</Th>
                          <Th>En producción</Th>
                          <Th>Fecha</Th>
                          <Th>Acción</Th>
                        </Tr>

                        <>
                          <Tr
                            key={data.id}
                            style={{ borderBottom: "2px solid #f8f8f8" }}
                          >
                            <Td>{data.client.name}</Td>
                            <Td>
                              <Status
                                code={data.code}
                                description={data.description}
                              />
                            </Td>
                            <Td>{data.in_production ? "Si" : "No"}</Td>
                            <Td>{data.date}</Td>

                            <Td style={{ cursor: "pointer" }}>
                              <Tooltip placement="right" title="Detalle">
                                <Icon
                                  color="primary"
                                  onClick={() =>
                                    history.push({
                                      pathname: "details",
                                      state: { data },
                                    })
                                  }
                                >
                                  description
                                </Icon>
                              </Tooltip>
                            </Td>
                          </Tr>

                          <Table>
                            <tbody>
                              <Tr>
                                <Th></Th>
                                <Th></Th>
                              </Tr>
                              {data.order_products.map((item) => (
                                <Tr
                                  key={data.id}
                                  style={{
                                    backgroundColor: "#f8f8f8",
                                    borderBottom: "1px solid #fff",
                                  }}
                                >
                                  <Td>{item.name}</Td>
                                  <Td>
                                    Cantidad: <b>{item.quantity}</b>
                                  </Td>
                                </Tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      </tbody>
                    </Table>
                  </TableContainer>
                </Container>
              );
            })}
          </>
        ) : (
          <NotPermissions isLoading={isLoading} />
        )}

        <Pagination
          next={() => paginate(orders.current_page + 1, orders)}
          previous={() => paginate(orders.current_page - 1, orders)}
          currentPage={orders.current_page}
          last_page={orders.last_page}
          pageOne={() => paginate(1, orders)}
        />
      </Container>
    </Fragment>
  );
}

export default withTheme(withRouter(OrdersGrid));
