import React from "react";

import Dialog from "@material-ui/core/Dialog";

import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

function Alert({ setAlert, open }) {
  return (
    <Dialog
      open={open}
      onClose={() => setAlert(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        No se puede insertar esta cantidad
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          La cantidad deseada supera la cantidad en existencia.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setAlert(false)} color="primary" autoFocus>
          De acuerdo
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Alert;
