import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

var moment = require("moment");

function MaterialUIPickers({
  text,
  setDateState,
  value,
  isDisable = false,
  minDate,
  ...props
}) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateState(date);
  };

  return (
    <MuiPickersUtilsProvider moment={moment} utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="dense"
        inputVariant="outlined"
        label={text}
        format="dd/MM/yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        fullWidth
        style={{ fontWeight: 500 }}
        disabled={isDisable}
        minDate={minDate}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

export default MaterialUIPickers;
