import React from "react";
import styled from "styled-components";

import LinearProgress from "@material-ui/core/LinearProgress";

import { Tr, Table, Td, Th, TableContainer } from "./Table";
import Pagination from "./Table/Pagination";

import { Container } from "../assets/utils/styles/global";

var moment = require("moment");

function Timeline({ isLoading, data, paginate, ...props }) {
  return (
    <div {...props}>
      <Container style={{ marginTop: 20, padding: "12px 0 0 0" }}>
        <Title>Historial</Title>

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <TableContainer style={{ marginTop: 20 }}>
          <Table>
            <tbody>
              <Tr>
                <Th>Usuario</Th>
                <Th>Acción</Th>
                <Th>Fecha</Th>
              </Tr>

              {data.data.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td>{item.username}</Td>
                    <Td>{item.action}</Td>
                    <Td>{moment(item.date).format("DD/MM/YYYY HH:mm:ss")}</Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>

        <Pagination
          next={() => paginate(data.current_page + 1)}
          previous={() => paginate(data.current_page - 1)}
          currentPage={data.current_page}
          last_page={data.last_page}
          pageOne={() => paginate(1)}
        />
      </Container>
    </div>
  );
}

export default Timeline;

const Divider = styled.div`
  height: 4px;
  background-color: #afdaa1;
`;

const Title = styled.div`
  color: #545358;
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0 12px 30px;
`;
