import React from "react";
import styled from "styled-components";
import Select from "react-select";
import { Formik } from "formik";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Outline from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Button from "../Buttons/Button";
import Divider from "../Divider";

var moment = require("moment");

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 12px 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 12px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;

function Filters({ clients, search_filter, filters, initFilters_value }) {
  const matches = useMediaQuery("(max-width:768px)");

  return (
    <Formik
      onSubmit={(_, { resetForm }) => {
        resetForm();
        search_filter(initFilters_value);
      }}
      enableReinitialize
      initialValues={filters}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <>
          <Container>
            <div style={{ width: 240, marginRight: 10 }}>
              <Select
                isClearable
                onChange={(item) => {
                  item === null
                    ? setFieldValue("client_id", null)
                    : setFieldValue("client_id", item.value);
                }}
                options={clients}
                placeholder="Buscar Cliente"
                styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
                value={clients.filter(
                  (item) => item.value === values.client_id
                )}
              />
            </div>

            <MuiPickersUtilsProvider moment={moment} utils={DateFnsUtils}>
              <KeyboardDatePicker
                clearable
                margin="dense"
                inputVariant="outlined"
                label="Fecha Creación"
                format="dd/MM/yyyy"
                value={values.date}
                onChange={(date) => {
                  if (date === null) {
                    setFieldValue("date", null);
                    setFieldValue("creation_date", null);
                  } else {
                    setFieldValue("date", date);
                    setFieldValue(
                      "creation_date",
                      moment(date).format("YYYY-MM-DD")
                    );
                  }
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                fullWidth
                style={{ fontWeight: 500, width: 240, marginRight: 10 }}
              />
            </MuiPickersUtilsProvider>
          </Container>

          <Divider />

          <ButtonContainer>
            <div style={{ margin: matches ? "0 0 20px 0 " : "0 8px 0 20px" }}>
              <Button
                text="Buscar"
                action={() => search_filter(values)}
                width={matches ? "100%" : "200px"}
                color="primary"
                icon="search"
                side="right"
              />
            </div>

            <Outline
              onClick={handleSubmit}
              style={{
                width: matches ? "100%" : 200,
              }}
              variant="outlined"
              color="primary"
            >
              Limpiar Filtros
            </Outline>
          </ButtonContainer>
        </>
      )}
    </Formik>
  );
}

export default Filters;
