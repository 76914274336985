import React from "react";
import styled from "styled-components";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";
import Icon from "@material-ui/core/Icon";

import { Label } from "../Text";
import { Container } from "../../assets/utils/styles/global";

const Body = styled(Container)`
  margin-bottom: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

function BodyNonButton({
  title,
  loading,
  children,
  success,
  reload,
  headerIcon
}) {
  return (
    <div>
      <Body>
        <LoadingContainer>
          <Icon style={{ fontSize: 30 }} color="primary">
            {headerIcon}
          </Icon>

          <Label
            size="22px"
            weight="bold"
            style={{ marginRight: 10, marginLeft: 10 }}
          >
            {title.toUpperCase()}
          </Label>

          {!success && (
            <LoadingContainer style={{ marginTop: 2 }}>
              <Label style={{ marginRight: 10 }}>
                Algo ha pasado intentelo de nuevo
              </Label>

              <Icon
                onClick={() => reload()}
                style={{ fontSize: 22, cursor: "pointer" }}
                color="primary"
              >
                cached
              </Icon>
            </LoadingContainer>
          )}
          {loading && <Spinner size={12} color="black" />}
        </LoadingContainer>
      </Body>

      {children}
    </div>
  );
}

export default BodyNonButton;
