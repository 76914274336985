import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import styled, { withTheme } from "styled-components";

import ContextAuth from "../../lib/ContextUser";

import { Title } from "../../components/Text";
import { SimpleAlert, confirmAlert } from "../../components/Alert";
import { Field } from "../../components/Input";
import Body from "../../components/Body/BodyNonButton";
import Calendar from "../../components/Calendar";
import SaveArea from "../../components/SaveArea";

import Clients from "../../components/Selects/Clients";

import Payment from "../../components/Sales/Add/Payment";
import Voucher from "../../components/CreditNote/Voucher";
import AdicionalNote from "../../components/Sales/Add/AdicionalNote";
import Products from "../../components/Sales/Add/Products";

import { Container as BodyContainer } from "../../assets/utils/styles/global";

import { create } from "../../http/credit_note";

var moment = require("moment");

function AddCreditNote({ history, location }) {
  const { user } = useContext(ContextAuth);

  const [loading, setloading] = useState(false);

  const [formValues, setFormValues] = useState({
    date_limit: null,
    payment_type: null,
    client: {
      username: "",
      rnc: "",
      discount: "",
      apply_tax: null,
      credit_limit: "",
    },
    voucher_number: "",
    comments: "",
    products: [],
    prices: {
      subtotal: 0,
      discount: 0,
      itebis: 0,
      total: 0,
    },
  });

  const [isLoading, setIsLoading] = useState(true);

  const edit = location.state.edit;
  const data = location.state.data;

  function cancel() {
    confirmAlert(
      "warning",
      "¿Está seguro de que quiere cancelar el proceso?"
    ).then((data) => data.value && history.goBack());
  }

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      const information = {
        id: data.id,
        date_limit: data.date_limit,
        payment_type: data.payment_code,
        client: { value: data.client_id, rnc: "", discount: "", username: "" },
        comments: data.comments,
        products: [],
        prices: {},
      };

      setFormValues(information);
      setIsLoading(false);
    }

    setIsLoading(false);
    fetch();
  }, [data]);

  async function save(values) {
    values.created_at = moment(new Date()).format("YYYY-MM-DD");
    values.admin = user.id;

    setloading(true);
    const { data } = await create(values);
    const success = data.success ? "success" : "error";

    SimpleAlert(success, data.data);
    setloading(false);
    success && history.goBack();
  }

  return (
    <Body
      title={edit ? "Editar Nota de Credito" : "Añadir nueva Nota de credito"}
      loading={isLoading}
      success
      headerIcon={edit ? "edit" : "person_add"}
    >
      {!isLoading && (
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(value) => save(value)}
        >
          {({ handleChange, handleSubmit, values, setFieldValue }) => (
            <div>
              <Container>
                <InputContainer>
                  <Title color="#545358" style={{ textAlign: "center" }}>
                    Información del cliente
                  </Title>

                  <Clients
                    isDisabled
                    edit={true}
                    showInformation={true}
                    values={values.client}
                    onChangeEdit={(selected) =>
                      setFieldValue("client", selected)
                    }
                  />
                </InputContainer>

                <InputContainer>
                  <Title color="#545358" style={{ textAlign: "center" }}>
                    Información de la factura
                  </Title>

                  <div style={{ marginTop: 16 }}>
                    <Calendar
                      isDisable
                      setDateState={(date) => setFieldValue("date_limit", date)}
                      text="Periodo de plazo"
                      value={values.date_limit}
                    />
                  </div>

                  <Field
                    label="Vendedor"
                    margin="dense"
                    variant="outlined"
                    value={values.client.username}
                    style={{ marginTop: 16 }}
                    disabled
                  />

                  <Payment
                    isDisabled
                    value={values.payment_type}
                    onChange={(item) =>
                      setFieldValue("payment_type", item.value)
                    }
                  />
                </InputContainer>
              </Container>

              <Container>
                <InputContainer>
                  <Title color="#545358" style={{ textAlign: "center" }}>
                    Comprobante
                  </Title>

                  <Voucher
                    values={values}
                    setVoucherId={(item) => setFieldValue("voucher_id", item)}
                    setVoucherNumber={(item) =>
                      setFieldValue("voucher_number", item)
                    }
                  />
                </InputContainer>

                <InputContainer>
                  <Title color="#545358" style={{ textAlign: "center" }}>
                    Notas Adicionales
                  </Title>

                  <AdicionalNote
                    value={values.comments}
                    onChange={handleChange("comments")}
                    isDisabled
                  />
                </InputContainer>
              </Container>

              <Container>
                <Products
                  credit_note
                  edit={edit}
                  products={values.products}
                  prices={values.prices}
                  client={values.client}
                  setProducts={(item) => setFieldValue("products", item)}
                  setPrices={(item) => setFieldValue("prices", item)}
                />
              </Container>

              <SaveArea
                isDisabled={values.voucher_number === ""}
                handleSubmit={handleSubmit}
                loading={loading}
                success
                cancel={() => cancel()}
              />
            </div>
          )}
        </Formik>
      )}
    </Body>
  );
}

export default withTheme(withRouter(AddCreditNote));

const InputContainer = styled(BodyContainer)`
  padding: 30px;
  padding-top: 10px;
  width: 49%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
