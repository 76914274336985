import React, { useEffect, useState, Fragment } from "react";
import styled, { withTheme } from "styled-components";

import { Label } from "../Text";
import { Logo } from "../Image";

import LOGO from "../../assets/images/logoDairies.jpeg";

var moment = require("moment");

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: ${(props) => props.color};
`;

const InformationInvoice = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
`;

const Content = styled.div`
  color: black;
  font-size: 14px;
`;

function Header({ theme, data, isCreditNote, isConduce }) {
  const invoice = !isCreditNote || !isConduce;

  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    if (isConduce) {
      setTitle("CONDUCE");
      setCode("No. Conduce");
    } else if (isCreditNote) {
      setTitle("NOTA DE CREDITO");
      setCode("No. Nota de credito");
    } else {
      setTitle("FACTURA");
      setCode("No. Factura");
    }
  }, [isConduce, isCreditNote]);

  return (
    <Fragment>
      <Container>
        <div>
          <Logo style={{ width: 70, height: 64 }} src={LOGO} />
          <div style={{ display: "flex", marginTop: 8 }}>
            <Content style={{ fontWeight: 600 }}>Nombre:</Content>
            <Content style={{ marginLeft: 4 }}>
              Dairies & Foods Company, SRL
            </Content>
          </div>

          <div style={{ display: "flex", marginTop: 8 }}>
            <Content style={{ fontWeight: 600 }}>RNC:</Content>
            <Content style={{ marginLeft: 4 }}>1-31-46126-3</Content>
          </div>

          <div style={{ display: "flex", marginTop: 8 }}>
            <Content style={{ fontWeight: 600 }}> Fecha de creación:</Content>
            <Content style={{ marginLeft: 4 }}>
              {moment(data.created_at).format("DD/MM/YYYY")}
            </Content>
          </div>

          {!isCreditNote && data.payment_code === "PM005" && (
            <div style={{ display: "flex", marginTop: 8 }}>
              <Content style={{ fontWeight: 600 }}>
                Fecha de expiración:
              </Content>

              <Content style={{ marginLeft: 4 }}>
                {moment(data.date_limit).format("DD/MM/YYYY")}
              </Content>
            </div>
          )}
        </div>

        <div style={{ marginLeft: 16 }}>
          <Title style={{ fontSize: 28 }} color={theme.colors.PRIMARY}>
            {title}
          </Title>

          {!invoice && (
            <InformationInvoice>
              <Label style={{ marginTop: 4 }} weight={600}>
                Factura#
              </Label>

              <Label style={{ marginTop: 4 }}>{data.number_code}</Label>
            </InformationInvoice>
          )}

          {!isConduce && data.voucher_number && (
            <InformationInvoice>
              <Label style={{ marginTop: 4 }} weight={600}>
                Secuencia de comprobante
              </Label>

              <Label style={{ marginTop: 4 }}>{data.voucher_number}</Label>
            </InformationInvoice>
          )}

          {isCreditNote && (
            <InformationInvoice>
              <Label style={{ marginTop: 4 }} weight={600}>
                NCF Factura
              </Label>

              <Label style={{ marginTop: 4 }}>
                {data.sales_voucher_number}
              </Label>
            </InformationInvoice>
          )}

          {!isConduce && data.voucher_number && (
            <InformationInvoice>
              <Label style={{ marginTop: 4 }} weight={600}>
                Vencimiento de secuencia:
              </Label>

              <Label style={{ marginTop: 4 }}>
                {moment(data.tax_expiration_date).format("DD/MM/YYYY")}
              </Label>
            </InformationInvoice>
          )}

          <InformationInvoice>
            <Label style={{ marginTop: 4 }} weight={600}>
              Vendedor
            </Label>

            <Label style={{ marginTop: 4 }}>{data.username}</Label>
          </InformationInvoice>

          {invoice && (
            <InformationInvoice>
              <Label style={{ marginTop: 4 }} weight={600}>
                Usuario
              </Label>

              <Label style={{ marginTop: 4 }}>{data.create_user}</Label>
            </InformationInvoice>
          )}

          <InformationInvoice>
            <Label style={{ marginTop: 4 }} weight={600}>
              Condición
            </Label>

            <Label style={{ marginTop: 4 }}>
              {data.payment_code === "PM005" ? "Crédito" : "Contado"}
            </Label>
          </InformationInvoice>

          {data.payment_code === "PM005" && (
            <>
              <InformationInvoice>
                <Label style={{ marginTop: 4 }} weight={600}>
                  Vencimiento
                </Label>

                <Label style={{ marginTop: 4 }}>
                  {moment(data.date_limit).format("DD/MM/YYYY")}
                </Label>
              </InformationInvoice>

              <InformationInvoice>
                <Label style={{ marginTop: 4 }} weight={600}>
                  Plazo
                </Label>

                <Label style={{ marginTop: 4 }}>
                  {data.client_deadline} dias
                </Label>
              </InformationInvoice>
            </>
          )}

          <InformationInvoice>
            <Label style={{ marginTop: 4 }} weight={600}>
              {code}
            </Label>

            <Label style={{ marginTop: 4 }}>#{data.number_code}</Label>
          </InformationInvoice>
        </div>
      </Container>
    </Fragment>
  );
}

export default withTheme(Header);
