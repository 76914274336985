import React from "react";
import { withTheme } from "styled-components";
import styled from "styled-components";

import { Icon } from "@material-ui/core";

import { Label } from "../Text";

import { Container } from "../../assets/utils/styles/global";

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

const CardContainer = styled(Container)`
  margin: 20px 0;
  width: 32%;

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px 0 0 0;
  }
`;

const Title = styled.div`
  font-size: 12px;
  margin-bottom: 12px;
`;

const IconContainer = styled.div`
  height: 52px;
  width: 66px;
  border-radius: 8px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color || "red"};
`;

function Card(props) {
  return (
    <CardContainer>
      <Title>{props.title}</Title>

      <RowContainer>
        <>
          <IconContainer color={props.iconColor}>
            <Icon style={{ color: "white", fontSize: 36 }}>{props.icon}</Icon>
          </IconContainer>

          <Label style={{ fontSize: 40 }}>{props.data}</Label>
        </>
      </RowContainer>
    </CardContainer>
  );
}

export default withTheme(Card);
