/* eslint-disable default-case */
import React, { useState, useEffect, Fragment } from "react";
import { withTheme } from "styled-components";
import styled from "styled-components";

import BarChart from "../components/Dashboard/Barchart";
import Card from "../components/Dashboard/Card";
import NotPermissions from "../components/NotPermissions";

import { Container } from "../assets/utils/styles/global";

import { getOverView, getBarCharData } from "../http/dashboard";

import check_permission from "../lib/check_permission";

var numeral = require("numeral");

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const BarContainer = styled(Container)`
  height: 50%;

  @media (max-width: 768px) {
    display: none;
  }
`;

function Dashboard() {
  const [cardData, setCardData] = useState({});
  const [chart, setChart] = useState({});
  const canSee = check_permission("PE001", "canSee");

  useEffect(() => {
    async function fetch() {
      const { data } = await getOverView();
      setCardData(data.data);
    }

    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      const { data } = await getBarCharData();

      var labelArr = data.map((lab) => {
        return lab.label;
      });

      var dataArr = data.map((lab) => {
        return lab.data;
      });

      setChart({
        labels: labelArr,
        datasets: [
          {
            label: "Total vendido por día",
            data: dataArr,
            backgroundColor: "rgba(115, 201, 119)",
          },
        ],
      });
    }

    fetch();
  }, []);

  if (!canSee) {
    return <NotPermissions />;
  }

  return (
    <Fragment>
      {canSee && (
        <Fragment>
          <CardContainer>
            <Card
              data={numeral(cardData.onMonthTotalSale).format("0,0.00")}
              icon="attach_money"
              iconColor="#43a047"
              title="TOTAL VENDIDO DEL MES"
            />

            <Card
              data={cardData.totalPending}
              icon="timer"
              iconColor="#ffb74d"
              title="FACTURAS PENDIENTES DE COBRO"
            />

            <Card
              data={cardData.todayTotalSale}
              icon="bar_chart"
              iconColor="#f44336"
              title="FACTURAS REALIZADAS HOY"
            />
          </CardContainer>

          <BarContainer>
            <BarChart data={chart} Type="Bar" position="top" />
          </BarContainer>
        </Fragment>
      )}
    </Fragment>
  );
}

export default withTheme(Dashboard);
