import { createMuiTheme } from "@material-ui/core/styles";
import theme from "./theme";

export const themeContainer = createMuiTheme({
  palette: {
    primary: {
      main: `${theme.colors.PRIMARY}`
    },
    secondary: {
      main: "#d51010"
    }
  }
});
