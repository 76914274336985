/* eslint-disable array-callback-return */
import React, { useState, useEffect, Fragment } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import Select from "react-select";
import InputMask from "react-input-mask";

import { Title, Label } from "../../components/Text";
import { SimpleAlert, confirmAlert } from "../../components/Alert";
import { TextArea, Field } from "../../components/Input";
import SaveArea from "../../components/SaveArea";
import DetailsHeader from "../../components/DetailsHeader";
import Calendar from "../../components/Calendar";
import Radio from "../../components/RadioButton";

import { Container as BodyContainer } from "../../assets/utils/styles/global";

import { create, update } from "../../http/visited_clients";
import { usersListForSelect } from "../../http/users";

const initValues = {
  name: "",
  address: "",
  city: "",
  phone: "",
  cell_phone: "",
  email: "",
  seller_id: "",
  notes: "",
  date: new Date(),
  buy_me: false,
};

function AddStateClient({ history, location }) {
  const [loading, setloading] = useState(false);
  const [users, setUsers] = useState([]);
  const [formValues, setFormValues] = useState(initValues);

  const [isLoading, setIsLoading] = useState(true);

  const edit = location.state.edit;
  const clientData = location.state.clientData;

  useEffect(() => {
    edit && setFormValues(clientData);
  }, [clientData, edit]);

  useEffect(() => {
    async function fetchSellers() {
      try {
        setIsLoading(true);

        const { data } = await usersListForSelect();
        setUsers(data);
      } catch (error) {
        return error;
      } finally {
        setIsLoading(false);
      }
    }

    fetchSellers();
  }, []);

  async function submit(value) {
    try {
      setloading(true);

      const { data } = edit ? await update(value) : await create(value);
      const type = data.success ? "success" : "error";

      if (data.success) {
        if (edit) {
          history.push({
            pathname: "details",
            state: { data: data.information[0] },
          });
        } else {
          history.goBack();
        }
      }

      SimpleAlert(type, data.data);
    } catch (error) {
      SimpleAlert("error", "Algo ha pasado por favor intentelo de nuevo");
    } finally {
      setloading(false);
    }
  }

  function cancel() {
    confirmAlert(
      "warning",
      "¿Está seguro de que quiere cancelar el proceso?"
    ).then((data) => data.value && history.goBack());
  }

  return (
    <Fragment>
      <DetailsHeader
        icon={edit ? "edit" : "add"}
        title={edit ? "Editar Cliente" : "Añadir Nuevo Cliente"}
      />

      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={(value) => submit(value)}
      >
        {({ handleChange, handleSubmit, values, setFieldValue }) => (
          <div>
            <Container>
              <InputContainer>
                <Title color="#545358" style={{ textAlign: "center" }}>
                  Información del cliente
                </Title>

                <Field
                  onChange={handleChange("name")}
                  label="Nombre del cliente"
                  margin="dense"
                  variant="outlined"
                  value={values.name}
                />

                <Field
                  onChange={handleChange("email")}
                  label="Correo Electrónico"
                  margin="dense"
                  variant="outlined"
                  value={values.email}
                  style={{ marginTop: 15 }}
                />

                <Field
                  onChange={handleChange("address")}
                  label="Dirección"
                  margin="dense"
                  variant="outlined"
                  value={values.address}
                  style={{ marginTop: 15 }}
                />

                <InputMask
                  onChange={handleChange("phone")}
                  value={values.phone}
                  mask="999-999-9999"
                  style={{ marginTop: 15 }}
                >
                  {() => (
                    <Field
                      variant="outlined"
                      label="Numero Telefonico"
                      margin="dense"
                    />
                  )}
                </InputMask>

                <InputMask
                  onChange={handleChange("cell_phone")}
                  value={values.cell_phone}
                  mask="999-999-9999"
                  style={{ marginTop: 15 }}
                >
                  {() => (
                    <Field
                      variant="outlined"
                      label="Numero Celular"
                      margin="dense"
                    />
                  )}
                </InputMask>

                <Field
                  onChange={handleChange("city")}
                  label="Provincia"
                  margin="dense"
                  variant="outlined"
                  value={values.city}
                  style={{ marginTop: 15 }}
                />
              </InputContainer>

              <InputContainer>
                <Title color="#545358" style={{ textAlign: "center" }}>
                  Mas Detalles
                </Title>

                {!edit && (
                  <Calendar
                    setDateState={(date) => setFieldValue("date", date)}
                    text="Fecha de visita"
                    value={values.date}
                  />
                )}

                <div style={{ marginTop: "16px" }}>
                  <Select
                    isClearable
                    onChange={(data) =>
                      data
                        ? setFieldValue("seller_id", data.value)
                        : setFieldValue("seller_id", null)
                    }
                    options={users}
                    placeholder={
                      edit ? clientData.username : "Seleccione el vendedor"
                    }
                    value={users.filter((data) => {
                      const result =
                        Number(data.value) === Number(values.seller_id);

                      if (result) {
                        return data.label;
                      }
                    })}
                  />

                  {!edit && (
                    <RadioContainer>
                      <Label weight={500}>Me ha comprado?:</Label>

                      <Radio
                        action={() => setFieldValue("buy_me", true)}
                        selected={values.buy_me}
                        label="Sí"
                        margin="0 0 0 3px"
                      />

                      <Radio
                        action={() => setFieldValue("buy_me", false)}
                        selected={!values.buy_me}
                        label="No"
                        margin="0 5px 0 0"
                      />
                    </RadioContainer>
                  )}
                </div>
              </InputContainer>
            </Container>

            <InputContainer style={{ marginTop: 20, width: "100%" }}>
              <Title color="#545358" style={{ textAlign: "center" }}>
                Notas adicionales
              </Title>

              <TextArea
                value={values.notes}
                onChange={handleChange("notes")}
                rows="4"
              />
            </InputContainer>

            <SaveArea
              handleSubmit={handleSubmit}
              loading={loading || isLoading}
              success={true}
              cancel={() => cancel()}
            />
          </div>
        )}
      </Formik>
    </Fragment>
  );
}

export default withTheme(withRouter(AddStateClient));

const InputContainer = styled(BodyContainer)`
  padding: 30px;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RadioContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
`;
