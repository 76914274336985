import { API } from "../actions/credit-note-actions";

function creditNoteReducer(state = [], { type, payload }) {
  switch (type) {
    case API:
      return payload.creditNote;
    default:
      return state;
  }
}

export default creditNoteReducer;
