import React, { Fragment, useState, useEffect } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import LinearProgress from "@material-ui/core/LinearProgress";
import Outline from "@material-ui/core/Button";
import { Tooltip, Icon } from "@material-ui/core";

import { Label } from "../../components/Text";
import Button from "../../components/Buttons/Button";
import Circle from "../../components/Buttons/Circle";
import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import DetailsHeader from "../../components/DetailsHeader";

import check_permission from "../../lib/check_permission";

import { Container } from "../../assets/utils/styles/global";

import {
  order_products,
  production_order,
  delete_product_order,
} from "../../http/orders";

var moment = require("moment");

function OrderDetails({ location, history }) {
  const order = location.state.data;

  const canSee = check_permission("PE012", "canSee");

  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [products, setProducts] = useState([]);
  const [inProduction, setInProduction] = useState(order.in_production);
  const [isProductionLoading, setIsProductionLoading] = useState(false);
  const [refreshProducts, setRefreshProducts] = useState(false);

  useEffect(() => {
    async function fetchProducts() {
      setIsLoadingProduct(true);
      const { data } = await order_products(order.id);
      setProducts(data);
      setIsLoadingProduct(false);
    }

    fetchProducts();
  }, [order, refreshProducts]);

  const list = (key, value) => {
    return (
      <RowContainer>
        <Label weight={600}>{key}: </Label>
        <Label style={{ marginLeft: 8 }}>{value}</Label>
      </RowContainer>
    );
  };

  async function handleProduction() {
    try {
      setIsProductionLoading(true);
      await production_order({
        id: order.id,
        in_production: !inProduction,
      });
      setInProduction(!inProduction);
    } catch (err) {
      return err;
    } finally {
      setIsProductionLoading(false);
    }
  }

  async function handleDelete(id) {
    try {
      await delete_product_order({ id });
      setRefreshProducts(!refreshProducts);
    } catch (err) {
      return err;
    } finally {
    }
  }

  return (
    <Fragment>
      <DetailsHeader icon="description" title="Información De la orden">
        <Circle
          disabled={!check_permission("PE012", "canEdit")}
          title="Editar"
          icon="edit"
          action={() =>
            history.push({ pathname: "add", state: { order, edit: true } })
          }
        />
        {inProduction ? (
          <Outline
            onClick={handleProduction}
            style={{ width: "250px", height: 40 }}
            variant="outlined"
            color="primary"
          >
            Sacar de produccion
          </Outline>
        ) : (
          <Button
            loading={isProductionLoading}
            text="Mandar a produccion"
            action={handleProduction}
            width="250px"
            color="primary"
            style={{ boxShadow: "none", color: "white", height: 40 }}
          />
        )}
      </DetailsHeader>

      <Body>
        <Card>
          {list("Cliente", order.client.name)}
          {list("Estado", order.description)}
          {list("Fecha", moment(order.date).format("DD/MM/YYYY"))}
        </Card>

        <Card></Card>
      </Body>

      <Container style={{ marginTop: 20, padding: "12px 0 0 0" }}>
        <Title>Ordenes</Title>

        {isLoadingProduct ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        {canSee ? (
          <TableContainer style={{ marginTop: 20 }}>
            <Table>
              <tbody>
                <Tr>
                  <Th>Name</Th>
                  <Th>Cantidad</Th>
                  <Th>Eliminar</Th>
                </Tr>

                {products.map((data, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{data.name}</Td>
                      <Td>{data.quantity}</Td>
                      <Td style={{ cursor: "pointer" }}>
                        <Tooltip placement="right" title="Eliminar">
                          <Icon
                            color="primary"
                            onClick={() => handleDelete(data.id)}
                          >
                            delete
                          </Icon>
                        </Tooltip>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        ) : (
          <div>{!isLoadingProduct && "no permisos"}</div>
        )}
      </Container>
    </Fragment>
  );
}

export default withTheme(withRouter(OrderDetails));

const RowContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #f8f8f8;

  :nth-child(even) {
    background-color: white;
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  height: 4px;
  background-color: #afdaa1;
`;

const Title = styled.div`
  color: #545358;
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0 12px 30px;
`;

const Card = styled(Container)`
  padding: 0;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
