import http from "./http";

export function create(data) {
  return http.post("conduces/create", data);
}

export function update(data) {
  return http.put("conduces/update", data);
}

export function grid(page, data) {
  return http.post(`conduces/grid?page=${page}`, data);
}
export function details(id) {
  return http.get(`conduces/details?id=${id}`);
}

export function updateStatus(data) {
  return http.put(`conduces/updateStatus`, data);
}

export function conduce_products(id) {
  return http.get("conduces/conduce_products", { params: { id: id } });
}
