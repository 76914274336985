import React from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function RadioButton({ selected, action, label, margin, ...props }) {
  return (
    <FormControlLabel
      control={
        <Radio
          checked={selected}
          onChange={() => action()}
          color="primary"
          name="radio-button-demo"
        />
      }
      label={label}
      labelPlacement="right"
      style={{ margin: `${margin}` }}
      {...props}
    />
  );
}

export default RadioButton;
