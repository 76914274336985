import React, { useState, useContext, Fragment } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import styled, { withTheme } from "styled-components";

import Icon from "@material-ui/core/Icon";
import Collapse from "@material-ui/core/Collapse";

import { in_system } from "../../routes";
import { menu_routes } from "./sideMenuRoutes";
import { Label } from "../Text";
import TopBar from "../TopBar";
import Avatar from "./Avatar";

import ContextAuth from "../../lib/ContextAuth";
import ContextUser from "../../lib/ContextUser";

const visitClientAllowId = 561;

function SideMenu({ theme }) {
  const { setLogged } = useContext(ContextAuth);
  const { user } = useContext(ContextUser);

  const userAllowed = Number(user.id) === visitClientAllowId;

  const currentRoute = window.location.pathname;

  const [isOpen, setIsOpen] = useState(false);
  const [sideMenuActive, setSideMenuActive] = useState(
    window.location.pathname
  );

  function logout() {
    setLogged(false);
    localStorage.clear();
  }

  function verifyRoute(route) {
    const matchModule = route === "/state_client/grid";

    if (matchModule) {
      if (userAllowed) return true;
      else return false;
    }

    return true;
  }

  return (
    <div style={{ display: "flex" }}>
      <Router>
        {currentRoute !== "/order/list" && (
          <Container background={theme.colors.SIDE_MENU}>
            <div style={{ height: "90%" }}>
              <Avatar logOut={() => logout()} name={user.name} />

              {menu_routes.map((route, index) => {
                const active = sideMenuActive === route.path;
                const canShow = verifyRoute(route.path);

                return (
                  <Fragment key={index}>
                    {canShow && (
                      <>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={!route.subRoute && route.path}
                          onClick={(event) => {
                            setIsOpen(false);

                            if (route.subRoute) {
                              event.preventDefault();
                              setIsOpen(!isOpen);
                            }

                            setSideMenuActive(route.path);
                          }}
                        >
                          <SideMenuContainer
                            color={
                              active ? `${theme.colors.PRIMARY}` : "transparent"
                            }
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Icon style={{ color: "white" }}>
                                {route.icon}
                              </Icon>

                              <Label
                                style={{ marginLeft: 10 }}
                                color={active ? "white" : "#CCCCCC"}
                                active={active}
                              >
                                {route.name}
                              </Label>
                            </div>

                            {active && (
                              <Label style={{ fontSize: 28 }} color="white">
                                •
                              </Label>
                            )}
                          </SideMenuContainer>
                        </Link>

                        {route.subRoute &&
                          route.subRoute.map((subRoute, j) => {
                            const activeSub = sideMenuActive === subRoute.path;

                            return (
                              <Collapse key={j} in={isOpen}>
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={subRoute.path}
                                  onClick={() =>
                                    setSideMenuActive(subRoute.path)
                                  }
                                >
                                  <SideMenuContainer
                                    style={{ marginTop: 0 }}
                                    color={
                                      activeSub ? "#4b5068" : "transparent"
                                    }
                                  >
                                    <Label
                                      style={{ marginLeft: 10 }}
                                      color={active ? "white" : "#CCCCCC"}
                                      active={active}
                                    >
                                      {subRoute.name}
                                    </Label>

                                    {activeSub && (
                                      <Label
                                        style={{ fontSize: 28 }}
                                        color="white"
                                      >
                                        •
                                      </Label>
                                    )}
                                  </SideMenuContainer>
                                </Link>
                              </Collapse>
                            );
                          })}
                      </>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </Container>
        )}

        <Parent>
          <TopBar />
          <ViewsContainer>
            {in_system.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.main}
              />
            ))}
          </ViewsContainer>
        </Parent>
      </Router>
    </div>
  );
}

export default withTheme(SideMenu);

const Container = styled.div`
  height: 100vh;
  width: 220px;
  background-color: ${(props) => props.background};
  border-right: 1px solid #eaeaea;
  padding: 0px 28px 0px 0px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ViewsContainer = styled.div`
  width: 100%;
  padding: 20px;
  overflow-x: hidden;
  box-sizing: border-box;
`;

const Parent = styled.div`
  width: 100%;
`;

const SideMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.color};
  padding: 0px 15px 0px 14px;
  margin-top: 8px;
  width: 99.5%;
  height: 44px;
`;
