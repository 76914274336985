import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";

import { Label } from "../Text";

import { listForSelect } from "../../http/taxReceipt";

function NCF({ onChange, value, ...props }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const { data } = await listForSelect();
      setData(data);

      setIsLoading(false);
    }

    fetchData();
  }, []);

  return (
    <Fragment>
      <Label weight={500} {...props}>
        Seleccione el tipo de comprobante
      </Label>

      <Select
        placeholder={isLoading ? "Cargando..." : "Comprobante Fiscal"}
        options={data}
        styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
        onChange={onChange}
        isDisabled={isLoading || props.isDisabled}
        value={data.filter((data) => data.value === Number(value))}
      />
    </Fragment>
  );
}

export default NCF;
