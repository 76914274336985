import http from "./http";

export function getUserList() {
  return http.get("products/list");
}

export function getPaymentTypeList(code) {
  return http.get("appCodes/getCodesByParam", { params: { code: code } });
}

export function createSale(data) {
  return http.post("sales/createSale", data);
}

export function updateSale(data) {
  return http.put("sales/updateSale", data);
}

export function getSaleInfo(id) {
  return http.get("sales/getSaleInfo", { params: { id: id } });
}

export function getSalesList() {
  return http.get("sales/getSalesList");
}

export function details(id) {
  return http.get(`sales/details?id=${id}`);
}

export function getFirstTaxReceive(id) {
  return http.get("taxReceipt/getFirstTaxReceive", { params: { id: id } });
}

export function updateStatus(data) {
  return http.put(`sales/updateSaleStatus`, data);
}

export function create(data) {
  return http.post("sales/create", data);
}

export function update(data) {
  return http.put("sales/update", data);
}

export function sold_products(id) {
  return http.get("sales/sold_products", { params: { id: id } });
}

export function sales_per_client(id, page) {
  return http.get(`sales/sales_per_client?page=${page}&id=${id}`);
}

export function grid(page, data) {
  return http.post(`sales/grid?page=${page}`, data);
}

export function about_expire(page, data) {
  return http.post(`sales/about_expire?page=${page}`, data);
}

export function expired(page, data) {
  return http.post(`sales/expired?page=${page}`, data);
}

export function change_status_expired() {
  return http.put("sales/change_status_expired");
}

export function add_abono(data) {
  return http.post("sales/add_abono", data);
}

export function get_abonos({ page, data }) {
  return http.post(`sales/get_abonos?page=${page}`, data);
}

export function total_abonos(id) {
  return http.get("sales/total_abonos", { params: { sale_id: id } });
}

export function all_abonos(id) {
  return http.get("sales/all_abonos", { params: { sale_id: id } });
}
