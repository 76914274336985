export const API = "clients:dataClient";

export function clientData(data) {
  return {
    type: API,
    payload: {
      client: data
    }
  };
}
