import { API } from "../actions/products-actions";

function productsReducer(state = [], { type, payload }) {
  switch (type) {
    case API:
      return payload.products;
    default:
      return state;
  }
}

export default productsReducer;
