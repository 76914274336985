/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Label } from "../Text";
import { SimpleAlert } from "../Alert";

import { getFirstTaxReceive } from "../../http/sales";

function Voucher({ setVoucher, values, ...props }) {
  const [isLoadingNumber, setIsLoadingNumber] = useState(false);

  useEffect(() => {
    async function fetch() {
      setIsLoadingNumber(true);

      try {
        const { data } = await getFirstTaxReceive("161");

        if (data.success) {
          props.setVoucherId(data.data.id);
          props.setVoucherNumber(data.data.numeration);
        } else {
          SimpleAlert("info", data.data);
          props.setVoucherNumber("");
        }
      } catch (error) {}

      setIsLoadingNumber(false);
    }

    fetch();
  }, []);

  return (
    <div style={{ marginTop: 16 }}>
      <Label weight={500} style={{ marginTop: 16 }}>
        Numero de comprobante:{" "}
        {isLoadingNumber ? "Cargando..." : `${values.voucher_number}`}
      </Label>
    </div>
  );
}

export default Voucher;
