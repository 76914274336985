/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";

import LinearProgress from "@material-ui/core/LinearProgress";

import { Tr, Table, Td, Th, TableContainer } from "../components/Table";
import Header from "../components/Table/Header";
import Pagination from "../components/Table/Pagination";
import { Label } from "../components/Text";
import Divider from "../components/Divider";
import NotPermissions from "../components/NotPermissions";

import { Container } from "../assets/utils/styles/global";

import check_permission from "../lib/check_permission";

import { grid } from "../http/history";
import { usersListForSelect } from "../http/users";

var moment = require("moment");

function History() {
  const initFilters_value = { user_id: null };

  const [filters, setFilters] = useState({ data: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [filters_value, setFilters_value] = useState(initFilters_value);

  const canSee = check_permission("PE010", "canSee");

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      try {
        const { data } = await grid(1);

        setFilters(data);
      } catch {}

      setIsLoading(false);
    }

    fetch();
  }, []);

  async function paginate(page, items) {
    setIsLoading(true);
    setFilters_value(items);

    const { data } = await grid(page, items);
    setFilters(data);

    setIsLoading(false);
  }

  useEffect(() => {
    async function fetch() {
      setIsLoadingUser(true);
      const { data } = await usersListForSelect();
      setUsers(data);
      setIsLoadingUser(false);
    }

    fetch();
  }, []);

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Historial de productos añadidos
      </Label>

      <Container style={{ padding: "12px 0 0 0" }}>
        <Header
          items={users}
          loading={isLoadingUser}
          setFilter={(item) => paginate(1, { user_id: item.value })}
        />

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        {canSee ? (
          <TableContainer>
            <Table>
              <tbody>
                <Tr>
                  <Th>Usuario</Th>
                  <Th>Producto</Th>
                  <Th>Cantidad añadida</Th>
                  <Th>Fecha</Th>
                </Tr>

                {filters.data.map((data) => (
                  <Tr key={data.id}>
                    <Td>{data.username}</Td>
                    <Td>{data.name}</Td>
                    <Td>{data.added_quantity}</Td>
                    <Td>{moment(data.date).format("DD/MM/YYYY")}</Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        ) : (
          <NotPermissions isLoading={isLoading} />
        )}

        <Pagination
          next={() => paginate(filters.current_page + 1, filters_value)}
          previous={() => paginate(filters.current_page - 1, filters_value)}
          currentPage={filters.current_page}
          last_page={filters.last_page}
          pageOne={() => paginate(1, filters_value)}
        />
      </Container>
    </Fragment>
  );
}

export default withTheme(withRouter(History));
