import React, { useState, useEffect, Fragment } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import LinearProgress from "@material-ui/core/LinearProgress";

import { timelineCode } from "../../lib/constant";

import Loading from "../../components/Loading";
import { Label } from "../../components/Text";
import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Timeline from "../../components/Timeline";

import { Container } from "../../assets/utils/styles/global";

import Header from "../../parts/quote/header";

import { details, get_products } from "../../http/quote";
import { getTimeline } from "../../http/timeline";

var moment = require("moment");
var numeral = require("numeral");

function QuoteDetails({ location, history }) {
  const id = location.state.id;

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [timeline, setTimeline] = useState({ data: [] });
  const [timelinePage, setTimelinePage] = useState(1);
  const [updateHistory, setUpdateHistory] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const { data } = await details(id);
        data[0].sale_discount = data[0].discount;
        setData(data[0]);
      } catch {}
      setIsLoading(false);
    }

    fetchData();
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      setIsLoadingProduct(true);

      try {
        const { data } = await get_products(id);
        setProducts(data);
      } catch {}
      setIsLoadingProduct(false);
    }

    fetchData();
  }, [id]);

  useEffect(() => {
    async function fetchTimeline() {
      try {
        const response = await getTimeline({
          module_id: id,
          module_name: timelineCode.quote,
          page: timelinePage,
        });

        setTimeline(response.data);
      } catch {}
    }

    fetchTimeline();
  }, [id, timelinePage, updateHistory]);

  const list = (key, value) => {
    return (
      <RowContainer>
        <Label weight={600}>{key}: </Label>
        <Label style={{ marginLeft: 8 }}>{value}</Label>
      </RowContainer>
    );
  };

  return (
    <Fragment>
      <Header
        loading={{ app: isLoading, product: isLoadingProduct }}
        products={products}
        data={data}
        setData={(item) => {
          setUpdateHistory(!updateHistory);
          setData(item);
        }}
        edit={() =>
          history.push({
            pathname: "add",
            state: { edit: true, data: data },
          })
        }
      />

      <Body>
        <Card>
          <Loading loading={isLoading} />

          {!isLoading && (
            <Fragment>
              {list("Código", data.number_code)}

              {list(
                "Fecha de creación",
                moment(data.created_at).format("DD/MM/YYYY")
              )}

              {list("Cliente", data.name)}
              {list("Descuento", data.client_discount + "%")}
              {list("Vendedor", data.username)}
            </Fragment>
          )}
        </Card>

        <Card>
          <Loading loading={isLoading} />

          {!isLoading && (
            <Fragment>
              {list("Sub Total", numeral(data.subtotal).format("0,0.00"))}
              {list("Descuento", numeral(data.discount).format("0,0.00"))}
              {list("ITBIS", numeral(data.itebis).format("0,0.00"))}
              {list("Total de la venta", numeral(data.total).format("0,0.00"))}
              {list("Comentarios", data.comments)}
            </Fragment>
          )}
        </Card>
      </Body>

      <Timeline
        data={timeline}
        paginate={(page) => setTimelinePage(page)}
        style={{ width: "49%" }}
      />

      <Container style={{ marginTop: 20, padding: "12px 0 0 0" }}>
        <Title>Productos</Title>

        {isLoadingProduct ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <TableContainer style={{ marginTop: 16 }}>
          <Table>
            <tbody>
              <Tr>
                {/* <Th>Código</Th> */}
                <Th>Nombre</Th>
                <Th>Costo del producto</Th>
                <Th>Costo deseado</Th>
                <Th>Cantidad a vender</Th>
                <Th>Total</Th>
              </Tr>

              {products.map((data, index) => (
                <Tr key={index}>
                  {/* <Td>{data.code}</Td> */}
                  <Td>{data.name}</Td>
                  <Td>{data.sale_price}</Td>
                  <Td>{data.custom_price}</Td>
                  <Td>{numeral(data.quantity).format(0, 0)}</Td>
                  <Td>
                    {numeral(
                      Number(data.custom_price) * Number(data.quantity)
                    ).format(0, 0)}
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Container>
    </Fragment>
  );
}

export default withTheme(withRouter(QuoteDetails));

const Title = styled.div`
  color: #545358;
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0 12px 30px;
`;

const RowContainer = styled.div`
  display: flex;
  background-color: #f8f8f8;
  align-items: center;
  padding: 20px;

  :nth-child(even) {
    background-color: white;
  }
`;

const Divider = styled.div`
  height: 4px;
  background-color: #afdaa1;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled(Container)`
  padding: 0;
  width: 49%;
  margin-top: 20px;
  min-height: 300px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
