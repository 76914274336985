import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Select from "react-select";
import styled from "styled-components";

import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";

import { Label } from "./Text";
import Button from "./Buttons/Button";
import Modal from "./Modal";
import { Field } from "./Input";
import Calendar from "./Calendar";
import { SimpleAlert } from "./Alert";

import { listForSelect, addReceiptsForCompany } from "../http/taxReceipt";

var numeral = require("numeral");

const SelectContainer = styled.div`
  margin-top: 12px;
  position: relative;
  z-index: 1000;
`;

function AddNewReceipt({ close, closeAndReload }) {
  const [loading, setloading] = useState(false);
  const [items, setItems] = useState([]);
  const [receiptType, setReceiptType] = useState(null);

  useEffect(() => {
    async function texReceiptList() {
      try {
        const { data } = await listForSelect();
        setItems(data);
      } catch (error) {
        SimpleAlert("error", "Algo ha pasado por favor intentelo de nuevo");
      }
    }

    texReceiptList();
  }, []);

  async function submit(value) {
    if (value.numeration.length < 8) {
      SimpleAlert(
        "warning",
        "Por favor verifique la cantidad de numeros del comprobante"
      );
    } else {
      setloading(true);

      try {
        const { data } = await addReceiptsForCompany(value);

        const type = data.success ? "success" : "error";
        data.success && closeAndReload(data.information);

        SimpleAlert(type, data.data);
      } catch (error) {
        SimpleAlert("error", "Algo ha pasado por favor intentelo de nuevo");
      }
      setloading(false);
    }
  }

  return (
    <Modal close={() => close()} open={true}>
      <Formik
        initialValues={{
          numeration: "",
          expire_date: new Date(),
          quantity: "",
        }}
        onSubmit={(value) => submit(value)}
      >
        {({ handleChange, handleSubmit, values, setFieldValue }) => (
          <div style={{ marginTop: 10 }}>
            <Label style={{ marginBottom: 10 }} weight="550">
              Agregar nuevos comprobantes fiscales
            </Label>

            <SelectContainer>
              <Select
                onChange={(data) => {
                  setFieldValue("id_tax_receipts", data.value);
                  setReceiptType(data.prefijo);
                }}
                options={items}
                placeholder="Seleccione el tipo de comprobante fiscal"
              />
            </SelectContainer>

            <FormControl style={{ marginTop: 12 }} fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">
                Numeración inicial
              </InputLabel>

              <OutlinedInput
                id="outlined-adornment-amount"
                value={values.numeration}
                margin="dense"
                onChange={handleChange("numeration")}
                startAdornment={
                  <InputAdornment position="start">
                    {receiptType}
                  </InputAdornment>
                }
                labelWidth={140}
                inputProps={{ maxLength: 8 }}
              />
            </FormControl>

            <Field
              onChange={handleChange("quantity")}
              label="Cantidad de comprobantes"
              margin="dense"
              variant="outlined"
              style={{ marginTop: 12 }}
              value={numeral(values.quantity).format(0, 0)}
            />

            <div style={{ marginTop: 12 }}>
              <Calendar
                setDateState={(date) => setFieldValue("expire_date", date)}
                text="Fecha de vencimiento del comprobante"
                value={values.expire_date}
              />
            </div>

            <Button
              top="12px"
              text="Generar comprobantes"
              action={handleSubmit}
              loading={loading}
              color="primary"
              icon="save"
              side="left"
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default AddNewReceipt;
