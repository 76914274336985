/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";

import { Field } from "../Input";

import { clientListSelect } from "../../http/clients";

var numeral = require("numeral");

function Clients({ edit, onChange, onChangeEdit, values, ...props }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const { data } = await clientListSelect();
      setData(data);

      setIsLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    function setInformation() {
      const result = data.filter((item) => item.value === values.value);
      data.length !== 0 && values.value !== 0 && onChangeEdit(result[0]);
    }

    edit && setInformation();
  }, [edit, values.value, data]);

  useEffect(() => {
    function setInformation() {
      const result = data.filter((item) => item.value === values.value);
      data.length !== 0 && onChangeEdit(result[0]);
    }

    props.conduce && setInformation();
  }, [props.conduce, values.value, data]);

  return (
    <Fragment>
      <Select
        isClearable={props.isClearable}
        placeholder={isLoading ? "Cargando..." : "Cliente"}
        options={data}
        styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
        onChange={onChange}
        isDisabled={isLoading || props.isDisabled}
        value={data.filter((data) => data.value === Number(values.value))}
      />

      {props.showInformation && (
        <Fragment>
          <Field
            label={values.document_type ? "RNC" : "Cédula"}
            margin="dense"
            variant="outlined"
            value={values.document ? values.document : ""}
            style={{ marginTop: 16 }}
            disabled
          />

          <Field
            label="Descuento de Cliente"
            margin="dense"
            variant="outlined"
            value={values.discount}
            style={{ marginTop: 16 }}
            disabled
          />

          <Field
            label="Limite de credito"
            margin="dense"
            variant="outlined"
            value={numeral(values.credit_limit).format("0,0.00")}
            style={{ marginTop: 16 }}
            disabled
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export default Clients;
