/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect, useRef } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";

import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

import ComponentToPrint from "../Bills/ComponentToPrint";

import Circle from "../Buttons/Circle";
import ModalStatus from "./ModalStatus";
import DetailsHeader from "../DetailsHeader";

import check_permission from "../../lib/check_permission";

import { list } from "../../http/codes";

function Header({ products, edit, data, setData, history, ...props }) {
  const [modalStatus, setModalStatus] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const canPrint = check_permission("PE006", "canPrint");

  const componentRef = useRef();

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await list("CD0");
        setStatusList(data);
      } catch {}
    }

    fetchData();
  }, []);

  function changeData(item = false) {
    if (!item) {
      setModalStatus(false);
    } else {
      const newArray = {
        ...data,
        description: item.label,
        status_code: item.value,
      };

      setData(newArray);
      setModalStatus(false);
    }
  }

  return (
    <Fragment>
      <DetailsHeader icon="description" title="Información Del Conduce">
        <Circle
          disabled={
            props.loading.app ||
            props.loading.product ||
            data.status_code === "CD003" ||
            !check_permission("PE006", "canEdit")
          }
          title="Editar"
          icon="edit"
          action={edit}
        />

        <ReactToPrint
          trigger={() => (
            <Fab
              disabled={props.loading.app || props.loading.product || !canPrint}
              style={{ marginLeft: 8, marginRight: 8 }}
              size="small"
              color="primary"
            >
              <Tooltip title="Imprimir Conduce" placement="bottom">
                <Icon>print</Icon>
              </Tooltip>
            </Fab>
          )}
          content={() => componentRef.current}
        />

        <Circle
          disabled={
            props.loading.app ||
            props.loading.product ||
            data.status_code === "CD003" ||
            data.status_code === "CD002" ||
            !check_permission("PE006", "canChangeStatus")
          }
          title="Cambiar Estado"
          icon="track_changes"
          action={() => setModalStatus(true)}
        />

        <Circle
          disabled={
            props.loading.app ||
            props.loading.product ||
            data.status_code === "CD003" ||
            data.status_code === "CD002" ||
            !check_permission("PE004", "canAdd")
          }
          title="Convertir a Factura"
          icon="transform"
          action={() =>
            history.push({
              pathname: "/sales/add",
              state: {
                edit: false,
                conduce: true,
                data: data,
                products: products,
              },
            })
          }
        />
      </DetailsHeader>

      {modalStatus && (
        <ModalStatus
          statusList={statusList}
          conduceData={data}
          close={(item) => changeData(item)}
          products={products}
        />
      )}

      <div style={{ display: "none" }}>
        <ComponentToPrint
          abonos={[]}
          conduce
          products={products}
          data={data}
          ref={componentRef}
        />
      </div>
    </Fragment>
  );
}

export default withRouter(withTheme(Header));
