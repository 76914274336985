import { API } from "../actions/conduces-actions";

function conducesReducer(state = [], { type, payload }) {
  switch (type) {
    case API:
      return payload.conduces;
    default:
      return state;
  }
}

export default conducesReducer;
