import { UPDATE_USER_LIST } from "../actions/user-actions";

export default function userReducer(state = [], { type, payload }) {
  switch (type) {
    case UPDATE_USER_LIST:
      return payload;
    default:
      return state;
  }
}
