import React from "react";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";

import { Container } from "../assets/utils/styles/global";

const ContainerBody = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Body = styled(Container)`
  width: 400px;
  margin: 20px;
  padding: 30px;
`;

function ModalComponent({ open, close, children }) {
  return (
    <ContainerBody open={open} onClose={() => close()}>
      <Body>{children}</Body>
    </ContainerBody>
  );
}

export default ModalComponent;
