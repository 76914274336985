/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useContext } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import styled, { withTheme } from "styled-components";

import ContextAuth from "../../lib/ContextUser";

import { Title } from "../../components/Text";
import { SimpleAlert, confirmAlert } from "../../components/Alert";
import { Field } from "../../components/Input";
import Calendar from "../../components/Calendar";
import SaveArea from "../../components/SaveArea";
import DetailsHeader from "../../components/DetailsHeader";

import Clients from "../../components/Selects/Clients";
import Payment from "../../components/Sales/Add/Payment";
import Voucher from "../../components/Sales/Add/Voucher";
import AdicionalNote from "../../components/Sales/Add/AdicionalNote";
import Products from "../../components/Sales/Add/Products";

import Coins from "../../components/Selects/Coins";

import { Container as BodyContainer } from "../../assets/utils/styles/global";

import { create, update, sold_products } from "../../http/sales";

var moment = require("moment");

function AddSales({ history, location }) {
  const { user: currentUser } = useContext(ContextAuth);

  const [loading, setloading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    created_at: new Date(),
    date_limit: null,
    payment_type: null,
    client: {
      value: 0,
      username: "",
      rnc: "",
      discount: "",
      apply_tax: null,
      credit_limit: "",
      deadline: "",
    },
    has_voucher: false,
    voucher_type_id: null,
    voucher_id: null,
    voucher_number: "",
    comments: "",
    products: [],
    prices: {
      subtotal: 0,
      discount: 0,
      itebis: 0,
      total: 0,
    },
    conduce: false,
    status_code: "SL001",
    coin: "DOP",
  });

  const edit = location.state.edit;
  const data = location.state.data;
  const conduce = location.state.conduce;
  const products = location.state.products;

  function cancel() {
    confirmAlert(
      "warning",
      "¿Está seguro de que quiere cancelar el proceso?"
    ).then((data) => data.value && history.goBack());
  }

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      const information = {
        id: data.id,
        date_limit: addDayToDate(data.date_limit),
        payment_type: data.payment_code,
        client: { value: data.client_id, rnc: "", discount: "", username: "" },
        has_voucher: data.voucher_id !== null,
        voucher_type_id: data.voucher_type_id,
        voucher_id: data.voucher_id,
        voucher_number: data.voucher_number,
        comments: data.comments,
        coin: data.coin,
        products: [],
        prices: {},
        conduce: false,
        status_code: data.status_code,
        created_at: addDayToDate(data.created_at),
      };

      const response = await sold_products(data.id);
      setFormValues({ ...information, products: response.data });
      setIsLoading(false);
    }

    setIsLoading(false);
    edit && fetch();
  }, [data, edit]);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      const information = {
        ...formValues,
        conduce_id: data.id,
        client: { value: data.client_id, rnc: "", discount: "", username: "" },
        comments: data.comments,
        products: products,
        conduce: true,
      };

      setFormValues(information);
      setIsLoading(false);
    }

    setIsLoading(false);
    conduce && fetch();
  }, [data, conduce, products]);

  async function save(values) {
    const userStorage = localStorage.getItem("user");
    const user = JSON.parse(userStorage);

    values.created_at = moment(values.created_at).format("YYYY-MM-DD");
    values.date_limit = moment(values.date_limit).format("YYYY-MM-DD");
    values.user = user.name;
    values.admin = currentUser.id;

    setloading(true);
    const { data } = await create(values);
    const success = data.success ? "success" : "error";

    SimpleAlert(success, data.data);
    setloading(false);
    success && history.goBack();
  }

  async function editFunc(values) {
    const userStorage = localStorage.getItem("user");
    const user = JSON.parse(userStorage);

    values.created_at = moment(values.created_at).format("YYYY-MM-DD");
    values.date_limit = moment(values.date_limit).format("YYYY-MM-DD");
    values.user = user.name;
    values.admin = currentUser.id;

    setloading(true);

    const { data } = await update(values);
    const success = data.success ? "success" : "error";

    SimpleAlert(success, data.data);
    setloading(false);
    success && history.goBack();
  }

  function addDays(days) {
    var result = new Date(new Date());
    result.setDate(result.getDate() + Number(days));
    return result;
  }

  function addDayToDate(date) {
    var result = new Date(date);
    result.setDate(result.getDate() + Number(1));
    return result;
  }

  return (
    <Fragment>
      <DetailsHeader
        icon={edit ? "edit" : "add"}
        title={edit ? "Editar Factura" : "Añadir nueva factura"}
      />

      {!isLoading && (
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(value) => (edit ? editFunc(value) : save(value))}
        >
          {({ handleChange, handleSubmit, values, setFieldValue }) => (
            <div>
              <Container>
                <InputContainer>
                  <Title color="#545358" style={{ textAlign: "center" }}>
                    Información del cliente
                  </Title>

                  <Clients
                    edit={edit}
                    conduce={conduce}
                    showInformation={true}
                    values={values.client}
                    onChangeEdit={(selected) => {
                      setFieldValue("client", selected);
                      conduce &&
                        setFieldValue("date_limit", addDays(selected.deadline));
                    }}
                    onChange={(selected) => {
                      setFieldValue("client", selected);
                      setFieldValue("date_limit", addDays(selected.deadline));

                      !edit && setFieldValue("voucher_type_id", selected.id);
                      !edit && setFieldValue("has_voucher", true);
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <Title color="#545358" style={{ textAlign: "center" }}>
                    Información de la factura
                  </Title>

                  <div style={{ marginTop: 8 }}>
                    <Calendar
                      setDateState={(date) => setFieldValue("created_at", date)}
                      text="Fecha de creación"
                      value={values.created_at}
                    />
                  </div>

                  <Payment
                    value={values.payment_type}
                    onChange={(item) =>
                      setFieldValue("payment_type", item.value)
                    }
                  />

                  {values.payment_type === "PM005" && (
                    <>
                      <Field
                        label="Plazo permitido"
                        margin="dense"
                        variant="outlined"
                        value={
                          values.client.deadline !== ""
                            ? `${values.client.deadline} días`
                            : ""
                        }
                        style={{ marginTop: 16 }}
                        disabled
                      />

                      <div style={{ marginTop: 8 }}>
                        <Calendar
                          setDateState={(date) =>
                            setFieldValue("date_limit", date)
                          }
                          text="Fecha de vencimiento"
                          value={values.date_limit}
                        />
                      </div>
                    </>
                  )}

                  <Field
                    label="Vendedor"
                    margin="dense"
                    variant="outlined"
                    value={values.client.username}
                    style={{ marginTop: 16 }}
                    disabled
                  />

                  <Coins
                    value={values.coin}
                    style={{ marginTop: 16 }}
                    onChange={(item) => setFieldValue("coin", item.value)}
                  />
                </InputContainer>
              </Container>

              <Container>
                <InputContainer>
                  <Title color="#545358" style={{ textAlign: "center" }}>
                    Comprobante
                  </Title>

                  <Voucher
                    edit={edit}
                    values={values}
                    setRadio={(item) => setFieldValue("has_voucher", item)}
                    setVoucherId={(item) => setFieldValue("voucher_id", item)}
                    setVoucher={(item) =>
                      setFieldValue("voucher_type_id", item.value)
                    }
                    setVoucherNumber={(item) =>
                      setFieldValue("voucher_number", item)
                    }
                  />
                </InputContainer>

                <InputContainer>
                  <Title color="#545358" style={{ textAlign: "center" }}>
                    Notas Adicionales
                  </Title>

                  <AdicionalNote
                    value={values.comments}
                    onChange={handleChange("comments")}
                  />
                </InputContainer>
              </Container>

              <Container style={{ marginTop: 20 }}>
                <Products
                  edit={edit}
                  conduce={conduce}
                  products={values.products}
                  prices={values.prices}
                  client={values.client}
                  setProducts={(item) => setFieldValue("products", item)}
                  setPrices={(item) => setFieldValue("prices", item)}
                />
              </Container>

              <SaveArea
                isDisabled={values.voucher_number === "" && values.has_voucher}
                handleSubmit={handleSubmit}
                loading={loading}
                success={true}
                cancel={() => cancel()}
              />
            </div>
          )}
        </Formik>
      )}
    </Fragment>
  );
}

export default withTheme(withRouter(AddSales));

const InputContainer = styled(BodyContainer)`
  padding: 30px;
  padding-top: 10px;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
