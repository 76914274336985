import http from "./http";

export function create(data) {
  return http.post("clients/create", data);
}

export function update(data) {
  return http.put("clients/edit", data);
}

export function clientList() {
  return http.get("clients/clientsList");
}

export function clientInfo(id) {
  return http.get("clients/clientInfo", { params: { id: id } });
}

export function clientListSelect() {
  return http.get("clients/clientListSelect");
}

export function export_excel(data) {
  return http.post("clients/client_excel", data);
}

//////////////

export function grid(page, data) {
  return http.post(`clients/grid?page=${page}`, data);
}

export function clientsToFollow(page, filter) {
  return http.post(`clients/get_follow_sales?page=${page}`, filter);
}

export function getSalesFollow(id) {
  return http.get(`clients/get_invoices_follow`, { params: { id, days: 20 } });
}

export function change_follow_status(data) {
  return http.put("clients/change_follow_status", data);
}
