/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Tooltip, Icon } from "@material-ui/core";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Header from "../../parts/follow/header";
import Pagination from "../../components/Table/Pagination";
import { Label } from "../../components/Text";
import Divider from "../../components/Divider";

import { Container } from "../../assets/utils/styles/global";

import { clientsToFollow } from "../../http/clients";

function FollowingGrid({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState({ data: [] });
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      try {
        const { data } = await clientsToFollow(1);
        setClients(data);
      } catch {
      } finally {
        setIsLoading(false);
      }
    }

    fetch();
  }, []);

  async function paginate(page, filter) {
    setIsLoading(true);

    const { data } = await clientsToFollow(page, filter);
    setClients(data);

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Clientes que compraron hace mas de 20 dias
      </Label>

      <Container style={{ padding: "12px 0 0 0" }}>
        <Header
          handleResults={(e) => paginate(1, { name: e.target.value })}
          openFilter={() => setOpen(!isOpen)}
          isOpen={isOpen}
          handleAdd={() =>
            history.push({ pathname: "add", state: { edit: false } })
          }
        />

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <TableContainer>
          <Table>
            <tbody>
              <Tr>
                <Th>Nombre</Th>
                <Th>Teléfono</Th>
                <Th>Correo Electronico</Th>
                <Th>Celular</Th>
                <Th>Acción</Th>
              </Tr>

              {clients.data.map((data) => (
                <Tr key={data.id}>
                  <Td>{data.name}</Td>
                  <Td>{data.phone}</Td>
                  <Td>{data.email}</Td>
                  <Td>{data.mobile}</Td>

                  <Td style={{ cursor: "pointer" }}>
                    <Tooltip placement="right" title="Detalle">
                      <Icon
                        color="primary"
                        onClick={() =>
                          history.push({
                            pathname: "details",
                            state: { data },
                          })
                        }
                      >
                        description
                      </Icon>
                    </Tooltip>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>

        <Pagination
          next={() => paginate(clients.current_page + 1)}
          previous={() => paginate(clients.current_page - 1)}
          currentPage={clients.current_page}
          last_page={clients.last_page}
          pageOne={() => paginate(1, clients)}
        />
      </Container>
    </Fragment>
  );
}

export default withTheme(withRouter(FollowingGrid));
