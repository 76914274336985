import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { Icon } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Button from "../../components/Buttons/Button";
import { Label } from "../../components/Text";
import { Field } from "../../components/Input";

function Header({ history, handleAddClient, handleClient, ...props }) {
  const matches = useMediaQuery("(max-width:768px)");

  return (
    <Container>
      <SelectContainer>
        <Field
          onChange={handleClient}
          label="Nombre"
          margin="dense"
          variant="outlined"
          style={{ width: matches ? "100%" : 240 }}
        />

        <div
          onClick={props.openFilter}
          style={{
            cursor: "pointer",
            display: "flex",
            marginTop: matches && "20px",
          }}
        >
          <Icon style={{ fontSize: 18, marginLeft: 16 }} color="primary">
            filter_list
          </Icon>

          <FilterText>
            {props.isOpen ? "Ocultar Filtros" : "Mas Filtros"}
          </FilterText>
        </div>
      </SelectContainer>

      <div className="button-container">
        <Button
          text="Añadir Cliente"
          action={handleAddClient}
          width="100%"
          color="primary"
          icon="add"
          side="left"
        />
      </div>
    </Container>
  );
}

export default withRouter(Header);

const FilterText = styled(Label)`
  font-size: 14px;
  margin-left: 4px;
  color: ${(props) => props.theme.colors.PRIMARY};
  font-weight: 600;
`;

const Container = styled.div`
  padding: 0 20px 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 40px);
  }

  .button-container {
    display: flex;
    align-items: center;
    width: 240px;

    @media (max-width: 768px) {
      margin-top: 20px;
      width: 100%;
    }
  }
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
