import { createGlobalStyle } from "styled-components";

import RobotoBold from "./Roboto/Roboto-Bold.ttf";
import RobotoMedium from "./Roboto/Roboto-Medium.ttf";
import RobotoRegular from "./Roboto/Roboto-Regular.ttf";

const GlobalStyle = createGlobalStyle`
   @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBold}) format('ttf');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoMedium}) format('ttf');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoRegular}) format('ttf');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
  html {
    color: #354147;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: Roboto,  sans-serif;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
  } 

`;

export default GlobalStyle;
