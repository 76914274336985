/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useContext } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import NumberFormat from "react-number-format";

import ContextAuth from "../../lib/ContextUser";

import { Label } from "../../components/Text";
import { SimpleAlert, confirmAlert } from "../../components/Alert";
import { Field } from "../../components/Input";
import DetailsHeader from "../../components/DetailsHeader";
import Radio from "../../components/RadioButton";
import Calendar from "../../components/Calendar";
import SaveArea from "../../components/SaveArea";

import { Container as BodyContainer } from "../../assets/utils/styles/global";

import { create, update } from "../../http/products";

import theme from "../../theme";

var numeral = require("numeral");
var moment = require("moment");

function AddProduct({ history, location }) {
  const { user } = useContext(ContextAuth);

  const [loading, setloading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    expiration_date: "",
    cost: "",
    sale_price: "",
    maker_name: "",
    quantity_in_stock: "",
    bill_no_product: "",
    apply_tax: false,
  });

  const [date, setDate] = useState(new Date());
  const [selected, setSelected] = useState(false);

  const edit = location.state.edit;
  const productData = location.state.productData;

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    edit && editFetch();
  }

  async function editFetch() {
    setFormValues(productData);
    setSelected(!!productData.bill_no_product);
    setDate(productData.expiration_date);
  }

  async function submit(value) {
    setloading(true);
    var cost = numeral(value.cost);
    var sale = numeral(value.sale_price);

    var cost_value = cost.value();
    var sale_value = sale.value();

    if (cost_value > sale_value) {
      SimpleAlert(
        "warning",
        "El costo del producto es mayor que el precio de venta"
      );
    } else {
      value.expiration_date = moment(date).format("YYYY-MM-DD");
      value.bill_no_product = selected;
      value.quantity_in_stock = numeral(value.quantity_in_stock).value();
      value.cost = numeral(value.cost).format("0.00");
      value.sale_price = numeral(value.sale_price).format("0.00");
      value.admin = user.id;

      try {
        const { data } = edit ? await update(value) : await create(value);
        const type = data.success ? "success" : "error";
        if (data.success) {
          if (edit) {
            history.push({
              pathname: "details",
              state: { data: data.information[0] },
            });
          } else {
            history.goBack();
          }
        }
        SimpleAlert(type, data.data);
      } catch (error) {
        SimpleAlert("error", "Algo ha pasado por favor intentelo de nuevo");
      }
    }

    setloading(false);
  }

  function cancel() {
    confirmAlert(
      "warning",
      "¿Está seguro de que quiere cancelar el proceso?"
    ).then((data) => data.value && history.goBack());
  }

  return (
    <Fragment>
      <DetailsHeader
        icon={edit ? "edit" : "add"}
        title={edit ? "Editar producto" : "Añadir Nuevo Producto"}
      />

      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={(value) => submit(value)}
      >
        {({ handleChange, handleSubmit, values, setFieldValue }) => (
          <div>
            <Container>
              <InputContainer>
                <Field
                  onChange={handleChange("name")}
                  label="Nombre del producto"
                  margin="dense"
                  variant="outlined"
                  value={values.name}
                />

                <Field
                  onChange={handleChange("description")}
                  label="Descripción del producto"
                  margin="dense"
                  variant="outlined"
                  value={values.description}
                  style={{ margin: "15px 0 15px 0" }}
                  multiline
                />

                <Calendar
                  setDateState={(date) => setDate(date)}
                  text="Fecha de vencimiento del producto"
                  value={date}
                />

                <NumberFormat
                  margin="dense"
                  variant="outlined"
                  label="Costo del producto"
                  thousandSeparator={true}
                  customInput={Field}
                  decimalSeparator="."
                  onChange={handleChange("cost")}
                  value={values.cost}
                  allowNegative={false}
                />

                <ItemsContainer>
                  <RadioContainer>
                    <Label weight={500}>Facturar sin existencia:</Label>

                    <Radio
                      action={() => setSelected(true)}
                      selected={selected}
                      label="Si"
                      margin="0 0 0 3px"
                    />

                    <Radio
                      action={() => setSelected(false)}
                      selected={!selected}
                      label="No"
                      margin="0 5px 0 0"
                    />
                  </RadioContainer>
                </ItemsContainer>
              </InputContainer>

              <InputContainer>
                <NumberFormat
                  margin="dense"
                  variant="outlined"
                  label="Precio de venta"
                  thousandSeparator={true}
                  customInput={Field}
                  decimalSeparator="."
                  onChange={handleChange("sale_price")}
                  value={values.sale_price}
                  allowNegative={false}
                />

                <Field
                  onChange={handleChange("maker_name")}
                  label="Provedor"
                  margin="dense"
                  variant="outlined"
                  value={values.maker_name}
                  style={{ marginTop: 15 }}
                />

                <NumberFormat
                  margin="dense"
                  variant="outlined"
                  label="Cantidad en almacen"
                  thousandSeparator={true}
                  customInput={Field}
                  decimalSeparator="."
                  onChange={handleChange("quantity_in_stock")}
                  value={values.quantity_in_stock}
                  allowNegative={false}
                  style={{ marginTop: 15 }}
                />

                <ItemsContainer>
                  <RadioContainer>
                    <Label weight={500}>Aplicar impuesto:</Label>

                    <Radio
                      action={() => setFieldValue("apply_tax", true)}
                      selected={values.apply_tax}
                      label="Si"
                      margin="0 0 0 3px"
                    />

                    <Radio
                      action={() => setFieldValue("apply_tax", false)}
                      selected={!values.apply_tax}
                      label="No"
                      margin="0 5px 0 0"
                    />
                  </RadioContainer>
                </ItemsContainer>
              </InputContainer>
            </Container>

            <SaveArea
              handleSubmit={handleSubmit}
              loading={loading}
              success={true}
              cancel={() => cancel()}
            />
          </div>
        )}
      </Formik>
    </Fragment>
  );
}

export default withTheme(withRouter(AddProduct));

const InputContainer = styled(BodyContainer)`
  padding: 30px;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ItemsContainer = styled.div`
  border: 1.5px solid #eaeaea;
  height: 46px;
  width: 100%;
  outline: none;
  border-radius: ${theme.body.RADIUS};
  padding: 0 12px 0 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 15px;
  background-color: white;
`;
