import React, { useState, useEffect, Fragment, useContext } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import moment from "moment";

import ContextAuth from "../../lib/ContextUser";

import { Title } from "../../components/Text";
import { SimpleAlert, confirmAlert } from "../../components/Alert";
import { Field } from "../../components/Input";
import Calendar from "../../components/Calendar";
import SaveArea from "../../components/SaveArea";
import DetailsHeader from "../../components/DetailsHeader";

import Clients from "../../components/Selects/Clients";
import Payment from "../../components/Sales/Add/Payment";
import AdicionalNote from "../../components/Sales/Add/AdicionalNote";
import Products from "../../components/Sales/Add/Products";

import { Container as BodyContainer } from "../../assets/utils/styles/global";

import { create, get_products, update } from "../../http/quote";

const initialValues = {
  client: {
    value: 0,
    username: "",
    rnc: "",
    discount: "",
    apply_tax: null,
    credit_limit: "",
    deadline: "",
  },
  payment_type: null,
  products: [],
  prices: {
    subtotal: 0,
    discount: 0,
    itebis: 0,
    total: 0,
  },
  created_at: new Date(),
  comments: "",
  user_id: "",
};

function AddQuote({ history, location }) {
  const { user } = useContext(ContextAuth);

  const [loading, setloading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const edit = location.state.edit;
  const data = location.state.data;

  function cancel() {
    confirmAlert(
      "warning",
      "¿Está seguro de que quiere cancelar el proceso?"
    ).then((data) => data.value && history.goBack());
  }

  useEffect(() => {
    async function fetch() {
      const information = {
        client: { value: data.client_id, rnc: "", username: "" },
        payment_type: data.payment_code,
        created_at: addDayToDate(data.created_at),
        prices: {
          subtotal: data.subtotal,
          discount: data.discount,
          itebis: data.itebis,
          total: data.total,
        },
      };

      const response = await get_products(data.id);
      setFormValues({ ...data, ...information, products: response.data });
    }

    edit && fetch();
  }, [data, edit]);

  async function save(values) {
    try {
      setloading(true);

      values.created_at = moment(values.created_at).format("YYYY-MM-DD");
      values.user_id = user.id;

      const { data } = await create(values);
      const success = data.success ? "success" : "error";
      SimpleAlert(success, data.data);

      success && history.goBack();
    } catch (error) {
      return error;
    } finally {
      setloading(false);
    }
  }

  async function editFunc(values) {
    values.created_at = moment(values.created_at).format("YYYY-MM-DD");
    values.user_id = user.id;

    setloading(true);
    const { data } = await update(values);
    const success = data.success ? "success" : "error";
    SimpleAlert(success, data.data);
    setloading(false);
    success && history.goBack();
  }

  function addDayToDate(date) {
    var result = new Date(date);
    result.setDate(result.getDate() + Number(1));
    return result;
  }

  return (
    <Fragment>
      <DetailsHeader
        icon={edit ? "edit" : "add"}
        title={edit ? "Editar Cotización" : "Añadir nueva cotización"}
      />

      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={(value) => (edit ? editFunc(value) : save(value))}
      >
        {({ handleChange, handleSubmit, values, setFieldValue }) => (
          <div>
            <Container>
              <InputContainer>
                <Title color="#545358" style={{ textAlign: "center" }}>
                  Información del cliente
                </Title>

                <Clients
                  edit={edit}
                  showInformation={true}
                  values={values.client}
                  onChangeEdit={(selected) => setFieldValue("client", selected)}
                  onChange={(selected) => setFieldValue("client", selected)}
                />
              </InputContainer>

              <InputContainer>
                <Title color="#545358" style={{ textAlign: "center" }}>
                  Información de la cotización
                </Title>

                <div style={{ marginTop: 8 }}>
                  <Calendar
                    setDateState={(date) => setFieldValue("created_at", date)}
                    text="Fecha de creación"
                    value={values.created_at}
                  />
                </div>

                <Payment
                  value={values.payment_type}
                  onChange={(item) => setFieldValue("payment_type", item.value)}
                />

                <Field
                  label="Vendedor"
                  margin="dense"
                  variant="outlined"
                  value={values.client.username}
                  style={{ marginTop: 16 }}
                  disabled
                />
              </InputContainer>
            </Container>

            <Container>
              <InputContainer>
                <Title color="#545358" style={{ textAlign: "center" }}>
                  Notas Adicionales
                </Title>

                <AdicionalNote
                  value={values.comments}
                  onChange={handleChange("comments")}
                />
              </InputContainer>
            </Container>

            <Container style={{ marginTop: 20 }}>
              <Products
                edit={edit}
                products={values.products}
                prices={values.prices}
                client={values.client}
                setProducts={(item) => setFieldValue("products", item)}
                setPrices={(item) => setFieldValue("prices", item)}
              />
            </Container>

            <SaveArea
              handleSubmit={handleSubmit}
              loading={loading}
              success={true}
              cancel={() => cancel()}
            />
          </div>
        )}
      </Formik>
    </Fragment>
  );
}

export default withTheme(withRouter(AddQuote));

const InputContainer = styled(BodyContainer)`
  padding: 30px;
  padding-top: 10px;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
