import React from "react";
import styled, { withTheme } from "styled-components";
import numeral from "numeral";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Container as BodyContainer } from "../../assets/utils/styles/global";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Button from "../../components/Buttons/Button";

function Products({ products, handleProducts }) {
  const matches = useMediaQuery("(max-width:768px)");

  return (
    <BodyContainer>
      <Header>
        <Title>Lista de productos agregados</Title>

        <Button
          text="Agregar productos"
          action={handleProducts}
          width={matches ? "100%" : "250px"}
          color="primary"
          icon="add"
          side="left"
          style={{ boxShadow: "none", color: "white", height: 40 }}
        />
      </Header>

      <Divider />

      {products.length !== 0 && (
        <TableContainer style={{ marginTop: 16 }}>
          <Table>
            <tbody>
              <Tr>
                <Th>Código</Th>
                <Th>Nombre</Th>
                <Th>Cantidad a vender</Th>
              </Tr>

              {products.map((data, index) => (
                <Tr key={index}>
                  <Td>{data.code}</Td>
                  <Td>{data.name}</Td>
                  <Td>{numeral(data.quantity).format(0, 0)}</Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
    </BodyContainer>
  );
}

export default withTheme(Products);

const Title = styled.div`
  color: #545358;
  font-size: 20px;
  font-weight: 600;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  height: 6px;
  background-color: #f8f8f8;
  margin: 8px -30px 0 -30px;
`;
