import React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import moment from "moment";

import Outline from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Button from "../../components/Buttons/Button";
import Divider from "../../components/Divider";
import Calendar from "../../components/Calendar";

function Filters({ handleSearch, filters, initFilters }) {
  const matches = useMediaQuery("(max-width:768px)");

  function addDayToDate(date) {
    var result = new Date(date);
    result.setDate(result.getDate() + Number(1));
    return result;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={filters}
      onSubmit={(_, { resetForm }) => {
        resetForm();
        handleSearch(initFilters);
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <>
          <Container>
            <Calendar
              setDateState={(date) =>
                setFieldValue(
                  "date",
                  date ? moment(date).format("YYYY-MM-DD") : null
                )
              }
              text="Fecha"
              value={values.date ? addDayToDate(values.date) : null}
              style={{ width: "250px" }}
            />
          </Container>

          <Divider />

          <ButtonContainer>
            <div style={{ margin: matches ? "0 0 20px 0 " : "0 8px 0 20px" }}>
              <Button
                text="Buscar"
                action={() => handleSearch(values)}
                width={matches ? "100%" : "200px"}
                color="primary"
                icon="search"
                side="right"
              />
            </div>

            <Outline
              onClick={handleSubmit}
              style={{
                width: matches ? "100%" : 200,
              }}
              variant="outlined"
              color="primary"
            >
              Limpiar Filtros
            </Outline>
          </ButtonContainer>
        </>
      )}
    </Formik>
  );
}

export default Filters;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 12px 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 12px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;
