import React, { Fragment } from "react";
import styled from "styled-components";

import { Tr, Table } from "../Table";

var numeral = require("numeral");

const Td = styled.td`
  padding: 4px;
`;

const Th = styled(Td)`
  background-color: white;
  font-weight: 600;
`;

const Label = styled.div`
  color: black;
  font-size: 14px;
`;

function Products({ data }) {
  return (
    <Fragment>
      <Table style={{ marginTop: 20 }}>
        <tbody>
          <Tr>
            <Th>Código</Th>
            <Th>Cantidad</Th>
            <Th>Item</Th>
            <Th>Precio</Th>
            <Th>Total</Th>
          </Tr>

          {data.map((data, index) => (
            <>
              <Tr key={index}>
                <Td>
                  <Label>{data.code}</Label>
                </Td>

                <Td>
                  <Label>{numeral(data.quantity).format(0, 0)}</Label>
                </Td>

                <Td>
                  <Label>{data.name}</Label>
                </Td>

                <Td>
                  <Label>${numeral(data.custom_price).format("0,0.00")}</Label>
                </Td>

                <Td>
                  <Label>
                    $
                    {numeral(data.quantity * data.custom_price).format(
                      "0,0.00"
                    )}
                  </Label>
                </Td>
              </Tr>
            </>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
}

export default Products;
