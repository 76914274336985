const theme = {
  colors: {
    PRIMARY: "#2fa008",
    DARKER_PRIMARY: "#12a13d",
    SECUNDARY: "#60a1cd",
    BODY: "#f9f9f9",
    SIDE_MENU: "#2d303e"
  },
  body: {
    RADIUS: "4px"
  }
};

export default theme;
