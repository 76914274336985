/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import { connect } from "react-redux";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Filters from "../../components/CreditNote/Filters";
import Divider from "../../components/Divider";
import { Label } from "../../components/Text";
import Header from "../../components/Table/Header";
import Pagination from "../../components/Table/Pagination";
import NotPermissions from "../../components/NotPermissions";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Tooltip, Icon } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";

import { Container } from "../../assets/utils/styles/global";

import check_permission from "../../lib/check_permission";

import { creditNoteData } from "../../actions/credit-note-actions";

import { grid } from "../../http/credit_note";
import { clientListSelect } from "../../http/clients";

var moment = require("moment");
var numeral = require("numeral");

const mapStateToProps = (state) => {
  return {
    creditNote: state.creditNote,
  };
};

const mapActionsToProps = {
  updateCreditNote: creditNoteData,
};

function CreditNoteGrid({ history, updateCreditNote, creditNote }) {
  const initialValues =
    creditNote.length === 0 ? { data: creditNote } : creditNote;
  const initFilters_value = {
    client_id: null,
    creation_date: null,
    date: null,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(initialValues);
  const [clients, setClients] = useState([]);
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [filters_value, setFilters_value] = useState(initFilters_value);

  useEffect(() => {
    async function getSalesListFunc() {
      setIsLoading(true);
      const page = creditNote.length === 0 ? 1 : creditNote.current_page;

      const { data } = await grid(page);
      updateCreditNote(data);
      setFilters(data);

      setIsLoading(false);
    }

    getSalesListFunc();
  }, []);

  useEffect(() => {
    async function fetch() {
      setIsLoadingClient(true);
      const { data } = await clientListSelect();
      setClients(data);
      setIsLoadingClient(false);
    }

    fetch();
  }, []);

  async function paginate(page, items) {
    setIsLoading(true);
    setFilters_value(items);

    const { data } = await grid(page, items);
    setFilters(data);

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Notas de Creditos
      </Label>

      <Container style={{ padding: "12px 0 0 0" }}>
        <Header
          items={clients}
          loading={isLoadingClient}
          setFilter={(item) => paginate(1, { client_id: item.value })}
          openFilter={() => setOpen(!isOpen)}
          isOpen={isOpen}
          hasMultipleFilters
        />

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <Collapse in={isOpen}>
          <Filters
            setFilters={setFilters}
            clients={clients}
            search_filter={(items) => paginate(1, items)}
            filters={filters_value}
            initFilters_value={initFilters_value}
          />
          <Divider />
        </Collapse>

        {check_permission("PE005", "canSee") ? (
          <TableContainer>
            <Table>
              <tbody>
                <Tr>
                  <Th>Código</Th>
                  <Th>Cliente</Th>
                  <Th>Total Venta</Th>
                  <Th>Fecha Creación</Th>
                  <Th>Acción</Th>
                </Tr>

                {filters.data.map((data, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{data.number_code}</Td>
                      <Td>{data.name}</Td>
                      <Td>RD$ {numeral(data.total).format("0,0.00")}</Td>
                      <Td>{moment(data.created_at).format("DD/MM/YYYY")}</Td>

                      <Td style={{ cursor: "pointer" }}>
                        <Tooltip placement="right" title="Detalle">
                          <Icon
                            color="primary"
                            onClick={() =>
                              history.push({
                                pathname: "details",
                                state: { id: data.id },
                              })
                            }
                          >
                            description
                          </Icon>
                        </Tooltip>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        ) : (
          <NotPermissions isLoading={isLoading} />
        )}

        <Pagination
          next={() => paginate(filters.current_page + 1, filters_value)}
          previous={() => paginate(filters.current_page - 1, filters_value)}
          currentPage={filters.current_page}
          last_page={filters.last_page}
          pageOne={() => paginate(1, filters_value)}
        />
      </Container>
    </Fragment>
  );
}

export default withTheme(
  withRouter(connect(mapStateToProps, mapActionsToProps)(CreditNoteGrid))
);
