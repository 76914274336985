import React, { useState } from "react";
import styled from "styled-components";

import { Field } from "../../../Input";

function Filters({ filters, setFilters }) {
  const [searchValues, setSearchValues] = useState({ name: "", code: "" });

  function hadleFilter(e) {
    const name = e.target.name;
    const value = e.target.value;
    const currentSearchValue = { ...searchValues, [name]: value };

    setSearchValues(currentSearchValue);
    handleSearch(currentSearchValue);
  }

  function handleSearch(filtersCopy) {
    var result = filters.filter((obj) => {
      return (
        obj.name.toLowerCase().includes(filtersCopy.name.toLowerCase()) &&
        obj.code.toLowerCase().includes(filtersCopy.code.toLowerCase())
      );
    });

    setFilters(result);
  }

  return (
    <Container style={{ marginLeft: 30 }}>
      <Field
        onChange={hadleFilter}
        label="Buscar por nombre"
        margin="dense"
        variant="outlined"
        style={{ marginRight: 10, marginBottom: 8 }}
        width="200px"
        name="name"
      />

      <Field
        onChange={hadleFilter}
        label="Buscar por codigo"
        margin="dense"
        variant="outlined"
        style={{ marginRight: 10, marginBottom: 8 }}
        width="200px"
        name="code"
      />
    </Container>
  );
}

export default Filters;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 15px 0 15px 0;
`;
