import React, { Fragment, useState, useEffect } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Tooltip, Icon } from "@material-ui/core";

import { Label } from "../../components/Text";
import Circle from "../../components/Buttons/Circle";
import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import StatusLabel from "../../components/Sales/StatusLabel";
import DetailsHeader from "../../components/DetailsHeader";
import Pagination from "../../components/Table/Pagination";
import Timeline from "../../components/Timeline";

import check_permission from "../../lib/check_permission";
import { timelineCode } from "../../lib/constant";

import { Container } from "../../assets/utils/styles/global";

import { sales_per_client } from "../../http/sales";
import { getTimeline } from "../../http/timeline";

var numeral = require("numeral");
var moment = require("moment");

function ClientDetails({ location, history }) {
  const data = location.state.data;

  const canSee = check_permission("PE004", "canSee");

  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [products, setProducts] = useState({ data: [] });
  const [timeline, setTimeline] = useState({ data: [] });
  const [timelinePage, setTimelinePage] = useState(1);

  useEffect(() => {
    async function fetchData() {
      setIsLoadingProduct(true);

      try {
        const response = await sales_per_client(data.id, 1);
        setProducts(response.data);
      } catch {}
      setIsLoadingProduct(false);
    }

    fetchData();
  }, [data]);

  useEffect(() => {
    async function fetchTimeline() {
      try {
        const response = await getTimeline({
          module_id: data.id,
          module_name: timelineCode.client,
          page: timelinePage,
        });
        setTimeline(response.data);
      } catch {}
    }

    fetchTimeline();
  }, [data, timelinePage]);

  async function paginate(page) {
    const response = await sales_per_client(data.id, page);
    setProducts(response.data);
  }

  const list = (key, value) => {
    return (
      <RowContainer>
        <Label weight={600}>{key}: </Label>
        <Label style={{ marginLeft: 8 }}>{value}</Label>
      </RowContainer>
    );
  };

  return (
    <Fragment>
      <DetailsHeader icon="description" title="Información Del Cliente">
        <Circle
          disabled={!check_permission("PE002", "canEdit")}
          title="Editar"
          icon="edit"
          action={() =>
            history.push({
              pathname: "add",
              state: { clientData: data, edit: true },
            })
          }
        />
      </DetailsHeader>

      <Body>
        <Card>
          {list("Nombre", data.name)}
          {list("País", data.country)}
          {list("Ciudad", data.city)}
          {list("Celular", data.mobile)}
          {list("Telefono", data.phone)}
          {list(`${data.document_type ? "RNC" : "Cédula"}`, data.document)}
          {list("Tipo de comprobante fiscal", data.ncf_name)}
        </Card>

        <Card>
          {list("Descuento", data.discount + "%")}
          {list("Correo", data.email)}
          {list("Dirección", data.address)}
          {list("Contacto", data.contact)}
          {list("Limite de Credito", numeral(data.credit_limit).format(0, 0))}
          {list("Periodo de plazo", `${data.deadline} dias`)}
          {list("Notas", data.notes)}
        </Card>
      </Body>

      <Timeline data={timeline} paginate={(page) => setTimelinePage(page)} />

      <Container style={{ marginTop: 20, padding: "12px 0 0 0" }}>
        <Title>Facturas del cliente</Title>

        {isLoadingProduct ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        {canSee ? (
          <TableContainer style={{ marginTop: 20 }}>
            <Table>
              <tbody>
                <Tr>
                  <Th>Código</Th>
                  <Th>Total venta</Th>
                  <Th>Fecha Creación</Th>
                  <Th>Fecha Expiración</Th>
                  <Th>Estado</Th>
                  <Th>Acción</Th>
                </Tr>

                {products.data.map((data, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "blue",
                        }}
                        onClick={() =>
                          history.push({
                            pathname: "../sales/details",
                            state: { id: data.id },
                          })
                        }
                      >
                        {data.number_code}
                      </Td>

                      <Td>RD$ {data.total}</Td>
                      <Td>{moment(data.created_at).format("DD/MM/YYYY")}</Td>
                      <Td>{moment(data.date_limit).format("DD/MM/YYYY")}</Td>
                      <Td>
                        <StatusLabel data={data} />
                      </Td>

                      <Td style={{ cursor: "pointer" }}>
                        <Tooltip placement="right" title="Detalle">
                          <Icon
                            color="primary"
                            onClick={() =>
                              history.push({
                                pathname: "/sales/details",
                                state: { id: data.id },
                              })
                            }
                          >
                            description
                          </Icon>
                        </Tooltip>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        ) : (
          <div>{!isLoadingProduct && "no permisos"}</div>
        )}

        <Pagination
          next={() => paginate(products.current_page + 1)}
          previous={() => paginate(products.current_page - 1)}
          currentPage={products.current_page}
          last_page={products.last_page}
          pageOne={() => paginate(1)}
        />
      </Container>
    </Fragment>
  );
}

export default withTheme(withRouter(ClientDetails));

const RowContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #f8f8f8;

  :nth-child(even) {
    background-color: white;
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  height: 4px;
  background-color: #afdaa1;
`;

const Title = styled.div`
  color: #545358;
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0 12px 30px;
`;

const Card = styled(Container)`
  padding: 0;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
