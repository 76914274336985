import React from "react";
import styled from "styled-components";

import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import { Label } from "../Text";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
`;

function Pagination(props) {
  return (
    <Container>
      <ButtonContainer>
        <IconButton onClick={props.pageOne} aria-label="Anterior">
          <Label style={{ width: "16px" }}>1</Label>
        </IconButton>

        <Label>--</Label>

        <IconButton onClick={props.previous} aria-label="Anterior">
          <Icon>navigate_before</Icon>
        </IconButton>

        <Label>
          {props.currentPage} de {props.last_page}
        </Label>

        <IconButton
          disabled={props.currentPage === props.last_page}
          onClick={props.next}
          aria-label="Siguiente"
        >
          <Icon>navigate_next</Icon>
        </IconButton>
      </ButtonContainer>
    </Container>
  );
}

export default Pagination;
