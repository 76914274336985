/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import LinearProgress from "@material-ui/core/LinearProgress";

import { timelineCode } from "../../lib/constant";

import Loading from "../../components/Loading";
import { Container } from "../../assets/utils/styles/global";
import { Label } from "../../components/Text";
import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Timeline from "../../components/Timeline";

import Header from "../../components/Conduce/Header";

import { details, conduce_products } from "../../http/conduce";
import { getTimeline } from "../../http/timeline";

var moment = require("moment");
var numeral = require("numeral");

function ConduceDetails({ location, history }) {
  const id = location.state.id;

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [timeline, setTimeline] = useState({ data: [] });
  const [timelinePage, setTimelinePage] = useState(1);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const { data } = await details(id);
        setData(data[0]);
      } catch {}
      setIsLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setIsLoadingProduct(true);

      try {
        const { data } = await conduce_products(id);
        setProducts(data);
      } catch {}
      setIsLoadingProduct(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchTimeline() {
      try {
        const response = await getTimeline({
          module_id: id,
          module_name: timelineCode.conduce,
          page: timelinePage,
        });

        setTimeline(response.data);
      } catch {}
    }

    fetchTimeline();
  }, [id, timelinePage]);

  const list = (key, value) => {
    return (
      <RowContainer>
        <Label weight={600}>{key}: </Label>
        <Label style={{ marginLeft: 8 }}>{value}</Label>
      </RowContainer>
    );
  };

  return (
    <Fragment>
      <Header
        loading={{ app: isLoading, product: isLoadingProduct }}
        products={products}
        data={data}
        setData={setData}
        edit={() =>
          history.push({
            pathname: "add",
            state: { edit: true, data: data },
          })
        }
      />

      <Body>
        <Card>
          <Loading loading={isLoading} />

          {!isLoading && (
            <Fragment>
              {list("Código del conduce", data.number_code)}
              {list("Estado", data.description)}

              {list(
                "Fecha de creación",
                moment(data.created_at).format("DD/MM/YYYY")
              )}

              {list("Cliente", data.name)}
              {list("Descuento", data.discount_percent + "%")}
            </Fragment>
          )}
        </Card>

        <Card>
          <Loading loading={isLoading} />

          {!isLoading && (
            <Fragment>
              {list("Sub Total", numeral(data.subtotal).format("0,0.00"))}
              {list("Descuento", numeral(data.discount).format("0,0.00"))}
              {list("ITBIS", numeral(data.itebis).format("0,0.00"))}
              {list("Total del conduce", numeral(data.total).format("0,0.00"))}
              {list("Comentarios", data.comments)}
            </Fragment>
          )}
        </Card>
      </Body>

      <Container style={{ marginTop: 20, padding: "12px 0 0 0" }}>
        <Title>Productos</Title>

        {isLoadingProduct ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <TableContainer style={{ marginTop: 16 }}>
          <Table>
            <tbody>
              <Tr>
                <Th>Código</Th>
                <Th>Nombre</Th>
                <Th>Costo del producto</Th>
                <Th>Costo deseado</Th>
                <Th>Cantidad a vender</Th>
              </Tr>

              {products.map((data, index) => (
                <Tr key={index}>
                  <Td>{data.code}</Td>
                  <Td>{data.name}</Td>
                  <Td>{numeral(data.sale_price).format("0,0.00")}</Td>
                  <Td>{numeral(data.custom_price).format("0,0.00")}</Td>
                  <Td>{numeral(data.quantity).format(0, 0)}</Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Container>

      <Timeline data={timeline} paginate={(page) => setTimelinePage(page)} />
    </Fragment>
  );
}

export default withTheme(withRouter(ConduceDetails));

const Title = styled.div`
  color: #545358;
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0 12px 30px;
`;

const RowContainer = styled.div`
  display: flex;
  background-color: #f8f8f8;
  align-items: center;
  padding: 20px;

  :nth-child(even) {
    background-color: white;
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled(Container)`
  padding: 0;
  width: 49%;
  margin-top: 20px;
  min-height: 300px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Divider = styled.div`
  height: 4px;
  background-color: #afdaa1;
`;
