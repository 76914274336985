import http from "./http";

export function create(data) {
  return http.post("credit_note/create", data);
}

export function grid(page, data) {
  return http.post(`credit_note/grid?page=${page}`, data);
}

export function details(id) {
  return http.get(`credit_note/details?id=${id}`);
}

export function sold_products(id) {
  return http.get("credit_note/sold_products", { params: { id: id } });
}
