import React, { useState } from "react";
import { Formik } from "formik";

import { Label } from "../Text";
import Button from "../Buttons/Button";
import Modal from "../Modal";
import { Field } from "../Input";

import { updateQuantity } from "../../http/products";
import { SimpleAlert } from "../Alert";

var numeral = require("numeral");
var moment = require("moment");

function AddQuantity({ close, closeAndReload, id }) {
  const [loading, setloading] = useState(false);

  async function submit(value) {
    setloading(true);

    value.quantity = numeral(value.quantity).value();
    value.id = id;
    value.user_id = localStorage.getItem("id");
    value.date = moment(new Date()).format("MM/DD/YYYY");

    try {
      const { data } = await updateQuantity(value);
      const type = data.success ? "success" : "error";
      data.success && closeAndReload();

      SimpleAlert(type, data.data);
    } catch (error) {
      SimpleAlert("error", "Algo ha pasado por favor intentelo de nuevo");
    }
    setloading(false);
  }

  return (
    <Modal close={() => close()} open={true}>
      <Formik
        initialValues={{ quantity: "" }}
        onSubmit={(value) => submit(value)}
      >
        {({ handleChange, handleSubmit, values }) => (
          <div style={{ marginTop: 10 }}>
            <Label style={{ marginBottom: 10 }} weight="550">
              Añadir cantidad al inventario
            </Label>

            <Field
              onChange={handleChange("quantity")}
              label="Cantidad"
              margin="dense"
              variant="outlined"
              style={{ marginTop: 8 }}
              value={
                Boolean(values.quantity)
                  ? numeral(values.quantity).format(0, 0)
                  : ""
              }
            />

            <Button
              disabled={values.quantity === "" || loading}
              top="8px"
              text="Añadir Cantidad"
              action={handleSubmit}
              loading={loading}
              color="primary"
              icon="save"
              side="left"
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default AddQuantity;
