import React from "react";
import styled, { withTheme } from "styled-components";
import moment from "moment";

import { Tr, Table } from "../Table";

var numeral = require("numeral");

const Td = styled.td`
  padding: 4px;
`;

const Th = styled(Td)`
  background-color: white;
  font-weight: 600;
`;

const Label = styled.div`
  color: black;
  font-size: 14px;
`;

function Abono({ abonos }) {
  return (
    <>
      <Label style={{ marginTop: 12, fontWeight: 500 }}>
        Abonos a esta factura
      </Label>

      <Table style={{ marginTop: 8 }}>
        <tbody>
          <Tr>
            <Th>Monto</Th>
            <Th>Fecha</Th>
          </Tr>

          {abonos.map((data) => (
            <>
              <Tr key={data.id}>
                <Td>
                  <Label>{numeral(data.amount).format(0, 0)}</Label>
                </Td>

                <Td>
                  <Label>{moment(data.date).format("DD/MM/YYYY")}</Label>
                </Td>
              </Tr>
            </>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default withTheme(Abono);
