import React, { useState, useEffect, Fragment, useContext } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import ContextAuth from "../../lib/ContextUser";

import { Title } from "../../components/Text";
import { SimpleAlert, confirmAlert } from "../../components/Alert";
import SaveArea from "../../components/SaveArea";
import DetailsHeader from "../../components/DetailsHeader";

import Clients from "../../components/Selects/Clients";

import AdicionalNote from "../../components/Sales/Add/AdicionalNote";
import Products from "../../components/Sales/Add/Products";

import { Container as BodyContainer } from "../../assets/utils/styles/global";

import { create, conduce_products, update } from "../../http/conduce";

var moment = require("moment");

const InputContainer = styled(BodyContainer)`
  padding: 30px;
  padding-top: 10px;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

function AddConduce({ history, location }) {
  const { user } = useContext(ContextAuth);

  const [loading, setloading] = useState(false);

  const matches = useMediaQuery("(max-width:768px)");

  const [formValues, setFormValues] = useState({
    date_limit: null,
    products: [],
    client: {
      username: "",
      rnc: "",
      discount: "",
      apply_tax: null,
      credit_limit: "",
    },
    prices: {
      subtotal: 0,
      discount: 0,
      itebis: 0,
      total: 0,
    },
    status_code: "CD001",
    comments: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  const edit = location.state.edit;
  const data = location.state.data;

  function cancel() {
    confirmAlert(
      "warning",
      "¿Está seguro de que quiere cancelar el proceso?"
    ).then((data) => data.value && history.goBack());
  }

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      const information = {
        id: data.id,
        date_limit: data.date_limit,
        client: { value: data.client_id, rnc: "", discount: "", username: "" },
        products: [],
        prices: {},
        status_code: data.status_code,
        comments: data.comments,
        created_at: data.created_at,
      };

      const response = await conduce_products(data.id);
      setFormValues({ ...information, products: response.data });

      setIsLoading(false);
    }

    setIsLoading(false);
    edit && fetch();
  }, [data, edit]);

  async function save(values) {
    values.created_at = moment(new Date()).format("YYYY-MM-DD");
    values.date_limit = moment(values.date_limit).format("YYYY-MM-DD");
    values.admin = user.id;

    setloading(true);
    const { data } = await create(values);
    const success = data.success ? "success" : "error";

    SimpleAlert(success, data.data);
    setloading(false);
    success && history.goBack();
  }

  async function editFunc(values) {
    setloading(true);
    values.date_limit = moment(values.date_limit).format("YYYY-MM-DD");
    values.admin = user.id;

    const { data } = await update(values);
    const success = data.success ? "success" : "error";

    SimpleAlert(success, data.data);
    setloading(false);
    success && history.goBack();
  }

  return (
    <Fragment>
      <DetailsHeader
        icon={edit ? "edit" : "add"}
        title={edit ? "Editar Conduce" : "Añadir nuevo conduce"}
      />

      {!isLoading && (
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(value) => (edit ? editFunc(value) : save(value))}
        >
          {({ handleSubmit, values, setFieldValue, handleChange }) => (
            <div>
              <Container>
                <InputContainer>
                  <Title color="#545358" style={{ textAlign: "center" }}>
                    Información del cliente
                  </Title>

                  <Clients
                    edit={edit}
                    showInformation={true}
                    values={values.client}
                    onChangeEdit={(selected) =>
                      setFieldValue("client", selected)
                    }
                    onChange={(selected) => setFieldValue("client", selected)}
                  />
                </InputContainer>

                <InputContainer
                  style={{ width: matches ? "100%" : "49%", marginTop: 20 }}
                >
                  <Title color="#545358" style={{ textAlign: "center" }}>
                    Notas Adicionales
                  </Title>

                  <AdicionalNote
                    value={values.comments}
                    onChange={handleChange("comments")}
                  />
                </InputContainer>
              </Container>

              <Container style={{ marginTop: 20 }}>
                <Products
                  credit_note={false}
                  edit={edit}
                  products={values.products}
                  prices={values.prices}
                  client={values.client}
                  setProducts={(item) => setFieldValue("products", item)}
                  setPrices={(item) => setFieldValue("prices", item)}
                />
              </Container>

              <SaveArea
                handleSubmit={handleSubmit}
                loading={loading}
                success={true}
                cancel={() => cancel()}
              />
            </div>
          )}
        </Formik>
      )}
    </Fragment>
  );
}

export default withRouter(AddConduce);
