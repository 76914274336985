import http from "./http";

export function create(data) {
  return http.post("visited_clients/create", data);
}

export function grid(page, data) {
  return http.post(`visited_clients/grid?page=${page}`, data);
}

export function get_visits(page, data) {
  return http.post(`visited_clients/get_visits?page=${page}`, data);
}

export function insert_visit(data) {
  return http.post(`visited_clients/insert_visit`, data);
}

export function update(data) {
  return http.put("visited_clients/update", data);
}
