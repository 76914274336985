import { API } from "../actions/quote-actions";

function quoteReducer(state = [], { type, payload }) {
  switch (type) {
    case API:
      return payload.quotes;
    default:
      return state;
  }
}

export default quoteReducer;
