/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Tooltip, Icon } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Filters from "../../components/Products/Filters";
import Header from "../../components/Table/Header";
import Pagination from "../../components/Table/Pagination";
import { Label } from "../../components/Text";
import Divider from "../../components/Divider";
import NotPermissions from "../../components/NotPermissions";

import { Container } from "../../assets/utils/styles/global";

import check_permission from "../../lib/check_permission";

import { grid, productListSelect } from "../../http/products";

import { productData } from "../../actions/products-actions";

var moment = require("moment");
var numeral = require("numeral");

const mapStateToProps = (state) => {
  return {
    products: state.products,
  };
};

const mapActionsToProps = {
  updateProduct: productData,
};

function ProductsGrid({ history, products, updateProduct }) {
  const initialValues = products.length === 0 ? { data: products } : products;
  const initFilters_value = {
    id: null,
    expiration_date: null,
    date: null,
    code: null,
  };

  const [filters, setFilters] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [products_state, setProducts] = useState([]);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [filters_value, setFilters_value] = useState(initFilters_value);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const page = products.length === 0 ? 1 : products.current_page;

      try {
        const { data } = await grid(page);

        setFilters(data);
        updateProduct(data);
      } catch {}

      setIsLoading(false);
    }

    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      setIsLoadingProduct(true);
      const { data } = await productListSelect();
      setProducts(data);
      setIsLoadingProduct(false);
    }

    fetch();
  }, []);

  async function paginate(page, items) {
    setIsLoading(true);
    setFilters_value(items);

    const { data } = await grid(page, items);
    setFilters(data);

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Productos
      </Label>

      <Container style={{ padding: "12px 0 0 0" }}>
        <Header
          canAdd={!check_permission("PE003", "canAdd")}
          items={products_state}
          loading={isLoadingProduct}
          setFilter={(item) => paginate(1, { id: item.value })}
          button_text="Añadir Producto"
          openFilter={() => setOpen(!isOpen)}
          isOpen={isOpen}
          hasMultipleFilters
          add={() =>
            history.push({
              pathname: "add",
              state: { edit: false },
            })
          }
        />

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <Collapse in={isOpen}>
          <Filters
            setFilters={setFilters}
            products={products_state}
            search_filter={(items) => paginate(1, items)}
            filters={filters_value}
            initFilters_value={initFilters_value}
          />
          <Divider />
        </Collapse>

        {check_permission("PE003", "canSee") ? (
          <TableContainer>
            <Table>
              <tbody>
                <Tr>
                  <Th>Código</Th>
                  <Th>Nombre</Th>
                  <Th>Fecha de expiración</Th>
                  <Th>Fabricante</Th>
                  <Th>Cantidad en almacen</Th>
                  <Th>Acción</Th>
                </Tr>

                {filters.data.map((data) => (
                  <Tr key={data.id}>
                    <Td>{data.code}</Td>
                    <Td>{data.name}</Td>
                    <Td>{moment(data.expiration_date).format("DD/MM/YYYY")}</Td>
                    <Td>{data.maker_name}</Td>
                    <Td>{numeral(data.quantity_in_stock).format(0, 0)}</Td>
                    <Td style={{ cursor: "pointer" }}>
                      <Tooltip placement="right" title="Detalle">
                        <Icon
                          color="primary"
                          onClick={() =>
                            history.push({
                              pathname: "details",
                              state: { data: data },
                            })
                          }
                        >
                          description
                        </Icon>
                      </Tooltip>
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        ) : (
          <NotPermissions isLoading={isLoading} />
        )}

        <Pagination
          next={() => paginate(filters.current_page + 1, filters_value)}
          previous={() => paginate(filters.current_page - 1, filters_value)}
          currentPage={filters.current_page}
          last_page={filters.last_page}
          pageOne={() => paginate(1, filters_value)}
        />
      </Container>
    </Fragment>
  );
}

export default withTheme(
  withRouter(connect(mapStateToProps, mapActionsToProps)(ProductsGrid))
);
