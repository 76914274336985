import React, { useState } from "react";
import { Formik } from "formik";

import { Label } from "../Text";
import Button from "../Buttons/Button";
import Modal from "../Modal";
import { Field } from "../Input";
import { SimpleAlert } from "../Alert";

import { register } from "../../http/auth";
import { edit } from "../../http/users";

function AddUser({ close, closeAndReload, isEdit, values }) {
  const [loading, setloading] = useState(false);

  async function submit(value) {
    setloading(true);

    try {
      const { data } = isEdit ? await edit(value) : await register(value);
      const type = data.success ? "success" : "error";
      data.success && closeAndReload(data.information);

      SimpleAlert(type, data.data);
    } catch (error) {
      SimpleAlert("error", "Algo ha pasado por favor intentelo de nuevo");
    }
    setloading(false);
  }

  return (
    <Modal close={close} open={true}>
      <Formik
        enableReinitialize
        initialValues={values}
        onSubmit={(value) => submit(value)}
      >
        {({ handleChange, handleSubmit, values }) => (
          <div style={{ marginTop: 10 }}>
            <Label style={{ marginBottom: 10 }} weight="550">
              Agregar nuevo usuario del sistema
            </Label>

            <Field
              onChange={handleChange("username")}
              label="Nombre del usuario"
              margin="dense"
              variant="outlined"
              style={{ marginTop: 8 }}
              value={values.username}
            />

            <Field
              onChange={handleChange("email")}
              label="Correo electronico"
              margin="dense"
              variant="outlined"
              style={{ marginTop: 8 }}
              value={values.email}
            />

            {!isEdit && (
              <Field
                onChange={handleChange("password")}
                label="Contraseña"
                margin="dense"
                variant="outlined"
                style={{ marginTop: 8 }}
                type="password"
              />
            )}

            <Button
              disabled={
                values.username === "" ||
                values.email === "" ||
                (!isEdit && values.password === "") ||
                loading
              }
              top="8px"
              text={isEdit ? "Editar usuario" : "Crear usuario"}
              action={handleSubmit}
              loading={loading}
              color="primary"
              icon="save"
              side="left"
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default AddUser;
