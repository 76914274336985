/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useRef, useEffect } from "react";
import { withTheme } from "styled-components";
import ReactToPrint from "react-to-print";
import { withRouter } from "react-router-dom";

import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

import Circle from "../../Buttons/Circle";
import ChangeStatus from "./ModalStatus";
import ComponentToPrint from "../../Bills/ComponentToPrint";
import DetailsHeader from "../../DetailsHeader";

import check_permission from "../../../lib/check_permission";

import { list } from "../../../http/codes";
import { all_abonos } from "../../../http/sales";

function Header({
  updateHistory,
  edit,
  data,
  setData,
  products,
  history,
  ...props
}) {
  const componentRef = useRef();
  const [modalStatus, setModalStatus] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [abonos, setAbonos] = useState([]);

  const canPrint = check_permission("PE004", "canPrint");

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await list("SL0");
        setStatusList(data);
      } catch {}
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchAbonos() {
      const response = await all_abonos(data.id);
      setAbonos(response.data);
    }

    fetchAbonos();
  }, [data, updateHistory]);

  function changeData(item = false) {
    if (!item) {
      setModalStatus(false);
    } else {
      const newArray = {
        ...data,
        status_name: item.label,
        status_code: item.value,
      };

      setData(newArray);
      setModalStatus(false);
    }
  }

  return (
    <Fragment>
      <DetailsHeader icon="description" title="Información De la Factura">
        <Circle
          disabled={
            props.loading.app ||
            props.loading.product ||
            data.status_code === "SL002" ||
            !check_permission("PE004", "canEdit")
          }
          title="Editar Factura"
          placement="bottom"
          icon="edit"
          action={edit}
        />

        <ReactToPrint
          trigger={() => (
            <Fab
              disabled={props.loading.app || props.loading.product || !canPrint}
              style={{ marginLeft: 8, marginRight: 8 }}
              size="small"
              color="primary"
            >
              <Tooltip title="Imprimir Factura" placement="bottom">
                <Icon>print</Icon>
              </Tooltip>
            </Fab>
          )}
          content={() => componentRef.current}
        />

        <Circle
          disabled={
            props.loading.app ||
            props.loading.product ||
            data.status_code === "SL002" ||
            !check_permission("PE004", "canChangeStatus")
          }
          title="Cambiar Estado"
          placement="bottom"
          icon="track_changes"
          action={() => setModalStatus(true)}
        />

        <Circle
          disabled={
            props.loading.app ||
            props.loading.product ||
            data.status_code === "SL002" ||
            data.voucher_number === null ||
            !check_permission("PE005", "canAdd")
          }
          title="Convertir a Nota de Credito"
          placement="bottom"
          icon="receipt"
          action={() =>
            history.push({
              pathname: "../credit_note/add",
              state: { edit: false, data: data },
            })
          }
        />
      </DetailsHeader>

      {modalStatus && (
        <ChangeStatus
          statusList={statusList}
          saleData={data}
          close={(item) => changeData(item)}
          products={products}
        />
      )}

      <div style={{ display: "none" }}>
        <ComponentToPrint
          products={products}
          abonos={abonos}
          data={data}
          ref={componentRef}
        />
      </div>
    </Fragment>
  );
}

export default withRouter(withTheme(Header));
