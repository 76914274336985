import React, { Fragment, useState, useEffect } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import LinearProgress from "@material-ui/core/LinearProgress";

import { Label } from "../../components/Text";
import Circle from "../../components/Buttons/Circle";
import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import DetailsHeader from "../../components/DetailsHeader";
import { confirmAlert } from "../../components/Alert";

import { Container } from "../../assets/utils/styles/global";

import { getSalesFollow, change_follow_status } from "../../http/clients";

var moment = require("moment");

function FollowDetails({ location, history }) {
  const data = location.state.data;

  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [sales, setSales] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIsLoadingProduct(true);

      try {
        const response = await getSalesFollow(data.id);
        setSales(response.data.data);
      } catch {}
      setIsLoadingProduct(false);
    }

    fetchData();
  }, [data]);

  async function updateCall() {
    confirmAlert(
      "info",
      "¿Está seguro que ya le dio seguimiento a este cliente y desea eliminarlo de la lista de seguimiento?"
    ).then(async (result) => {
      if (result.value) {
        await change_follow_status({ id: data.id });
        history.push("grid");
      }
    });
  }

  const list = (key, value) => {
    return (
      <RowContainer>
        <Label weight={600}>{key}: </Label>
        <Label style={{ marginLeft: 8 }}>{value}</Label>
      </RowContainer>
    );
  };

  return (
    <Fragment>
      <DetailsHeader icon="description" title="Información Del Cliente">
        <Circle title="Editar" icon="phone" action={() => updateCall()} />
      </DetailsHeader>

      <Body>
        <Card>
          {list("Nombre", data.name)}
          {list("Ciudad", data.city)}
          {list("Celular", data.mobile)}
          {list("Telefono", data.phone)}
        </Card>
      </Body>

      <Container style={{ marginTop: 20, padding: "12px 0 0 0" }}>
        <Title>Ultimas 5 facturas con mas de 20 dias</Title>

        {isLoadingProduct ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <TableContainer style={{ marginTop: 20 }}>
          <Table>
            <tbody>
              <Tr>
                <Th>Código</Th>
                <Th>Total venta</Th>
                <Th>Fecha Creación</Th>
                <Th>Fecha Expiración</Th>
              </Tr>

              {sales.map((data, index) => {
                return (
                  <Tr key={index}>
                    <Td>{data.number_code}</Td>

                    <Td>RD$ {data.total}</Td>
                    <Td>{moment(data.created_at).format("DD/MM/YYYY")}</Td>
                    <Td>{moment(data.date_limit).format("DD/MM/YYYY")}</Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
      </Container>
    </Fragment>
  );
}

export default withTheme(withRouter(FollowDetails));

const RowContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #f8f8f8;

  :nth-child(even) {
    background-color: white;
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  height: 4px;
  background-color: #afdaa1;
`;

const Title = styled.div`
  color: #545358;
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0 12px 30px;
`;

const Card = styled(Container)`
  padding: 0;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
