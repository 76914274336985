export const menu_routes = [
  {
    name: "Inicio",
    path: "/",
    icon: "home",
  },
  {
    name: "Clientes",
    path: "/clients/grid",
    icon: "group",
  },
  {
    name: "Productos",
    path: "/products/grid",
    icon: "shopping_cart",
  },
  {
    name: "Facturación",
    icon: "trending_up",
    subRoute: [
      {
        name: "Facturas",
        path: "/sales/grid",
        icon: "trending_up",
      },
      {
        name: "A vencer",
        path: "/sales/about_expire",
        icon: "trending_up",
      },
      {
        name: "Vencidas",
        path: "/sales/expired",
        icon: "trending_up",
      },
      {
        name: "Seguimiento",
        path: "/follow/grid",
        icon: "trending_up",
      },
    ],
  },
  {
    name: "Cotización",
    path: "/quote/grid",
    icon: "receipt",
  },
  {
    name: "Nota de Credito",
    path: "/credit_note/grid",
    icon: "receipt",
  },
  {
    name: "Conduce",
    path: "/conduce/grid",
    icon: "directions_car",
  },
  {
    name: "Comprobantes",
    path: "/tax/grid",
    icon: "list",
  },
  {
    name: "Ordenes",
    path: "/order/grid",
    icon: "shopping_cart",
  },
  {
    name: "Reportes",
    path: "/reports/sales",
    icon: "receipt",
  },
  {
    name: "Usuarios",
    path: "/users/grid",
    icon: "person",
  },
  {
    name: "Clientes visitas",
    path: "/state_client/grid",
    icon: "group",
  },
];
