/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import { useContext } from "react";

import ContextPermissions from "./ContextPermissions";

function Check_permissions(code, action) {
  const { permissions } = useContext(ContextPermissions);

  var value = false;
  permissions.map((item) => {
    if (item.code === code) {
      value = item[action];
    }
  });

  return value;
}

export default Check_permissions;
