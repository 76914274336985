import React from "react";
import styled from "styled-components";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Button from "./Buttons/Button";

import { Container as BodyContainer } from "../assets/utils/styles/global";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  width: 420px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const ParentButton = styled(BodyContainer)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 20px;
`;

function SaveArea({ history, ...props }) {
  const matches = useMediaQuery("(max-width:768px)");

  return (
    <ParentButton>
      <ButtonContainer>
        <Button
          text="Cancelar"
          action={props.cancel}
          width={matches ? "100%" : "200px"}
          color="secondary"
          icon="cancel"
          side="left"
          top={matches ? "8px" : "0px"}
        />

        <Button
          disabled={props.loading || !props.success || props.isDisabled}
          text="Guardar"
          action={props.handleSubmit}
          loading={props.loading}
          width={matches ? "100%" : "200px"}
          color="primary"
          icon="save"
          side="left"
        />
      </ButtonContainer>
    </ParentButton>
  );
}

export default SaveArea;
