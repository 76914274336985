import React, { useState, useEffect } from "react";
import Select from "react-select";

import { Label } from "../../Text";

import { getPaymentTypeList } from "../../../http/sales";

function Payment({ onChange, value, ...props }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const { data } = await getPaymentTypeList("PM0");
      setData(data);

      setIsLoading(false);
    }

    fetchData();
  }, []);

  return (
    <div style={{ marginTop: 16 }}>
      <Label weight={500} style={{ marginBottom: 4 }}>
        Seleccione el tipo de pago
      </Label>

      <Select
        label={<Label>lklk</Label>}
        placeholder={isLoading ? "Cargando..." : "Tipo de pago"}
        options={data}
        styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
        onChange={onChange}
        isDisabled={isLoading || props.isDisabled}
        value={data.filter((data) => data.value === value)}
      />
    </div>
  );
}

export default Payment;
