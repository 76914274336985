/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Filters from "../../components/Sales/Details/Filters/Filters";
import StatusLabel from "../../components/Sales/StatusLabel";
import Divider from "../../components/Divider";
import { Label } from "../../components/Text";
import Header from "../../components/Table/Header";
import Pagination from "../../components/Table/Pagination";
import NotPermissions from "../../components/NotPermissions";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Tooltip, Icon } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";

import { Container } from "../../assets/utils/styles/global";

import check_permission from "../../lib/check_permission";

import { expired, change_status_expired } from "../../http/sales";
import { clientListSelect } from "../../http/clients";
import { list } from "../../http/codes";
import { usersListForSelect } from "../../http/users";

var moment = require("moment");
var numeral = require("numeral");

function SalesExpired({ history }) {
  const initFilters_value = {
    client_id: null,
    creation_date: null,
    expiration_date: null,
    number_code: null,
    seller_id: null,
    status: null,
    date_1: null,
    date_2: null,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({ data: [] });
  const [clients, setClients] = useState([]);
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [list_state, setList] = useState([]);
  const [filters_value, setFilters_value] = useState(initFilters_value);
  const [sellers, setSellers] = useState([]);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      await change_status_expired();

      const { data } = await expired(1);
      setFilters(data);

      setIsLoading(false);
    }

    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      setIsLoadingClient(true);
      const { data } = await clientListSelect();
      setClients(data);
      setIsLoadingClient(false);
    }

    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      const { data } = await usersListForSelect();
      setSellers(data);
    }

    fetch();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await list("SL0");
        setList(data);
      } catch {}
    }

    fetchData();
  }, []);

  async function paginate(page, items) {
    setIsLoading(true);
    setFilters_value(items);

    const { data } = await expired(page, items);
    setFilters(data);

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Facturas Vencidas
      </Label>

      <Container style={{ padding: "12px 0 0 0" }}>
        <Header
          items={clients}
          loading={isLoadingClient}
          setFilter={(item) => paginate(1, { client_id: item.value })}
          openFilter={() => setOpen(!isOpen)}
          isOpen={isOpen}
          hasMultipleFilters
        />

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <Collapse in={isOpen}>
          <Filters
            setFilters={setFilters}
            status={list_state}
            clients={clients}
            sellers={sellers}
            search_filter={(items) => paginate(1, items)}
            filters={filters_value}
            initFilters_value={initFilters_value}
          />
          <Divider />
        </Collapse>

        {check_permission("PE004", "canSee") ? (
          <TableContainer>
            <Table>
              <tbody>
                <Tr>
                  <Th>Código</Th>
                  <Th>Cliente</Th>
                  <Th>Total Venta</Th>
                  <Th>Fecha Creación</Th>
                  <Th>Fecha Expiración</Th>
                  <Th>Estado</Th>
                  <Th>Acción</Th>
                </Tr>

                {filters.data.map((data, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{data.number_code}</Td>
                      <Td>{data.name}</Td>
                      <Td>RD$ {numeral(data.total).format("0,0.00")}</Td>

                      <Td>{moment(data.created_at).format("DD/MM/YYYY")}</Td>
                      <Td>{moment(data.date_limit).format("DD/MM/YYYY")}</Td>
                      <Td>
                        <StatusLabel data={data} />
                      </Td>

                      <Td style={{ cursor: "pointer" }}>
                        <Tooltip placement="right" title="Detalle">
                          <Icon
                            color="primary"
                            onClick={() =>
                              history.push({
                                pathname: "details",
                                state: { id: data.id },
                              })
                            }
                          >
                            description
                          </Icon>
                        </Tooltip>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        ) : (
          <NotPermissions isLoading={isLoading} />
        )}

        <Pagination
          next={() => paginate(filters.current_page + 1, filters_value)}
          previous={() => paginate(filters.current_page - 1, filters_value)}
          currentPage={filters.current_page}
          last_page={filters.last_page}
        />
      </Container>
    </Fragment>
  );
}

export default withTheme(withRouter(SalesExpired));
