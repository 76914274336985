import React, { useState } from "react";
import { Formik } from "formik";

import { Label } from "../Text";
import Button from "../Buttons/Button";
import Modal from "../Modal";
import { Field } from "../Input";
import { SimpleAlert } from "../Alert";

import { resetPassword } from "../../http/users";

function ResetPassword({ close, user }) {
  const [loading, setloading] = useState(false);

  async function submit(value) {
    setloading(true);

    const obj = { id: user.id, password: value.password };
    const { data } = await resetPassword(obj);
    setloading(false);
    data && close();

    SimpleAlert("success", "Contraseña reiniciada exitosamente");
  }

  return (
    <Modal close={() => close()} open={true}>
      <Formik
        initialValues={{ password: "" }}
        onSubmit={(value) => submit(value)}
      >
        {({ handleChange, handleSubmit, values }) => (
          <div style={{ marginTop: 10 }}>
            <Label style={{ marginTop: 0 }} weight="550">
              Reiniciar contraseña para {user.name}
            </Label>

            <Field
              onChange={handleChange("password")}
              label="Ingrese la contraseña aquí"
              margin="dense"
              variant="outlined"
              style={{ marginTop: 8 }}
              type="password"
            />

            <Button
              disabled={values.password === "" || loading}
              top="8px"
              text="Reiniciar contraseña"
              action={handleSubmit}
              loading={loading}
              color="primary"
              icon="lock"
              side="left"
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default ResetPassword;
