import React, { useState } from "react";
import styled, { withTheme } from "styled-components";

import { Label } from "../Text";

const Container = styled.div`
  display: flex;
`;

const Shape = styled.div`
  width: 100px;
  height: 32px;
  border-radius: 8px 20px 0 0;
  background-color: ${(props) => (props.active ? "white" : "#f2f2f2")};
  border: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 8px;
`;

const Text = styled(Label)`
  font-size: 14px;
  color: ${(props) => (props.active ? props.theme.colors.PRIMARY : "#a6a6a6")};
`;

function StatusFilter({ setFilter, setAll, status }) {
  const [active, setActive] = useState(0);

  return (
    <Container>
      <Shape
        active={active === 0}
        onClick={() => {
          setActive(0);
          setAll();
        }}
      >
        <Text active={active === 0}>Todas</Text>
      </Shape>

      {status.map((data) => (
        <Shape
          onClick={() => {
            setActive(data.value);
            setFilter(data);
          }}
          key={data.value}
          active={data.value === active}
        >
          <Text active={data.value === active}>{data.label}</Text>
        </Shape>
      ))}
    </Container>
  );
}

export default withTheme(StatusFilter);
