import http from "./http";

export function grid(page, data) {
  return http.post(`products/grid?page=${page}`, data);
}

export function list() {
  return http.get("products/list");
}

export function productListSelect() {
  return http.get("products/productListSelect");
}

export function getSelf(id) {
  return http.get("products/getSelf", { params: { id: id } });
}

export function create(data) {
  return http.post("products/create", data);
}

export function update(data) {
  return http.put("products/update", data);
}

export function updateQuantity(data) {
  return http.put("products/changeQuantity", data);
}
