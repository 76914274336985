import React from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Formik } from "formik";

import { Icon } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Button from "../Buttons/Button";
import Circle from "../Buttons/Circle";
import { Label } from "../Text";

const FilterText = styled(Label)`
  font-size: 14px;
  margin-left: 4px;
  color: ${(props) => props.theme.colors.PRIMARY};
  font-weight: 600;
`;

const Container = styled.div`
  padding: 0 20px 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }

  .button-container {
    display: flex;
    align-items: center;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

function Header({ history, ...props }) {
  const matches = useMediaQuery("(max-width:768px)");

  return (
    <Formik initialValues={{ client: null }}>
      {({ values, setFieldValue }) => (
        <Container>
          <SelectContainer>
            {props.items && (
              <div style={{ width: matches ? "100%" : 240 }}>
                <Select
                  isClearable
                  onChange={(item) => {
                    if (item === null) {
                      setFieldValue("client", null);
                      props.setFilter({ value: null });
                    } else {
                      setFieldValue("client", item.value);
                      props.setFilter(item);
                    }
                  }}
                  options={props.items}
                  placeholder="Buscar..."
                  isLoading={props.loading}
                  styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
                  value={props.items.filter(
                    (item) => item.value === values.client
                  )}
                />
              </div>
            )}

            {props.hasMultipleFilters && (
              <div
                onClick={props.openFilter}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  marginTop: matches && "20px",
                }}
              >
                <Icon style={{ fontSize: 18, marginLeft: 16 }} color="primary">
                  filter_list
                </Icon>

                <FilterText>
                  {props.isOpen ? "Ocultar Filtros" : "Mas Filtros"}
                </FilterText>
              </div>
            )}
          </SelectContainer>

          <div className="button-container">
            {props.hasExportExcel && (
              <Circle
                title="Exportar a excel"
                placement="left"
                icon="import_export"
                action={() => props.exportExcel()}
              />
            )}

            {props.button_text && (
              <Button
                disabled={props.canAdd}
                text={props.button_text}
                action={props.add}
                width={matches ? "100%" : "280px"}
                color="primary"
                icon="add"
                side="left"
              />
            )}
          </div>
        </Container>
      )}
    </Formik>
  );
}

export default withRouter(Header);
