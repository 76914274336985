import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function Circle({ action, disabled, icon, ...props }) {
  const classes = useStyles();

  return (
    <Fab
      size="small"
      disabled={disabled}
      onClick={action}
      color="primary"
      className={classes.fab}
    >
      <Tooltip {...props}>
        <Icon>{icon ? icon : `add`}</Icon>
      </Tooltip>
    </Fab>
  );
}

export default Circle;
