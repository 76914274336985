import React, { useState, Fragment, useEffect } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { Label } from "../../components/Text";
import AddQuantity from "../../components/Products/AddQuantity";
import Circle from "../../components/Buttons/Circle";
import DetailsHeader from "../../components/DetailsHeader";
import Timeline from "../../components/Timeline";

import { Container } from "../../assets/utils/styles/global";

import check_permission from "../../lib/check_permission";
import { timelineCode } from "../../lib/constant";

import { getSelf } from "../../http/products";
import { getTimeline } from "../../http/timeline";

var moment = require("moment");
var numeral = require("numeral");

function ProductDetails({ history, location }) {
  const [product, setProduct] = useState(location.state.data);
  const [modal, setModal] = useState(false);
  const [timeline, setTimeline] = useState({ data: [] });
  const [timelinePage, setTimelinePage] = useState(1);

  useEffect(() => {
    async function fetchTimeline() {
      try {
        const response = await getTimeline({
          module_id: product.id,
          module_name: timelineCode.product,
          page: timelinePage,
        });

        setTimeline(response.data);
      } catch {}
    }

    fetchTimeline();
  }, [product, timelinePage]);

  async function fetchData() {
    try {
      const { data } = await getSelf(product.id);
      setProduct(data[0]);
    } catch {}
  }

  const list = (key, value) => {
    return (
      <RowContainer>
        <Label weight={600}>{key}: </Label>
        <Label style={{ marginLeft: 8 }}>{value}</Label>
      </RowContainer>
    );
  };

  return (
    <Fragment>
      <DetailsHeader icon="description" title="Información Del Producto">
        <Circle
          disabled={!check_permission("PE003", "canEdit")}
          title="Editar"
          icon="edit"
          action={() =>
            history.push({
              pathname: "add",
              state: { productData: product, edit: true },
            })
          }
        />

        <Circle
          disabled={!check_permission("PE003", "canAddQuantity")}
          title="Añadir Cantidad"
          icon="add"
          action={() => setModal(true)}
        />
      </DetailsHeader>

      <Body>
        <Card>
          {list("Nombre", product.name)}
          {list("Descripción", product.description)}
          {list("Costo", numeral(product.cost).format("0,0.00"))}
          {list(
            "Fecha de expiración",
            moment(product.expiration_date).format("DD/MM/YYYY")
          )}
          {list("Incluye impuesto", product.apply_tax ? "Si" : "No")}
        </Card>

        <Card>
          {list("Suplidor", product.maker_name)}
          {list(
            "Cantidad en almacen",
            numeral(product.quantity_in_stock).format(0, 0)
          )}
          {list(
            "Precio de venta",
            numeral(product.sale_price).format("0,0.00")
          )}
          {list(
            "Facturar sin existencia",
            product.bill_no_product ? "Si" : "No"
          )}
        </Card>
      </Body>

      <Timeline data={timeline} paginate={(page) => setTimelinePage(page)} />

      {modal && (
        <AddQuantity
          close={() => setModal(false)}
          id={product.id}
          closeAndReload={() => {
            fetchData();
            setModal(false);
          }}
        />
      )}
    </Fragment>
  );
}

export default withTheme(withRouter(ProductDetails));

const RowContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #f8f8f8;

  :nth-child(even) {
    background-color: white;
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled(Container)`
  padding: 0;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
