/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import check_permission from "../lib/check_permission";

import LinearProgress from "@material-ui/core/LinearProgress";

import { Tr, Table, Td, Th, TableContainer } from "../components/Table";
import { Label } from "../components/Text";
import Divider from "../components/Divider";
import Header from "../components/Table/Header";
import Pagination from "../components/Table/Pagination";
import NotPermissions from "../components/NotPermissions";
import AddNewReceipt from "../components/AddNewReceipt";

import { Container } from "../assets/utils/styles/global";

import { grid, listForSelect } from "../http/taxReceipt";

import { taxReceiptData } from "../actions/receipt-actions";

var moment = require("moment");

const mapStateToProps = (state) => {
  return {
    taxReceipt: state.taxReceipt,
  };
};

const mapActionsToProps = {
  updateTaxReceipt: taxReceiptData,
};

function TaxReceipt({ taxReceipt, updateTaxReceipt }) {
  const initialValues =
    taxReceipt.length === 0 ? { data: taxReceipt } : taxReceipt;

  const [filters, setFilters] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [addReceipt, setAddReceipt] = useState(false);
  const [update, setUpdate] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [isLoadingTaxList, setIsLoadingTaxList] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [filters_value, setFilters_value] = useState({
    id_tax_receipts: null,
  });

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const page = taxReceipt.length === 0 ? 1 : taxReceipt.current_page;

      try {
        const { data } = await grid(page);

        setFilters(data);
        updateTaxReceipt(data);
      } catch {}

      setIsLoading(false);
    }

    fetch();
  }, [update]);

  useEffect(() => {
    async function fetch() {
      setIsLoadingTaxList(true);
      try {
        const { data } = await listForSelect();
        setTaxList(data);
      } catch {}
      setIsLoadingTaxList(false);
    }

    fetch();
  }, []);

  async function paginate(page, items) {
    setIsLoading(true);
    setFilters_value(items);

    const { data } = await grid(page, items);
    setFilters(data);

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Comprobantes
      </Label>

      <Container style={{ padding: "12px 0 0 0" }}>
        <Header
          canAdd={!check_permission("PE007", "canAdd")}
          items={taxList}
          loading={isLoadingTaxList}
          setFilter={(item) => paginate(1, { id_tax_receipts: item.value })}
          button_text="Añadir Comprobante"
          openFilter={() => setOpen(!isOpen)}
          isOpen={isOpen}
          add={() => setAddReceipt(true)}
        />

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        {check_permission("PE007", "canSee") ? (
          <TableContainer>
            <Table>
              <tbody>
                <Tr>
                  <Th>Tipo de comprobante</Th>
                  <Th>Fecha de expiración</Th>
                  <Th>Numeración</Th>
                  <Th>Usado</Th>
                </Tr>

                {filters.data.map((data) => (
                  <Tr key={data.id}>
                    <Td>{data.name}</Td>
                    <Td>{moment(data.expire_date).format("DD/MM/YYYY")}</Td>
                    <Td>{data.numeration}</Td>
                    <Td>{Boolean(data.used) ? "Si" : "No"}</Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        ) : (
          <NotPermissions isLoading={isLoading} />
        )}

        <Pagination
          next={() => paginate(filters.current_page + 1, filters_value)}
          previous={() => paginate(filters.current_page - 1, filters_value)}
          currentPage={filters.current_page}
          last_page={filters.last_page}
          pageOne={() => paginate(1, filters_value)}
        />
      </Container>

      {addReceipt && (
        <AddNewReceipt
          close={() => setAddReceipt(false)}
          closeAndReload={(data) => {
            setAddReceipt(false);
            setUpdate(!update);
          }}
        />
      )}
    </Fragment>
  );
}

export default withTheme(
  withRouter(connect(mapStateToProps, mapActionsToProps)(TaxReceipt))
);
