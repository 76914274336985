import http from "./http";

export function create(data) {
  return http.post("OrdersController/create", data);
}

export function grid(page, data) {
  return http.post(`OrdersController/grid?page=${page}`, data);
}

export function order_products(id) {
  return http.get(`OrdersController/order_products?id=${id}`);
}

export function production_order(values) {
  return http.put(`OrdersController/production_order`, values);
}

export function delete_product_order(values) {
  return http.post(`OrdersController/delete_product_order`, values);
}

export function edit_order(data) {
  return http.put("OrdersController/edit", data);
}

export function days_order(date) {
  return http.get(`OrdersController/days_order?date=${date}`);
}

export function update_status(data) {
  return http.put(`OrdersController/update_status`, data);
}
