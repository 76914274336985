import React, { useState } from "react";
import { Formik } from "formik";
import styled from "styled-components";

import { Label } from "../../components/Text";
import Button from "../../components/Buttons/Button";
import Modal from "../../components/Modal";
import Calendar from "../../components/Calendar";
import { SimpleAlert } from "../../components/Alert";
import Radio from "../../components/RadioButton";
import { TextArea } from "../../components/Input";

import { insert_visit } from "../../http/visited_clients";

function AddVisit({ onClose, client_id, closeAndReload }) {
  const [loading, setloading] = useState(false);

  async function submit(value) {
    setloading(true);
    try {
      const { data } = await insert_visit(value);
      const type = data.success ? "success" : "error";
      data.success && closeAndReload();
      SimpleAlert(type, data.data);
    } catch (error) {
      SimpleAlert("error", "Algo ha pasado por favor intentelo de nuevo");
    }
    setloading(false);
  }

  return (
    <Modal close={onClose} open={true}>
      <Formik
        initialValues={{
          date: new Date(),
          buy_me: false,
          client_id,
          notes: "",
        }}
        onSubmit={(value) => submit(value)}
      >
        {({ handleSubmit, values, setFieldValue, handleChange }) => (
          <div style={{ marginTop: 10 }}>
            <Label style={{ marginBottom: 10 }} weight="550">
              Agregar Visita
            </Label>

            <div style={{ marginTop: 12 }}>
              <Calendar
                setDateState={(date) => setFieldValue("date", date)}
                text="Fecha de visita"
                value={values.date}
              />
            </div>

            <RadioContainer>
              <Label weight={500}>Me ha comprado?:</Label>

              <Radio
                action={() => setFieldValue("buy_me", true)}
                selected={values.buy_me}
                label="Sí"
                margin="0 0 0 3px"
              />

              <Radio
                action={() => setFieldValue("buy_me", false)}
                selected={!values.buy_me}
                label="No"
                margin="0 5px 0 0"
              />
            </RadioContainer>

            <Label color="#545358" style={{ marginTop: "16px" }}>
              Notas adicionales
            </Label>

            <TextArea
              value={values.notes}
              onChange={handleChange("notes")}
              rows="4"
            />

            <Button
              top="12px"
              text="Guardar"
              action={handleSubmit}
              loading={loading}
              color="primary"
              icon="save"
              side="left"
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default AddVisit;

const RadioContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
`;
