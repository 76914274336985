import React, { Fragment, useRef } from "react";
import { withTheme } from "styled-components";
import ReactToPrint from "react-to-print";
import { withRouter } from "react-router-dom";

import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

import PrintQuote from "../../parts/quote/print";

import Circle from "../../components/Buttons/Circle";
import DetailsHeader from "../../components/DetailsHeader";

import check_permission from "../../lib/check_permission";

function Header({ edit, data, products, history }) {
  const componentRef = useRef();
  const canEdit = check_permission("PE011", "canEdit");
  const canPrint = check_permission("PE011", "canPrint");
  const canAddInvoice = check_permission("PE004", "canAdd");

  return (
    <Fragment>
      <DetailsHeader icon="description" title="Información De la Cotización">
        <Circle
          disabled={!canEdit}
          title="Editar Cotización"
          placement="bottom"
          icon="edit"
          action={edit}
        />

        <ReactToPrint
          trigger={() => (
            <Fab
              disabled={!canPrint}
              style={{ marginLeft: 8, marginRight: 8 }}
              size="small"
              color="primary"
            >
              <Tooltip title="Imprimir" placement="bottom">
                <Icon>print</Icon>
              </Tooltip>
            </Fab>
          )}
          content={() => componentRef.current}
        />

        <Circle
          disabled={!canAddInvoice}
          title="Convertir a Factura"
          placement="bottom"
          icon="receipt"
          action={() =>
            history.push({
              pathname: "../sales/add-from-quote",
              state: { data },
            })
          }
        />
      </DetailsHeader>

      <div style={{ display: "none" }}>
        <PrintQuote products={products} data={data} ref={componentRef} />
      </div>
    </Fragment>
  );
}

export default withRouter(withTheme(Header));
