import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";

import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";

import SaveArea from "../../components/SaveArea";
import DetailsHeader from "../../components/DetailsHeader";

import { Container } from "../../assets/utils/styles/global";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import { SimpleAlert, confirmAlert } from "../../components/Alert";

import { get_permissions_by_user_to_edit, edit } from "../../http/roles";

function Permissions({ location, history }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const id = location.state.id;

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      const { data } = await get_permissions_by_user_to_edit(id);
      setData(data);

      setIsLoading(false);
    }

    fetch();
  }, [id]);

  async function save() {
    setIsLoading(true);

    const object = {
      id: id,
      roles: data,
    };

    const response = await edit(object);
    const type = response.data.success ? "success" : "error";
    response.data.success && history.goBack();

    SimpleAlert(type, response.data.data);
    setIsLoading(false);
  }

  function cancel() {
    confirmAlert(
      "warning",
      "¿Está seguro de que quiere cancelar el proceso?"
    ).then((data) => data.value && history.goBack());
  }

  function changeStatus(index, type) {
    const newArr = [...data];
    var value = newArr[index][type];
    newArr[index][type] = !value;
    setData(newArr);
  }

  return (
    <Fragment>
      <DetailsHeader icon="edit" title="Cambiar permisos del usuario" />

      <Container style={{ padding: "12px 0 0 0", marginTop: 20 }}>
        {isLoading && <LinearProgress value="100" mode="determinate" />}

        <TableContainer>
          <Table>
            <tbody>
              <Tr>
                <Th>Módulo</Th>
                <Th>Ver</Th>
                <Th>Añadir</Th>
                <Th>Editar</Th>
                <Th>Añadir cantidad</Th>
                <Th>Imprimir</Th>
                <Th>Cambiar estado</Th>
              </Tr>

              {data.map((data, index) => (
                <Tr key={index}>
                  <Td>{data.description}</Td>
                  <Td>
                    <Checkbox
                      onChange={() => changeStatus(index, "canSee")}
                      checked={data.canSee}
                      color="primary"
                    />
                  </Td>

                  <Td>
                    <Checkbox
                      onChange={() => changeStatus(index, "canAdd")}
                      checked={data.canAdd}
                      color="primary"
                      disabled={
                        data.code === "PE001" ||
                        data.code === "PE008" ||
                        data.code === "PE010"
                      }
                    />
                  </Td>

                  <Td>
                    <Checkbox
                      onChange={() => changeStatus(index, "canEdit")}
                      checked={data.canEdit}
                      color="primary"
                      disabled={
                        data.code === "PE001" ||
                        data.code === "PE007" ||
                        data.code === "PE008" ||
                        data.code === "PE010"
                      }
                    />
                  </Td>

                  <Td>
                    <Checkbox
                      onChange={() => changeStatus(index, "canAddQuantity")}
                      checked={data.canAddQuantity}
                      color="primary"
                      disabled={data.code !== "PE003"}
                    />
                  </Td>

                  <Td>
                    <Checkbox
                      onChange={() => changeStatus(index, "canPrint")}
                      checked={data.canPrint}
                      color="primary"
                      disabled={
                        data.code === "PE003" ||
                        data.code === "PE001" ||
                        data.code === "PE002" ||
                        data.code === "PE007" ||
                        data.code === "PE008" ||
                        data.code === "PE009" ||
                        data.code === "PE010"
                      }
                    />
                  </Td>

                  <Td>
                    <Checkbox
                      onChange={() => changeStatus(index, "canChangeStatus")}
                      checked={data.canChangeStatus}
                      color="primary"
                      disabled={
                        data.code === "PE003" ||
                        data.code === "PE001" ||
                        data.code === "PE002" ||
                        data.code === "PE007" ||
                        data.code === "PE008" ||
                        data.code === "PE009" ||
                        data.code === "PE010"
                      }
                    />
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Container>

      <SaveArea
        handleSubmit={() => save()}
        loading={isLoading}
        success={true}
        cancel={() => cancel()}
      />
    </Fragment>
  );
}

export default withRouter(Permissions);
