import { API } from "../actions/clients-actions";

function clientsReducer(state = [], { type, payload }) {
  switch (type) {
    case API:
      return payload.client;
    default:
      return state;
  }
}

export default clientsReducer;
