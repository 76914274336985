import React from "react";
import styled from "styled-components";
import { sum } from "lodash";

import { Label } from "../Text";

var numeral = require("numeral");

const Container = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  justify-content: space-between;
`;

function Amount({ data, abonos }) {
  return (
    <Container>
      <TextContainer>
        <Label weight={600} style={{ marginRight: 8 }}>
          Subtotal
        </Label>
        <Label>{numeral(data.subtotal).format("0,0.00")}</Label>
      </TextContainer>

      <TextContainer>
        <Label weight={600} style={{ marginRight: 8 }}>
          Descuento
        </Label>
        <Label>{numeral(data.sale_discount).format("0,0.00")}</Label>
      </TextContainer>

      <TextContainer>
        <Label weight={600} style={{ marginRight: 8 }}>
          ITBIS
        </Label>
        <Label>{numeral(data.itebis).format("0,0.00")}</Label>
      </TextContainer>

      {abonos && abonos.length > 0 && (
        <TextContainer>
          <Label weight={600} style={{ marginRight: 8 }}>
            Total abonado
          </Label>
          <Label>
            {numeral(sum(abonos.map((item) => Number(item.amount)))).format(
              "0,0.00"
            )}
          </Label>
        </TextContainer>
      )}
    </Container>
  );
}

export default Amount;
