import React from "react";
import { Bar } from "react-chartjs-2";

function BarChart({ data, position }) {
  return (
    <Bar
      data={data}
      options={{
        title: {
          display: true,
          text: "Ventas del mes",
          fontSize: 25,
        },
        legend: {
          display: true,
          position: position,
        },
      }}
    />
  );
}

export default BarChart;
