import http from "./http";

export function login(user) {
  return http.get("auth/login", {
    params: {
      username: user.authMethod,
      password: user.password,
    },
  });
}

export function register(data) {
  return http.post("auth/register", data);
}
