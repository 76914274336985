import styled from "styled-components";
import theme from "../../../theme";

export const Container = styled.div`
  width: 100%;
  padding: 8px 30px 8px 30px;
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: ${theme.body.RADIUS};
  box-sizing: border-box;
`;
