import React from "react";
import styled, { withTheme } from "styled-components";

import Header from "./Header";
import Client from "./Client";
import Products from "./Products";
import Amount from "./Amount";
import Total from "./Total";
import Sign from "./Sign";
import Abono from "./abono";

const Container = styled.div`
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
`;

const Label = styled.div`
  color: black;
  font-size: 14px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  margin-top: 12px;
  width: 100%;
`;

class ComponentToPrint extends React.Component {
  render() {
    return (
      this.props.data !== null && (
        <Container>
          <div>
            <Header
              isConduce={this.props.conduce}
              isCreditNote={this.props.isCreditNote}
              data={this.props.data}
            />
            <Divider />
            <Client data={this.props.data} />
            <Divider />
            <Products data={this.props.products} />
            <Divider />
            {this.props.abonos.length > 0 && (
              <>
                <Abono abonos={this.props.abonos} />
                <Divider />
              </>
            )}

            <Amount data={this.props.data} abonos={this.props.abonos} />
            <Divider />
            <Total data={this.props.data} abonos={this.props.abonos} />
          </div>

          <div>
            <Sign />

            <Label>
              C/Resp. 3ra, Res. Castillo. No.3A Santo Domingo Oeste, R.D. Tel:
              1-809-379-2052, 1-829- 341-4169. Correo: dairiesfoods@gmail.com
            </Label>
          </div>
        </Container>
      )
    );
  }
}

export default withTheme(ComponentToPrint);
