/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Tooltip, Icon } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import { Container } from "../../assets/utils/styles/global";
import Header from "../../components/Table/Header";
import Pagination from "../../components/Table/Pagination";
import { Label } from "../../components/Text";
import Divider from "../../components/Divider";
import Filters from "../../components/Conduce/Filters";
import NotPermissions from "../../components/NotPermissions";

import check_permission from "../../lib/check_permission";

import { conducesData } from "../../actions/conduces-actions";

import { grid } from "../../http/conduce";
import { clientListSelect } from "../../http/clients";

const mapStateToProps = (state) => {
  return {
    conduces: state.conduces,
  };
};

const mapActionsToProps = {
  updateConduce: conducesData,
};

var moment = require("moment");

function ConduceGrid({ history, updateConduce, conduces }) {
  const initialValues = conduces.length === 0 ? { data: conduces } : conduces;
  const initFilters_value = {
    id: null,
    created_at: null,
    date_limit: null,
    status_code: null,
    date_1: null,
    date_2: null,
  };

  const [filters, setFilters] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(true);
  const [clients_state, setClients] = useState([]);
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [filters_value, setFilters_value] = useState(initFilters_value);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const page = conduces.length === 0 ? 1 : conduces.current_page;

      const { data } = await grid(page);
      updateConduce(data);
      setFilters(data);

      setIsLoading(false);
    }

    setIsLoading(false);
    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      setIsLoadingClient(true);
      const { data } = await clientListSelect();
      setClients(data);
      setIsLoadingClient(false);
    }

    fetch();
  }, []);

  async function paginate(page, items) {
    setIsLoading(true);
    setFilters_value(items);

    const { data } = await grid(page, items);
    setFilters(data);

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Conduces
      </Label>

      <Container style={{ padding: "12px 0 0 0" }}>
        <Header
          canAdd={!check_permission("PE006", "canAdd")}
          items={clients_state}
          loading={isLoadingClient}
          setFilter={(item) => paginate(1, { client_id: item.value })}
          button_text="Añadir Conduce"
          openFilter={() => setOpen(!isOpen)}
          isOpen={isOpen}
          hasMultipleFilters
          add={() =>
            history.push({
              pathname: "add",
              state: { edit: false },
            })
          }
        />

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <Collapse in={isOpen}>
          <Filters
            setFilters={setFilters}
            clients={clients_state}
            search_filter={(items) => paginate(1, items)}
            filters={filters_value}
            initFilters_value={initFilters_value}
          />
          <Divider />
        </Collapse>

        {check_permission("PE006", "canSee") ? (
          <TableContainer>
            <Table>
              <tbody>
                <Tr>
                  <Th>Código</Th>
                  <Th>Cliente</Th>
                  <Th>Total venta</Th>
                  <Th>Fecha Creación</Th>
                  <Th>Estado</Th>
                  <Th>Acciones</Th>
                </Tr>

                {filters.data.map((data, index) => (
                  <Tr key={index}>
                    <Td>{data.number_code}</Td>
                    <Td>{data.name}</Td>
                    <Td>RD$ {data.total}</Td>
                    <Td>{moment(data.created_at).format("DD/MM/YYYY")}</Td>
                    <Td>{data.description}</Td>

                    <Td style={{ cursor: "pointer" }}>
                      <Tooltip placement="right" title="Detalle">
                        <Icon
                          color="primary"
                          onClick={() =>
                            history.push({
                              pathname: "details",
                              state: { id: data.id },
                            })
                          }
                        >
                          description
                        </Icon>
                      </Tooltip>
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        ) : (
          <NotPermissions isLoading={isLoading} />
        )}

        <Pagination
          next={() => paginate(filters.current_page + 1, filters_value)}
          previous={() => paginate(filters.current_page - 1, filters_value)}
          currentPage={filters.current_page}
          last_page={filters.last_page}
          pageOne={() => paginate(1, filters_value)}
        />
      </Container>
    </Fragment>
  );
}

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(ConduceGrid)
);
