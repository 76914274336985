import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Field } from "../../components/Input";

function Header({ history, handleAdd, handleResults }) {
  const matches = useMediaQuery("(max-width:768px)");

  return (
    <Container>
      <SelectContainer>
        <Field
          onChange={handleResults}
          label="Nombre"
          margin="dense"
          variant="outlined"
          style={{ width: matches ? "100%" : 240 }}
        />
      </SelectContainer>
    </Container>
  );
}

export default withRouter(Header);

const Container = styled.div`
  padding: 0 20px 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 40px);
  }
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
