import React, { useState, useContext } from "react";
import { Formik } from "formik";

import ContextAuth from "../../lib/ContextUser";

import Modal from "../../components/Modal";
import Button from "../../components/Buttons/Button";
import { Label } from "../../components/Text";
import { SimpleAlert } from "../../components/Alert";
import { Field } from "../../components/Input";

import { add_abono } from "../../http/sales";

const initialValues = {
  amount: "",
};

function AddAbono({ saleId, onClose, onSuccess }) {
  const { user } = useContext(ContextAuth);

  const [loading, setloading] = useState(false);

  async function save(values) {
    setloading(true);

    const object = {
      sale_id: saleId,
      user_id: user.id,
      amount: values.amount,
    };

    try {
      const { data } = await add_abono(object);
      const type = data.success ? "success" : "error";

      SimpleAlert(type, data.data);
      onSuccess(values.amount);
    } catch (error) {
      SimpleAlert("error", "Algo ha pasado por favor intentelo de nuevo");
    }
    setloading(false);
  }

  return (
    <Modal close={onClose} open={true}>
      <Formik initialValues={initialValues} onSubmit={save}>
        {({ handleSubmit, values, handleChange }) => (
          <div style={{ marginTop: 10 }}>
            <Label style={{ marginBottom: 10 }} weight="550">
              Agregar abono
            </Label>

            <Field
              onChange={handleChange("amount")}
              label="Monto"
              margin="dense"
              variant="outlined"
              style={{ marginTop: 8 }}
            />

            <Button
              top="16px"
              text="Agregar Abono"
              action={handleSubmit}
              loading={loading}
              disabled={loading}
              color="primary"
              icon="save"
              side="left"
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default AddAbono;
