import http from "./http";

export function list() {
  return http.get("users/list");
}

export function usersListForSelect() {
  return http.get("users/usersListForSelect");
}

export function resetPassword(data) {
  return http.put("users/resetPassword", data);
}

export function verify_is_reset(id) {
  return http.get("users/verify_is_reset", { params: { id: id } });
}

export function edit(data) {
  return http.put("users/edit", data);
}
