import React, { useState, useContext } from "react";
import styled, { withTheme } from "styled-components";
import { Formik } from "formik";

import { Title, Label } from "../components/Text";
import { Logo } from "../components/Image";
import { Field } from "../components/Input";
import Button from "../components/Buttons/Button";
import { SimpleAlert } from "../components/Alert";

import { login } from "../http/auth";

import ContextAuth from "../lib/ContextAuth";
import ContextUser from "../lib/ContextUser";
import ContextPermissions from "../lib/ContextPermissions";

import LOGO from "../assets/images/logoDairies.jpeg";

const Container = styled.div`
  display: flex;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const LoginContainer = styled.div`
  padding: 2% 2% 3% 2%;
  border-radius: ${(props) => props.radius};
  background-color: white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  width: 350px;

  @media (max-width: 800px) {
    padding: 10%;
    margin: 5%;
  }
`;

const LogoContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 5px;
`;

function Signin({ theme }) {
  const [loading, setLoading] = useState(false);

  const { setLogged } = useContext(ContextAuth);
  const { setUser } = useContext(ContextUser);
  const { setPermissions } = useContext(ContextPermissions);

  async function submit(values) {
    setLoading(true);

    try {
      const { data } = await login(values);

      if (data.success) {
        const object = { name: data.user };
        setLogged(true);
        setUser({ id: data.user_id, name: data.user });
        setPermissions(data.roles);

        localStorage.setItem("logged", true);
        localStorage.setItem("id", data.user_id);
        localStorage.setItem("user", JSON.stringify(object));
      } else {
        SimpleAlert("error", "Los datos son erroneos");
      }
    } catch (error) {
      SimpleAlert("error", "Algo ha pasado por favor intentelo de nuevo");
    }

    setLoading(false);
  }

  function handleKeyPress(target, values) {
    if (target.charCode === 13) {
      if (values.authMethod !== "" && values.password !== "") {
        submit(values);
      }
    }
  }

  return (
    <Container>
      <LoginContainer radius={theme.body.RADIUS}>
        <LogoContainer>
          <Logo src={LOGO} />
        </LogoContainer>

        <Title>Bienvenido a Dairies Foods</Title>

        <Label weight="550" color="#CCCCCC">
          Por favor ingrese a su cuenta.
        </Label>

        <Formik
          initialValues={{ authMethod: "", password: "" }}
          onSubmit={(value) => submit(value)}
        >
          {({ handleChange, handleSubmit, values }) => (
            <div style={{ marginTop: 20 }}>
              <Field
                onChange={handleChange("authMethod")}
                label="Usuario"
                margin="dense"
                variant="outlined"
                style={{ marginTop: 8 }}
              />

              <Field
                onKeyPress={(target) => handleKeyPress(target, values)}
                onChange={handleChange("password")}
                label="Contraseña"
                margin="dense"
                variant="outlined"
                style={{ marginTop: 8 }}
                type="password"
              />

              <Button
                disabled={
                  values.authMethod === "" || values.password === "" || loading
                }
                top="8px"
                text="Inciar sesión"
                action={handleSubmit}
                loading={loading}
                color="primary"
                icon="send"
                side="right"
              />
            </div>
          )}
        </Formik>
      </LoginContainer>
    </Container>
  );
}

export default withTheme(Signin);
