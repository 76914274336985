import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";

import { ThemeProvider as ThemeMaterial } from "@material-ui/styles";
import { Fade } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import SideMenu from "./components/sidemenu/SideMenu";
import ForMobile from "./components/sidemenu/ForMobile";

import ContextAuth from "./lib/ContextAuth";
import ContextUser from "./lib/ContextUser";
import ContextMenu from "./lib/ContextMenu";
import ContextPermissions from "./lib/ContextPermissions";
import { SalesContextProvider } from "./lib/filtersContenxt/sales";

import { out_system } from "./routes";
import GlobalStyle from "./assets/fonts/font";
import theme from "./theme";
import { themeContainer } from "./material-theme";

import clientsReducer from "./reducers/clients-reducer";
import userReducer from "./reducers/user-reducer";
import productsReducer from "./reducers/products-reducer";
import taxReceiptReducer from "./reducers/receipt-reducer";
import salesReducer from "./reducers/sales-reducer";
import conducesReducer from "./reducers/conduces-reducer";
import creditNoteReducer from "./reducers/credit-note-reducer";
import quoteReducer from "./reducers/quote-reducer";
import visitedClientsReducer from "./reducers/visited-clients-reducer";

import { permissions_by_user } from "./http/roles";
import { verify_is_reset } from "./http/users";

const allReducer = combineReducers({
  clients: clientsReducer,
  users: userReducer,
  products: productsReducer,
  taxReceipt: taxReceiptReducer,
  sales: salesReducer,
  conduces: conducesReducer,
  creditNote: creditNoteReducer,
  quotes: quoteReducer,
  visitedClients: visitedClientsReducer,
});

const store = createStore(allReducer);

const Container = styled.div`
  background-color: ${theme.colors.BODY};
  width: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

function App() {
  const [logged, setLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [user, setUser] = useState({ name: "" });
  const [permissions, setPermissions] = useState([]);

  const matches = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    async function fetch() {
      const value = localStorage.getItem("logged");
      const userStorage = localStorage.getItem("user");
      const name = JSON.parse(userStorage);
      const id = localStorage.getItem("id");

      const { data } = await verify_is_reset(id);

      if (data.length) {
        if (data[0].reset) {
          localStorage.setItem("logged", false);
          setLogged(false);
        } else {
          value && get_permission(id);
          userStorage !== null && setUser({ id, name: name.name });
          setLogged(value);
        }
      }

      setIsLoading(false);
    }

    fetch();
  }, []);

  async function get_permission(id) {
    const { data } = await permissions_by_user(id);
    setPermissions(data);
  }

  return (
    <Provider store={store}>
      <SalesContextProvider>
        <ContextMenu.Provider value={{ isOpenMenu, setIsOpenMenu }}>
          <ContextAuth.Provider value={{ logged, setLogged }}>
            <ContextUser.Provider value={{ user, setUser }}>
              <ContextPermissions.Provider
                value={{ permissions, setPermissions }}
              >
                <ThemeMaterial theme={themeContainer}>
                  <ThemeProvider theme={theme}>
                    <GlobalStyle />

                    {isLoading ? (
                      <LoadingContainer>
                        <CircularProgress color="primary" />
                      </LoadingContainer>
                    ) : logged ? (
                      <Fade in={true} timeout={500}>
                        <Container>
                          {matches ? <ForMobile /> : <SideMenu />}
                        </Container>
                      </Fade>
                    ) : (
                      <Router>
                        {out_system.map((route, index) => (
                          <Route
                            key={index}
                            path={route.path}
                            component={route.main}
                          />
                        ))}
                      </Router>
                    )}
                  </ThemeProvider>
                </ThemeMaterial>
              </ContextPermissions.Provider>
            </ContextUser.Provider>
          </ContextAuth.Provider>
        </ContextMenu.Provider>
      </SalesContextProvider>
    </Provider>
  );
}

export default App;
