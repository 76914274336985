/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import { connect } from "react-redux";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Tooltip, Icon } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";

import { Container } from "../../assets/utils/styles/global";

import check_permission from "../../lib/check_permission";
import {
  useSalesFilters,
  initial_values_sales_filter,
} from "../../lib/filtersContenxt/sales";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Divider from "../../components/Divider";
import { Label } from "../../components/Text";
import Header from "../../components/Table/Header";
import Pagination from "../../components/Table/Pagination";
import NotPermissions from "../../components/NotPermissions";

import Filters from "../../parts/quote/filters";

import { quoteData } from "../../actions/quote-actions";

import { grid } from "../../http/quote";
import { clientListSelect } from "../../http/clients";
import { usersListForSelect } from "../../http/users";

var moment = require("moment");
var numeral = require("numeral");

const mapStateToProps = (state) => {
  return {
    quotes: state.quotes,
  };
};

const mapActionsToProps = {
  updateQuote: quoteData,
};

function QuoteGrid({ history, updateQuote, quotes }) {
  const { filtersSales, setFiltersSales } = useSalesFilters();

  const initialValues = quotes.length === 0 ? { data: quotes } : quotes;

  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(initialValues);
  const [clients, setClients] = useState([]);
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [sellers, setSellers] = useState([]);

  useEffect(() => {
    async function fetchQuotes() {
      setIsLoading(true);
      const page = quotes.length === 0 ? 1 : quotes.current_page;

      const { data } = await grid(page, filtersSales);
      updateQuote(data);
      setFilters(data);

      setIsLoading(false);
    }

    fetchQuotes();
  }, []);

  useEffect(() => {
    async function fetch() {
      setIsLoadingClient(true);
      const { data } = await clientListSelect();
      setClients(data);
      setIsLoadingClient(false);
    }

    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      const { data } = await usersListForSelect();
      setSellers(data);
    }

    fetch();
  }, []);

  async function paginate(page, items) {
    setIsLoading(true);
    setFiltersSales(items);

    const { data } = await grid(page, items);
    updateQuote(data);
    setFilters(data);

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Cotizaciones
      </Label>

      <Container style={{ padding: "12px 0 0 0" }}>
        <Header
          canAdd={!check_permission("PE011", "canAdd")}
          items={clients}
          loading={isLoadingClient}
          setFilter={(item) => paginate(1, { client_id: item.value })}
          button_text="Añadir cotización"
          openFilter={() => setOpen(!isOpen)}
          isOpen={isOpen}
          hasMultipleFilters
          add={() =>
            history.push({
              pathname: "add",
              state: { edit: false },
            })
          }
        />

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <Collapse in={isOpen}>
          <Filters
            setFilters={setFilters}
            clients={clients}
            sellers={sellers}
            search_filter={(items) => paginate(1, items)}
            filters={filtersSales}
            initFilters_value={initial_values_sales_filter}
          />
          <Divider />
        </Collapse>

        {check_permission("PE011", "canSee") ? (
          <TableContainer>
            <Table>
              <tbody>
                <Tr>
                  <Th>Codigo</Th>
                  <Th>Cliente</Th>
                  <Th>Vendedor</Th>
                  <Th>Fecha Creación</Th>
                  <Th>Total de cotización</Th>
                  <Th>Acción</Th>
                </Tr>

                {filters.data.map((data, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{data.number_code}</Td>
                      <Td>{data.name}</Td>
                      <Td>{data.username}</Td>

                      <Td>{moment(data.created_at).format("DD/MM/YYYY")}</Td>
                      <Td>RD$ {numeral(data.total).format("0,0.00")}</Td>

                      <Td style={{ cursor: "pointer" }}>
                        <Tooltip placement="right" title="Detalle">
                          <Icon
                            color="primary"
                            onClick={() =>
                              history.push({
                                pathname: "details",
                                state: { id: data.id },
                              })
                            }
                          >
                            description
                          </Icon>
                        </Tooltip>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        ) : (
          <NotPermissions isLoading={isLoading} />
        )}

        <Pagination
          next={() => paginate(filters.current_page + 1, filtersSales)}
          previous={() => paginate(filters.current_page - 1, filtersSales)}
          currentPage={filters.current_page}
          last_page={filters.last_page}
          pageOne={() => paginate(1, filtersSales)}
        />
      </Container>
    </Fragment>
  );
}

export default withTheme(
  withRouter(connect(mapStateToProps, mapActionsToProps)(QuoteGrid))
);
