import React from "react";
import styled, { withTheme } from "styled-components";

import Header from "./header";
import Client from "../../../components/Bills/Client";
import Products from "../../../components/Bills/Products";
import Amount from "../../../components/Bills/Amount";
import Total from "../../../components/Bills/Total";
import Sign from "../../../components/Bills/Sign";

class PrintQuote extends React.Component {
  render() {
    return (
      this.props.data !== null && (
        <Container>
          <div>
            <Header data={this.props.data} />
            <Divider />
            <Client data={this.props.data} />
            <Divider />
            <Products data={this.props.products} />
            <Divider />
            <Amount abonos={[]} data={this.props.data} />
            <Divider />
            <Total abonos={[]} data={this.props.data} />
          </div>

          <div>
            <Sign />

            <Label>
              C/Resp. 3ra, Res. Castillo. No.3A Santo Domingo Oeste, R.D. Tel:
              1-809-379-2052, 1-829- 341-4169. Correo: dairiesfoods@gmail.com
            </Label>
          </div>
        </Container>
      )
    );
  }
}
export default withTheme(PrintQuote);

const Container = styled.div`
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
`;

const Label = styled.div`
  color: black;
  font-size: 14px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  margin-top: 12px;
  width: 100%;
`;
