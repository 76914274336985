import { API } from "../actions/sales-actions";

function salesReducer(state = [], { type, payload }) {
  switch (type) {
    case API:
      return payload.sales;
    default:
      return state;
  }
}

export default salesReducer;
