import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import moment from "moment";

import { create, edit_order } from "../../http/orders";

import { Title } from "../../components/Text";
import { confirmAlert, SimpleAlert } from "../../components/Alert";
import SaveArea from "../../components/SaveArea";
import DetailsHeader from "../../components/DetailsHeader";
import Clients from "../../components/Selects/Clients";
import Calendar from "../../components/Calendar";
import AddProducts from "../../components/Sales/Add/Products/AddProducts";

import Products from "../../parts/orders/products";

import { Container as BodyContainer } from "../../assets/utils/styles/global";

const initValues = {
  status: "OD001",
  date: new Date(),
  client: {
    value: 0,
    username: "",
    rnc: "",
    discount: "",
    apply_tax: null,
    credit_limit: "",
    deadline: "",
  },
};

function AddOrder({ history, location }) {
  const [formValues, setFormValues] = useState(initValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [products, setProducts] = useState([]);

  const edit = location.state.edit;
  const order = location.state.order;

  useEffect(() => {
    function handleEdit() {
      const values = {
        id: order.id,
        date: addDayToDate(order.date),
        client: { value: order.client_id },
      };
      setFormValues(values);
    }

    edit && handleEdit();
  }, [edit, order]);

  async function submit(values) {
    values.products = products;
    values.date = moment(values.date).format("YYYY-MM-DD");

    try {
      setIsLoading(true);
      const { data } = edit ? await edit_order(values) : await create(values);
      const success = data.success ? "success" : "error";

      if (data.success) {
        if (edit) {
          history.push({
            pathname: "details",
            state: { data: data.information },
          });
        } else {
          history.push({ pathname: "grid" });
        }
      }

      SimpleAlert(success, data.data);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }

  function cancel() {
    confirmAlert(
      "warning",
      "¿Está seguro de que quiere cancelar el proceso?"
    ).then((data) => data.value && history.goBack());
  }

  function addDayToDate(date) {
    var result = new Date(date);
    result.setDate(result.getDate() + Number(1));
    return result;
  }

  return (
    <>
      <AddProducts
        open={isProductsOpen}
        products={[]}
        close={() => setIsProductsOpen(false)}
        setProducts={(items) => {
          setProducts(items);
          setIsProductsOpen(false);
        }}
      />

      <DetailsHeader
        icon={edit ? "edit" : "add"}
        title={edit ? "Editar Pedido" : "Añadir Nuevo Pedido"}
      />

      <Formik enableReinitialize initialValues={formValues} onSubmit={submit}>
        {({ handleSubmit, values, setFieldValue }) => (
          <div>
            <Container>
              <InputContainer>
                <Title color="#545358" style={{ textAlign: "center" }}>
                  Información del cliente
                </Title>

                <Clients
                  edit={edit}
                  showInformation={true}
                  values={values.client}
                  onChangeEdit={(selected) => setFieldValue("client", selected)}
                  onChange={(selected) => setFieldValue("client", selected)}
                />
              </InputContainer>

              <InputContainer>
                <Title color="#545358" style={{ textAlign: "center" }}>
                  Mas detalles
                </Title>

                <div style={{ marginTop: 8 }}>
                  <Calendar
                    setDateState={(date) => setFieldValue("date", date)}
                    text="Fecha"
                    value={values.date}
                  />
                </div>
              </InputContainer>
            </Container>

            <Container style={{ marginTop: 20 }}>
              <Products
                products={products}
                handleProducts={() => setIsProductsOpen(true)}
              />
            </Container>

            <SaveArea
              handleSubmit={handleSubmit}
              loading={isLoading}
              success={true}
              cancel={() => cancel()}
            />
          </div>
        )}
      </Formik>
    </>
  );
}

export default withTheme(withRouter(AddOrder));

const InputContainer = styled(BodyContainer)`
  padding: 30px;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
