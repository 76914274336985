import styled from "styled-components";

export const Tr = styled.tr`
  background-color: #f8f8f8;
  color: black;

  :nth-child(even) {
    background-color: white;
  }
`;

export const Td = styled.td`
  padding: 15px 30px 15px 30px;
`;

export const Th = styled(Td)`
  background-color: white;
  font-weight: 600;
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
`;

export const TableContainer = styled.div`
  overflow-x: auto;
`;
