import { API } from "../actions/visited_clients-action";

function visitedClientsReducer(state = [], { type, payload }) {
  switch (type) {
    case API:
      return payload.visitedClients;
    default:
      return state;
  }
}

export default visitedClientsReducer;
