export const API = "creditNote:dataCreditNote";

export function creditNoteData(data) {
  return {
    type: API,
    payload: {
      creditNote: data,
    },
  };
}
