import React, { Fragment } from "react";
import styled, { withTheme, css } from "styled-components";

import { Label } from "../../components/Text";

function Status({ code, description }) {
  return (
    <Fragment>
      <StatusCard code={code}>
        <Label color="white">{description}</Label>
      </StatusCard>
    </Fragment>
  );
}

export default withTheme(Status);

const StatusCard = styled.div`
  width: 100px;
  border-radius: 8px;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;

  ${(props) =>
    props.code === "OD001" &&
    css`
      background-color: #ff8f00;
    `}

  ${(props) =>
    props.code === "OD002" &&
    css`
      background-color: #1565c0;
    `}

    ${(props) =>
    props.code === "OD003" &&
    css`
      background-color: #81c784;
    `}
`;
