import styled from "styled-components";
import InputMask from "react-input-mask";

import TextField from "@material-ui/core/TextField";

import theme from "../theme";

export const Input = styled.input`
  border: 1.5px solid #eaeaea;
  height: 40px;
  font-weight: 550;
  font-size: 15px;
  width: ${(props) => props.width || "100%"};
  box-sizing: border-box;
  outline: none;
  border-radius: ${theme.body.RADIUS};
  padding: 0 12px 0 12px;

  ::placeholder {
    color: #cccccc;
  }

  :focus {
    border: 1.5px solid ${theme.colors.PRIMARY};
  }
`;

export const TextArea = styled.textarea`
  border: 1.5px solid #eaeaea;
  font-weight: 550;
  font-size: 15px;
  box-sizing: border-box;
  outline: none;
  border-radius: 8px;
  padding: 12px;
  width: ${(props) => props.width || "100%"};

  ::placeholder {
    color: #cccccc;
  }

  :focus {
    border: 1.5px solid ${theme.colors.PRIMARY};
  }
`;

export const Mask = styled(InputMask)`
  border: 1.5px solid #eaeaea;
  height: 40px;
  font-weight: 550;
  font-size: 15px;
  width: ${(props) => props.width || "100%"};
  box-sizing: border-box;
  outline: none;
  border-radius: ${theme.body.RADIUS};
  padding: 0 12px 0 12px;

  ::placeholder {
    color: #cccccc;
  }

  :focus {
    border: 1.5px solid ${theme.colors.PRIMARY};
  }
`;

export const Field = styled(TextField)`
  font-weight: 500;
  font-size: 15px;
  width: ${(props) => props.width || "100%"};
  background-color: white;
`;
