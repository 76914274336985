/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { withTheme } from "styled-components";
import styled from "styled-components";

import { Label } from "../Text";

function StatusLabel({ data }) {
  const [color, setColor] = useState("");

  useEffect(() => {
    switch (data.status_code) {
      case "SL001":
        setColor("#ff8f00");
        break;
      case "SL002":
        setColor("#1565c0");
        break;
      case "SL003":
        setColor("#c62828");
        break;
      case "SL004":
        setColor("rgba(47, 160, 8, 0.7)");
        break;
      default:
        setColor("rgba(47, 160, 8, 0.7)");
    }
  }, [data]);

  return (
    <Fragment>
      <StatusCard color={color}>
        <Label color="white">{data.description}</Label>
      </StatusCard>
    </Fragment>
  );
}

export default withTheme(StatusLabel);

export const StatusCard = styled.div`
  width: 100px;
  background-color: ${(props) => props.color};
  border-radius: 8px;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
