export const API = "products:dataProducts";

export function productData(data) {
  return {
    type: API,
    payload: {
      products: data
    }
  };
}
