import React, { Fragment } from "react";
import Select from "react-select";

import { Label } from "../Text";

const coins = [
  { label: "DOP", value: "DOP" },
  { label: "USD", value: "USD" },
];

function Coins({ onChange, value, ...props }) {
  return (
    <Fragment>
      <Label weight={500} {...props}>
        Seleccione el tipo de moneda
      </Label>

      <Select
        placeholder="Moneda"
        options={coins}
        styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
        onChange={onChange}
        value={coins.filter((data) => data.value === value)}
      />
    </Fragment>
  );
}

export default Coins;
