import React from "react";
import styled from "styled-components";

import Outline from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Divider from "../../components/Divider";
import { Field } from "../../components/Input";

function Filters({ filters, handleFilter, handleClear }) {
  const matches = useMediaQuery("(max-width:768px)");

  return (
    <>
      <Container>
        <Input
          onChange={(e) => handleFilter(e.target.value, "name")}
          label="Nombre"
          margin="dense"
          variant="outlined"
          value={filters.name || ""}
        />

        <Input
          onChange={(e) => handleFilter(e.target.value, "city")}
          label="Ciudad"
          margin="dense"
          variant="outlined"
          value={filters.city || ""}
        />

        <Input
          onChange={(e) => handleFilter(e.target.value, "seller")}
          label="Vendedor"
          margin="dense"
          variant="outlined"
          value={filters.seller || ""}
        />
      </Container>

      <Divider />

      <ButtonContainer>
        <Outline
          onClick={handleClear}
          style={{
            width: matches ? "100%" : 200,
          }}
          variant="outlined"
          color="primary"
        >
          Limpiar Filtros
        </Outline>
      </ButtonContainer>
    </>
  );
}

export default Filters;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 12px 20px;
`;

const ButtonContainer = styled.div`
  margin: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;

const Input = styled(Field)`
  margin-right: 12px;
  width: 240px;

  @media (max-width: 768px) {
    margin-right: 0;
    width: calc(100% - 40px);
  }
`;
