import React, { useState, useContext } from "react";
import { Formik } from "formik";
import Select from "react-select";

import ContextAuth from "../../../lib/ContextUser";

import Modal from "../../Modal";
import Button from "../../Buttons/Button";
import { Label } from "../../Text";
import { SimpleAlert, confirmAlert } from "../../Alert";

import { updateStatus } from "../../../http/sales";

function ModalStatus({ statusList, saleData, products, close }) {
  const { user } = useContext(ContextAuth);

  const [loading, setloading] = useState(false);

  async function submit(item) {
    if (item.code.value === "SL002") {
      confirmAlert(
        "info",
        "¿Está seguro que quiere cambiar el estado a Anulada? Los producto serán devueltos al inventario y no podra cambiar el estado de nuevo"
      ).then((result) => {
        if (result.value) {
          save(item);
        }
      });
    } else {
      save(item);
    }
  }

  async function save(item) {
    setloading(true);

    const object = {
      saleId: saleData.id,
      status: item.code.value,
      products: products,
      admin: user.id,
    };

    try {
      const { data } = await updateStatus(object);
      const type = data.success ? "success" : "error";
      data.success && close(item.code);

      SimpleAlert(type, data.data);
    } catch (error) {
      SimpleAlert("error", "Algo ha pasado por favor intentelo de nuevo");
    }
    setloading(false);
  }

  return (
    <Modal close={close} open={true}>
      <Formik
        initialValues={{ code: { value: saleData.status_code } }}
        onSubmit={(value) => submit(value)}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <div style={{ marginTop: 10 }}>
            <Label style={{ marginBottom: 10 }} weight="550">
              Cambiar estado
            </Label>

            <Select
              onChange={(selected) => setFieldValue("code", selected)}
              options={statusList}
              placeholder="Estado"
              value={statusList.filter(
                (item) => item.value === values.code.value
              )}
            />

            <Button
              top="16px"
              text="Cambiar estado"
              action={handleSubmit}
              loading={loading}
              disabled={loading}
              color="primary"
              icon="save"
              side="left"
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default ModalStatus;
