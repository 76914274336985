import styled from "styled-components";

export const Logo = styled.img`
  height: 137px;
  width: 150px;

  @media (max-width: 800px) {
    height: 137px;
    width: 150px;
  }
`;

export const TopBarLogo = styled.img`
  height: 24px;
  width: 26px;
  border-radius: 4px;
`;
