import React from "react";
import styled from "styled-components";

import { Label } from "../Text";

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 16px;
`;

const Line = styled.div`
  height: 1px;
  background-color: black;
  width: 400px;
  margin-left: 8px;
  align-self: flex-end;
`;

function Sign() {
  return (
    <Container>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Label>Recibido por: </Label>
        <Line />
      </div>

      <Label style={{ margin: "4px 0 0 100px" }}>Firma, Sello y Cédula</Label>
    </Container>
  );
}

export default Sign;
