import http from "./http";

export function grid(page, data) {
  return http.post(`taxReceipt/grid?page=${page}`, data);
}

export function listForSelect() {
  return http.get("taxReceipt/listForSelect");
}

export function addReceiptsForCompany(data) {
  return http.post("taxReceipt/addReceiptsForCompany", data);
}
