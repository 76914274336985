/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styled from "styled-components";

import { Icon } from "@material-ui/core";

import { Label } from "./Text";

import { Container } from "../assets/utils/styles/global";

const ContainerBody = styled(Container)`
  display: flex;
  align-items: center;
  padding: 6px 30px 6px 30px;
  flex-wrap: wrap;
`;

function DetailsHeader({ children, title, icon }) {
  return (
    <ContainerBody>
      <Icon style={{ fontSize: 30 }} color="primary">
        {icon}
      </Icon>

      <Label
        weight="bold"
        style={{ marginRight: 30, marginLeft: 10, fontSize: 20 }}
      >
        {title}
      </Label>

      {children}
    </ContainerBody>
  );
}

export default DetailsHeader;
