export const API = "taxReceipt:dataTaxReceipt";

export function taxReceiptData(data) {
  return {
    type: API,
    payload: {
      taxReceipt: data
    }
  };
}
