import React, { Fragment } from "react";
import styled, { withTheme } from "styled-components";

import { Label } from "../../../components/Text";
import { Logo } from "../../../components//Image";

import LOGO from "../../../assets/images/logoDairies.jpeg";

var moment = require("moment");

function Header({ theme, data }) {
  return (
    <Fragment>
      <Container>
        <div>
          <Logo style={{ width: 70, height: 64 }} src={LOGO} />
          <div style={{ display: "flex", marginTop: 8 }}>
            <Content style={{ fontWeight: 600 }}>Nombre:</Content>
            <Content style={{ marginLeft: 4 }}>
              Dairies & Foods Company, SRL
            </Content>
          </div>

          <div style={{ display: "flex", marginTop: 8 }}>
            <Content style={{ fontWeight: 600 }}>RNC:</Content>
            <Content style={{ marginLeft: 4 }}>1-31-46126-3</Content>
          </div>

          <div style={{ display: "flex", marginTop: 8 }}>
            <Content style={{ fontWeight: 600 }}> Fecha de creación:</Content>
            <Content style={{ marginLeft: 4 }}>
              {moment(data.created_at).format("DD/MM/YYYY")}
            </Content>
          </div>
        </div>

        <div style={{ marginLeft: 16 }}>
          <Title style={{ fontSize: 28 }} color={theme.colors.PRIMARY}>
            Cotización
          </Title>

          <InformationInvoice>
            <Label style={{ marginTop: 4 }} weight={600}>
              Vendedor
            </Label>

            <Label style={{ marginTop: 4 }}>{data.username}</Label>
          </InformationInvoice>

          <InformationInvoice>
            <Label style={{ marginTop: 4 }} weight={600}>
              Usuario
            </Label>

            <Label style={{ marginTop: 4 }}>{data.create_user}</Label>
          </InformationInvoice>

          <InformationInvoice>
            <Label style={{ marginTop: 4 }} weight={600}>
              Condición
            </Label>

            <Label style={{ marginTop: 4 }}>
              {data.payment_code === "PM005" ? "Crédito" : "Contado"}
            </Label>
          </InformationInvoice>

          <InformationInvoice>
            <Label style={{ marginTop: 4 }} weight={600}>
              No. Cotización
            </Label>

            <Label style={{ marginTop: 4 }}>#{data.number_code}</Label>
          </InformationInvoice>
        </div>
      </Container>
    </Fragment>
  );
}

export default withTheme(Header);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: ${(props) => props.color};
`;

const InformationInvoice = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
`;

const Content = styled.div`
  color: black;
  font-size: 14px;
`;
