import React, { useState, useContext, Fragment } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import styled, { withTheme } from "styled-components";

import Icon from "@material-ui/core/Icon";
import Collapse from "@material-ui/core/Collapse";

import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { in_system } from "../../routes";
import { menu_routes } from "./sideMenuRoutes";
import { Label } from "../Text";
import TopBar from "../TopBar";
import Avatar from "./Avatar";

import ContextAuth from "../../lib/ContextAuth";
import ContextUser from "../../lib/ContextUser";
import ContextMenu from "../../lib/ContextMenu";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const visitClientAllowId = 561;

function SideMenu({ theme }) {
  const { setLogged } = useContext(ContextAuth);
  const { user } = useContext(ContextUser);
  const { isOpenMenu, setIsOpenMenu } = useContext(ContextMenu);

  const userAllowed = Number(user.id) === visitClientAllowId;

  const classes = useStyles();

  const currentRoute = window.location.pathname;

  const [isOpen, setIsOpen] = useState(false);
  const [sideMenuActive, setSideMenuActive] = useState(
    window.location.pathname
  );

  function logout() {
    setLogged(false);
    localStorage.clear();
  }

  function verifyRoute(route) {
    const matchModule = route === "/state_client/grid";

    if (matchModule) {
      if (userAllowed) return true;
      else return false;
    }

    return true;
  }

  return (
    <>
      <Router>
        {currentRoute !== "/order/list" && (
          <Dialog fullScreen open={isOpenMenu}>
            <AppBar
              style={{ boxShadow: "none", position: "sticky" }}
              className={classes.appBar}
            >
              <Toolbar>
                <Icon
                  onClick={() => setIsOpenMenu(false)}
                  style={{ color: "white", cursor: "pointer" }}
                >
                  close
                </Icon>

                <Typography variant="h6" className={classes.title}>
                  Menu
                </Typography>
              </Toolbar>
            </AppBar>

            <Container background={theme.colors.SIDE_MENU}>
              <div style={{ height: "90%" }}>
                <Avatar logOut={() => logout()} name={user.name} />

                {menu_routes.map((route, index) => {
                  const active = sideMenuActive === route.path;
                  const canShow = verifyRoute(route.path);

                  return (
                    <Fragment>
                      {canShow && (
                        <>
                          <Link
                            style={{ textDecoration: "none" }}
                            key={index}
                            to={!route.subRoute && route.path}
                            onClick={(event) => {
                              setIsOpen(false);
                              !route.subRoute && setIsOpenMenu(false);

                              if (route.subRoute) {
                                event.preventDefault();
                                setIsOpen(!isOpen);
                              }

                              setSideMenuActive(route.path);
                            }}
                          >
                            <SideMenuContainer
                              color={
                                active
                                  ? `${theme.colors.PRIMARY}`
                                  : "transparent"
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Icon style={{ color: "white" }}>
                                  {route.icon}
                                </Icon>

                                <Label
                                  style={{ marginLeft: 10 }}
                                  color={active ? "white" : "#CCCCCC"}
                                  active={active}
                                >
                                  {route.name}
                                </Label>
                              </div>

                              {active && (
                                <Label style={{ fontSize: 28 }} color="white">
                                  •
                                </Label>
                              )}
                            </SideMenuContainer>
                          </Link>

                          {route.subRoute &&
                            route.subRoute.map((subRoute, j) => {
                              const activeSub =
                                sideMenuActive === subRoute.path;

                              return (
                                <Collapse in={isOpen}>
                                  <Link
                                    key={j}
                                    style={{ textDecoration: "none" }}
                                    to={subRoute.path}
                                    onClick={() => {
                                      setIsOpenMenu(false);
                                      setSideMenuActive(subRoute.path);
                                    }}
                                  >
                                    <SideMenuContainer
                                      style={{ marginTop: 0 }}
                                      color={
                                        activeSub ? "#4b5068" : "transparent"
                                      }
                                    >
                                      <Label
                                        style={{ marginLeft: 10 }}
                                        color={active ? "white" : "#CCCCCC"}
                                        active={active}
                                      >
                                        {subRoute.name}
                                      </Label>

                                      {activeSub && (
                                        <Label
                                          style={{ fontSize: 28 }}
                                          color="white"
                                        >
                                          •
                                        </Label>
                                      )}
                                    </SideMenuContainer>
                                  </Link>
                                </Collapse>
                              );
                            })}
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </Container>
          </Dialog>
        )}

        <Parent>
          <TopBar />
          <ViewsContainer>
            {in_system.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.main}
              />
            ))}
          </ViewsContainer>
        </Parent>
      </Router>
    </>
  );
}

export default withTheme(SideMenu);

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${(props) => props.background};
  border-right: 1px solid #eaeaea;
  padding: 0px 28px 0px 0px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
`;

const ViewsContainer = styled.div`
  width: 100%;
  padding: 20px;
  overflow-x: hidden;
  box-sizing: border-box;
`;

const Parent = styled.div`
  width: 100%;
`;

const SideMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.color};
  padding: 0px 15px 0px 14px;
  width: 99.5%;
  height: 44px;
`;
