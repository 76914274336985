import React, { useState, useContext } from "react";

export const initial_values_sales_filter = {
  client_id: null,
  creation_date: null,
  expiration_date: null,
  number_code: null,
  seller_id: null,
  status: null,
  date_1: null,
  date_2: null,
};

export const ContextFiltersSales = React.createContext({
  filtersSales: initial_values_sales_filter,
  setFiltersSales: () => null,
});

export const SalesContextProvider = ({ children }) => {
  const [filtersSales, setFiltersSales] = useState(initial_values_sales_filter);

  return (
    <ContextFiltersSales.Provider
      value={{
        filtersSales,
        setFiltersSales,
      }}
    >
      {children}
    </ContextFiltersSales.Provider>
  );
};

export const useSalesFilters = () => useContext(ContextFiltersSales);
