import Swal from "sweetalert2";
import "../swalcss.css";

export function SimpleAlert(type, text) {
  return Swal.fire({
    type: type,
    text: text,
    customContainerClass: "my-swal",
  });
}

export function confirmAlert(type, text) {
  return Swal.fire({
    type: type,
    text: text,
    showCancelButton: true,
    confirmButtonText: "Si",
    confirmButtonColor: "#d51010",
    cancelButtonText: "No",
    customContainerClass: "my-swal",
  });
}
