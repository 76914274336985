import React from "react";

import Signin from "./views/sign-in";
import Dashboard from "./views/dashboard";

import AddClient from "./views/Clients/Add";
import ClientsGrid from "./views/Clients/Grid";
import ClientDetails from "./views/Clients/Details";

import ClientToFollow from "./views/Following/Grid";

import AddProduct from "./views/Products/Add";
import ProductsGrid from "./views/Products/Grid";
import ProductDetails from "./views/Products/Details";

import AddConduce from "./views/Conduce/Add";
import ConduceGrid from "./views/Conduce/Grid";
import ConduceDetails from "./views/Conduce/Details";

import AddSales from "./views/Sales/Add";
import SalesGrid from "./views/Sales/Grid";
import SalesDetails from "./views/Sales/Details";
import SalesAboutExpired from "./views/Sales/AboutExpired";
import SalesExpired from "./views/Sales/Expired";
import AddFromQuote from "./views/Sales/add-from-quote";

import AddCreditNote from "./views/CreditNote/Add";
import CreditNoteGrid from "./views/CreditNote/Grid";
import CreditNoteDetails from "./views/CreditNote/Details";

import TaxReceipt from "./views/taxReceipt";

import UsersGrid from "./views/Users/Grid";
import Permissions from "./views/Users/Permissions";

import QuoteGrid from "./views/quote/grid";
import AddQuote from "./views/quote/add";
import QuoteDetails from "./views/quote/details";

import SalesGeneralReport from "./views/Reports/SalesGeneralReport";

import History from "./views/History";

import StateClientGrid from "./views/state-client/grid";
import AddStateClient from "./views/state-client/add";
import StateClientDetails from "./views/state-client/details";

import AddOrder from "./views/orders/add";
import OrdersGrid from "./views/orders/grid";
import OrderDetails from "./views/orders/details";
// import OrderList from "./views/orders/list";

import FollowDetails from "./views/Following/Details";

export const in_system = [
  { path: "/", exact: true, main: () => <Dashboard /> },

  { path: "/clients/add", main: () => <AddClient /> },
  { path: "/clients/grid", main: () => <ClientsGrid /> },
  { path: "/clients/details", main: () => <ClientDetails /> },

  { path: "/products/add", main: () => <AddProduct /> },
  { path: "/products/grid", main: () => <ProductsGrid /> },
  { path: "/products/details", main: () => <ProductDetails /> },

  { path: "/sales/add", main: () => <AddSales /> },
  { path: "/sales/grid", main: () => <SalesGrid /> },
  { path: "/sales/details", main: () => <SalesDetails /> },
  { path: "/sales/about_expire", main: () => <SalesAboutExpired /> },
  { path: "/sales/expired", main: () => <SalesExpired /> },
  { path: "/sales/add-from-quote", main: () => <AddFromQuote /> },

  { path: "/credit_note/add", main: () => <AddCreditNote /> },
  { path: "/credit_note/grid", main: () => <CreditNoteGrid /> },
  { path: "/credit_note/details", main: () => <CreditNoteDetails /> },

  { path: "/conduce/add", main: () => <AddConduce /> },
  { path: "/conduce/grid", main: () => <ConduceGrid /> },
  { path: "/conduce/details", main: () => <ConduceDetails /> },

  { path: "/reports/sales", main: () => <SalesGeneralReport /> },

  { path: "/tax/grid", main: () => <TaxReceipt /> },

  { path: "/users/grid", main: () => <UsersGrid /> },
  { path: "/users/permissions", main: () => <Permissions /> },

  { path: "/quote/grid", main: () => <QuoteGrid /> },
  { path: "/quote/add", main: () => <AddQuote /> },
  { path: "/quote/details", main: () => <QuoteDetails /> },

  { path: "/history", main: () => <History /> },

  { path: "/state_client/grid", main: () => <StateClientGrid /> },
  { path: "/state_client/add", main: () => <AddStateClient /> },
  { path: "/state_client/details", main: () => <StateClientDetails /> },

  { path: "/order/add", main: () => <AddOrder /> },
  { path: "/order/grid", main: () => <OrdersGrid /> },
  { path: "/order/details", main: () => <OrderDetails /> },

  { path: "/follow/grid", main: () => <ClientToFollow /> },
  { path: "/follow/details", main: () => <FollowDetails /> },
];

export const out_system = [{ path: "/", exact: true, main: () => <Signin /> }];
