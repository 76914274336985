import styled from "styled-components";

export const Title = styled.h2`
  color: ${props => props.color || "black"};
`;

export const Label = styled.div`
  color: ${props => props.color || "black"};
  font-weight: ${props => props.weight || "400"};
  font-size: ${props => props.size || "16px"};
`;
