/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useContext } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import Select from "react-select";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";

import ContextAuth from "../../lib/ContextUser";

import { Title, Label } from "../../components/Text";
import { SimpleAlert, confirmAlert } from "../../components/Alert";
import { TextArea, Field } from "../../components/Input";
import SaveArea from "../../components/SaveArea";
import DetailsHeader from "../../components/DetailsHeader";
import Radio from "../../components/RadioButton";
import NCF from "../../components/Selects/NCF";

import { Container as BodyContainer } from "../../assets/utils/styles/global";

import { create, update } from "../../http/clients";
import { usersListForSelect } from "../../http/users";
// import { countryList } from "../../http/country";

import theme from "../../theme";

const initValues = {
  name: "",
  address: "",
  city: "",
  country: "",
  phone: "",
  mobile: "",
  document: "",
  document_type: true,
  discount: "",
  email: "",
  user: "",
  notes: "",
  ncf: "",
  credit_limit: "",
  contact: "",
  deadline: "",
};

function AddClient({ history, location }) {
  const { user } = useContext(ContextAuth);

  const [loading, setloading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [users, setUsers] = useState([]);
  const [formValues, setFormValues] = useState(initValues);

  const [countriesState, setCountriesState] = useState([]);
  const [usersState, setUsersState] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const edit = location.state.edit;
  const clientData = location.state.clientData;
  const fromVisitClient = location.state.fromVisitClient;

  useEffect(() => {
    async function fetch() {
      setFormValues(clientData);
      setCountriesState(clientData.country);
      setUsersState(clientData.user);
    }

    edit && !fromVisitClient && fetch();
  }, [fromVisitClient]);

  useEffect(() => {
    async function fetch() {
      const newValues = { ...clientData };
      newValues.mobile = newValues.cell_phone;
      const sellerId = newValues.seller_id;

      delete newValues.cell_phone;
      delete newValues.seller_id;

      setFormValues(newValues);
      setCountriesState("República Dominicana");
      setUsersState(sellerId);
    }

    fromVisitClient && fetch();
  }, [fromVisitClient]);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      try {
        const { data } = await usersListForSelect();
        setUsers(data);
      } catch {}
      setIsLoading(false);
    }

    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const array = [];

      try {
        // const dataCountry = await countryList();
        // dataCountry.data.map((data) => {
        //   return array.push({ value: data.name, label: data.name });
        // });
        array.push({
          value: "República Dominicana",
          label: "República Dominicana",
        });

        setCountries(array);
      } catch {}
      setIsLoading(false);
    }

    fetch();
  }, []);

  async function submit(value) {
    value.country = countriesState;
    value.user = usersState;
    value.admin = user.id;

    delete value.username;
    delete value.ncf_name;

    try {
      setloading(true);

      const { data } = edit ? await update(value) : await create(value);
      const type = data.success ? "success" : "error";

      if (data.success) {
        if (edit) {
          history.push({
            pathname: "details",
            state: { data: data.information[0] },
          });
        } else {
          history.push({ pathname: "grid" });
        }
      }

      SimpleAlert(type, data.data);
    } catch (error) {
      SimpleAlert("error", "Algo ha pasado por favor intentelo de nuevo");
    } finally {
      setloading(false);
    }
  }

  function cancel() {
    confirmAlert(
      "warning",
      "¿Está seguro de que quiere cancelar el proceso?"
    ).then((data) => data.value && history.goBack());
  }

  return (
    <Fragment>
      <DetailsHeader
        icon={edit ? "edit" : "add"}
        title={edit ? "Editar Cliente" : "Añadir Nuevo Cliente"}
      />

      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={(value) => submit(value)}
      >
        {({ handleChange, handleSubmit, values, setFieldValue }) => (
          <div>
            <Container>
              <InputContainer>
                <Title color="#545358" style={{ textAlign: "center" }}>
                  Información del cliente
                </Title>

                <Field
                  onChange={handleChange("name")}
                  label="Nombre del cliente"
                  margin="dense"
                  variant="outlined"
                  value={values.name}
                />

                <Field
                  onChange={handleChange("email")}
                  label="Correo Electrónico"
                  margin="dense"
                  variant="outlined"
                  value={values.email}
                  style={{ marginTop: 15 }}
                />

                <Field
                  onChange={handleChange("address")}
                  label="Dirección"
                  margin="dense"
                  variant="outlined"
                  value={values.address}
                  style={{ marginTop: 15 }}
                />

                <InputMask
                  onChange={handleChange("phone")}
                  value={values.phone}
                  mask="999-999-9999"
                  style={{ marginTop: 15 }}
                >
                  {() => (
                    <Field
                      variant="outlined"
                      label="Numero Telefonico"
                      margin="dense"
                    />
                  )}
                </InputMask>

                <InputMask
                  onChange={handleChange("mobile")}
                  value={values.mobile}
                  mask="999-999-9999"
                  style={{ marginTop: 15 }}
                >
                  {() => (
                    <Field
                      variant="outlined"
                      label="Numero Celular"
                      margin="dense"
                    />
                  )}
                </InputMask>

                <Field
                  onChange={handleChange("city")}
                  label="Ciudad"
                  margin="dense"
                  variant="outlined"
                  value={values.city}
                  style={{ marginTop: 15 }}
                />

                <div style={{ marginTop: 15 }}>
                  <Select
                    onChange={(data) => setCountriesState(data.value)}
                    options={countries}
                    placeholder={edit ? countriesState : "Seleccione el país"}
                    value={countries.filter(
                      (data) => data.label === countriesState
                    )}
                  />
                </div>
              </InputContainer>

              <InputContainer>
                <Title color="#545358" style={{ textAlign: "center" }}>
                  Mas detalles
                </Title>

                <ItemsContainer>
                  <RadioContainer>
                    <Label weight={500}>Seleccione el tipo de documento:</Label>

                    <Radio
                      action={() => {
                        setFieldValue("document", "");
                        setFieldValue("document_type", true);
                      }}
                      selected={values.document_type}
                      label="RNC"
                      margin="0 0 0 3px"
                    />

                    <Radio
                      action={() => {
                        setFieldValue("document", "");
                        setFieldValue("document_type", false);
                      }}
                      selected={!values.document_type}
                      label="Cédula"
                      margin="0 5px 0 0"
                    />
                  </RadioContainer>
                </ItemsContainer>

                <InputMask
                  onChange={handleChange("document")}
                  value={values.document}
                  mask={values.document_type ? "9-99-99999-9" : "999-9999999-9"}
                  style={{ marginTop: 15 }}
                >
                  {() => (
                    <Field
                      variant="outlined"
                      label={values.document_type ? "RNC" : "Cédula"}
                      margin="dense"
                    />
                  )}
                </InputMask>

                <NumberFormat
                  margin="dense"
                  variant="outlined"
                  label="Descuento"
                  thousandSeparator={true}
                  customInput={Field}
                  decimalSeparator="."
                  inputProps={{ maxLength: 3 }}
                  onChange={handleChange("discount")}
                  value={values.discount}
                  allowNegative={false}
                />

                <div style={{ marginTop: 15 }}>
                  <NCF
                    value={values.ncf}
                    onChange={(selected) =>
                      setFieldValue("ncf", selected.value)
                    }
                    style={{ marginBottom: 4 }}
                  />
                </div>

                <Field
                  onChange={handleChange("contact")}
                  label="Contacto"
                  margin="dense"
                  variant="outlined"
                  value={values.contact}
                  style={{ marginTop: 15 }}
                />

                <NumberFormat
                  margin="dense"
                  variant="outlined"
                  label="Limite de credito"
                  thousandSeparator={true}
                  customInput={Field}
                  decimalSeparator="."
                  onChange={handleChange("credit_limit")}
                  value={values.credit_limit}
                  allowNegative={false}
                  style={{ marginTop: 15 }}
                />

                <NumberFormat
                  margin="dense"
                  variant="outlined"
                  label="Ingrese periodo de plazo en dias"
                  thousandSeparator={true}
                  customInput={Field}
                  decimalSeparator="."
                  onChange={handleChange("deadline")}
                  value={values.deadline}
                  allowNegative={false}
                />

                <Title
                  color="#545358"
                  style={{ textAlign: "center", marginTop: 40 }}
                >
                  Vendedor
                </Title>

                <Select
                  onChange={(data) => setUsersState(data.value)}
                  options={users}
                  placeholder={
                    edit ? clientData.username : "Seleccione el vendedor"
                  }
                  value={users.filter((data) => {
                    const result = Number(data.value) === Number(usersState);

                    if (result) {
                      return data.label;
                    }
                  })}
                />
              </InputContainer>
            </Container>

            <InputContainer style={{ marginTop: 20, width: "100%" }}>
              <Title color="#545358" style={{ textAlign: "center" }}>
                Notas adicionales
              </Title>

              <TextArea
                value={values.notes}
                onChange={handleChange("notes")}
                rows="4"
              />
            </InputContainer>

            <SaveArea
              handleSubmit={handleSubmit}
              loading={loading || isLoading}
              success={true}
              cancel={() => cancel()}
            />
          </div>
        )}
      </Formik>
    </Fragment>
  );
}

export default withTheme(withRouter(AddClient));

const InputContainer = styled(BodyContainer)`
  padding: 30px;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ItemsContainer = styled.div`
  border: 1.5px solid #eaeaea;
  height: 46px;
  width: 100%;
  outline: none;
  border-radius: ${theme.body.RADIUS};
  padding: 0 12px 0 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 15px;
  background-color: white;
`;
