import React, { useState, useEffect } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import { Label } from "../../components/Text";
import Circle from "../../components/Buttons/Circle";
import DetailsHeader from "../../components/DetailsHeader";
import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Pagination from "../../components/Table/Pagination";
import Button from "../../components/Buttons/Button";

import { Container } from "../../assets/utils/styles/global";

import { get_visits } from "../../http/visited_clients";

import AddVisit from "../../parts/state-clients/add-visit";

function ClientDetails({ location, history }) {
  const client = location.state.data;

  const [page, setPage] = useState(1);
  const [visits, setVisits] = useState({ data: [] });
  const [isVisitOpen, setIsVisitOpen] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    async function fetchVisits() {
      const { data } = await get_visits(page, { client_id: client.id });
      setVisits(data);
    }

    fetchVisits();
  }, [client, page, reload]);

  const list = (key, value) => {
    return (
      <RowContainer>
        <Label weight={600}>{key}: </Label>
        <Label style={{ marginLeft: 8 }}>{value}</Label>
      </RowContainer>
    );
  };

  return (
    <>
      {isVisitOpen && (
        <AddVisit
          client_id={client.id}
          onClose={() => setIsVisitOpen(false)}
          closeAndReload={() => {
            setReload(!reload);
            setIsVisitOpen(false);
          }}
        />
      )}

      <DetailsHeader icon="description" title="Cliente Visitado">
        <Circle
          title="Editar"
          icon="edit"
          action={() =>
            history.push({
              pathname: "add",
              state: { clientData: client, edit: true },
            })
          }
        />

        <Circle
          title="Convertir a cliente"
          icon="group"
          action={() =>
            history.push({
              pathname: "../clients/add",
              state: { clientData: client, fromVisitClient: true },
            })
          }
        />
      </DetailsHeader>

      <Body>
        <Card>
          {list("Nombre", client.name)}
          {list("Ciudad", client.city)}
          {list("Celular", client.cell_phone)}
          {list("Telefono", client.phone)}
        </Card>

        <Card>
          {list("Dirección", client.address)}
          {list("Vendedor", client.username)}
          {list("Correo", client.email)}
          {list("Notas", client.notes)}
        </Card>
      </Body>

      <Container style={{ marginTop: 20, padding: "12px 0 0 0" }}>
        <Header>
          <Title>Visitas</Title>

          <Button
            width="200px"
            text="Agregar Visita"
            action={() => setIsVisitOpen(true)}
            color="primary"
          />
        </Header>

        <TableContainer>
          <Table>
            <tbody>
              <Tr>
                <Th>Fecha de visita</Th>
                <Th>Me compro</Th>
                <Th>Notas</Th>
              </Tr>

              {visits.data.map((data) => {
                return (
                  <Tr key={data.id}>
                    <Td>{moment(data.date).format("DD/MM/YYYY")}</Td>
                    <Td>{data.buy_me ? "Sí" : "No"}</Td>
                    <Td>{data.notes}</Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>

        <Pagination
          next={() => setPage(visits.current_page + 1)}
          previous={() => setPage(visits.current_page - 1)}
          currentPage={visits.current_page}
          last_page={visits.last_page}
          pageOne={() => setPage(1)}
        />
      </Container>
    </>
  );
}

export default withTheme(withRouter(ClientDetails));

const RowContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #f8f8f8;

  :nth-child(even) {
    background-color: white;
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled(Container)`
  padding: 0;
  width: 49%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.div`
  color: #545358;
  font-size: 20px;
  font-weight: 600;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
`;
