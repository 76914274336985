export const UPDATE_USER_LIST = "users:updateUserList";

export function updateUserList(data) {
  return {
    type: UPDATE_USER_LIST,
    payload: {
      user: data
    }
  };
}
