import http from "./http";

export function create(data) {
  return http.post("quote/create", data);
}

export function grid(page, data) {
  return http.post(`quote/grid?page=${page}`, data);
}

export function details(id) {
  return http.get(`quote/details?id=${id}`);
}

export function get_products(id) {
  return http.get(`quote/get_products?id=${id}`);
}

export function update(data) {
  return http.put("quote/update", data);
}
