import React from "react";
import styled from "styled-components";

import { Icon } from "@material-ui/core";

import { Label } from "./Text";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

function NotPermissions({ isLoading }) {
  return (
    <Container style={{ padding: 20 }}>
      {!isLoading && (
        <Container>
          <Icon style={{ fontSize: 60, color: "#fbc02d" }}>error_outline</Icon>
          <Label style={{ marginTop: 8 }}>
            Usted no cuenta con los permisos para usar esta funcionalidad
          </Label>
        </Container>
      )}
    </Container>
  );
}

export default NotPermissions;
