import React from "react";
import styled, { withTheme } from "styled-components";

const Details = styled.div`
  margin-top: 12px;
`;

const Label = styled.div`
  color: black;
  font-size: 14px;
`;

function Client({ theme, data }) {
  return (
    <Details>
      <div>
        <Label
          style={{ fontWeight: 600, fontSize: 18, color: theme.colors.PRIMARY }}
        >
          Para:
        </Label>

        <div style={{ display: "flex", marginTop: 8 }}>
          <Label style={{ fontWeight: 600 }}>Nombre:</Label>
          <Label style={{ marginLeft: 4 }}>{data.name}</Label>
        </div>

        <div style={{ display: "flex", marginTop: 8 }}>
          <Label style={{ fontWeight: 600 }}>
            {data.document_type ? "RNC" : "Cédula"}:
          </Label>
          <Label style={{ marginLeft: 4 }}>{data.document}</Label>
        </div>

        <div style={{ display: "flex", marginTop: 8 }}>
          <Label style={{ fontWeight: 600 }}>Provincia:</Label>
          <Label style={{ marginLeft: 4 }}>{data.city}</Label>
        </div>
      </div>

      <div style={{ display: "flex", marginTop: 8 }}>
        <Label style={{ fontWeight: 600 }}>Dirección:</Label>
        <Label style={{ marginLeft: 4 }}>{data.address}</Label>
      </div>

      <div style={{ display: "flex", marginTop: 8 }}>
        <Label style={{ fontWeight: 600 }}>Télefono:</Label>
        <Label style={{ marginLeft: 4 }}>{data.phone}</Label>
      </div>
    </Details>
  );
}

export default withTheme(Client);
