/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import { connect } from "react-redux";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Tooltip, Icon } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";

import { Container } from "../../assets/utils/styles/global";

import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Divider from "../../components/Divider";
import { Label } from "../../components/Text";
import Pagination from "../../components/Table/Pagination";

import Filters from "../../parts/state-clients/filters";
import Header from "../../parts/state-clients/header";

import { visitedClientsData } from "../../actions/visited_clients-action";

import { grid } from "../../http/visited_clients";

const mapStateToProps = (state) => {
  return {
    visitedClients: state.visitedClients,
  };
};

const mapActionsToProps = {
  updateVisitedClients: visitedClientsData,
};

const initValuesFilters = {
  name: null,
  city: null,
  seller: null,
};

function StateClientGrid({ history, updateVisitedClients, visitedClients }) {
  const initialValues =
    visitedClients.length === 0 ? { data: visitedClients } : visitedClients;

  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(initValuesFilters);
  const [clientsFiltered, setClientsFiltered] = useState(initialValues);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    async function fetchQuotes() {
      setIsLoading(true);
      const page =
        visitedClients.length === 0 ? 1 : visitedClients.current_page;

      const { data } = await grid(page, filters);
      updateVisitedClients(data);
      setClientsFiltered(data);

      setIsLoading(false);
    }

    fetchQuotes();
  }, []);

  async function paginate(page, items) {
    setIsLoading(true);

    const { data } = await grid(page, items);
    updateVisitedClients(data);
    setClientsFiltered(data);
    setFilters(items);

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Clientes visitados
      </Label>

      <Container style={{ padding: "12px 0 0 0" }}>
        <Header
          handleClient={(e) => paginate(1, { name: e.target.value })}
          openFilter={() => setOpen(!isOpen)}
          isOpen={isOpen}
          handleAddClient={() =>
            history.push({ pathname: "add", state: { edit: false } })
          }
        />

        {isLoading ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <Collapse in={isOpen}>
          <Filters
            handleFilter={(value, name) =>
              paginate(1, { ...filters, [name]: value })
            }
            handleClear={() => paginate(1, initValuesFilters)}
            filters={filters}
          />
          <Divider />
        </Collapse>

        <TableContainer>
          <Table>
            <tbody>
              <Tr>
                <Th>Cliente Visitado</Th>
                <Th>Vendedor</Th>
                <Th>Ciudad</Th>
                <Th>Dirección</Th>
                <Th>Acción</Th>
              </Tr>

              {clientsFiltered.data.map((data) => {
                return (
                  <Tr key={data.id}>
                    <Td>{data.name}</Td>
                    <Td>{data.username}</Td>

                    <Td>{data.city}</Td>
                    <Td>{data.address}</Td>

                    <Td style={{ cursor: "pointer" }}>
                      <Tooltip placement="right" title="Detalle">
                        <Icon
                          color="primary"
                          onClick={() =>
                            history.push({
                              pathname: "details",
                              state: { data },
                            })
                          }
                        >
                          description
                        </Icon>
                      </Tooltip>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>

        <Pagination
          next={() => paginate(clientsFiltered.current_page + 1, filters)}
          previous={() => paginate(clientsFiltered.current_page - 1, filters)}
          currentPage={clientsFiltered.current_page}
          last_page={clientsFiltered.last_page}
          pageOne={() => paginate(1, filters)}
        />
      </Container>
    </Fragment>
  );
}

export default withTheme(
  withRouter(connect(mapStateToProps, mapActionsToProps)(StateClientGrid))
);
